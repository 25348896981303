const checkPostcodeDistance = (postcode, callback) => {
  const scunthorpeLocation = new window.google.maps.LatLng(53.588646, -0.654413); // Scunthorpe's latitude and longitude
  const service = new window.google.maps.DistanceMatrixService();

  service.getDistanceMatrix({
    origins: [postcode],
    destinations: [scunthorpeLocation],
    travelMode: 'DRIVING',
  }, (response, status) => {
    if (status === 'OK' && response.rows[0] && response.rows[0].elements[0] && response.rows[0].elements[0].distance) {
      const distanceMeters = response.rows[0].elements[0].distance.value;
      const distanceMiles = distanceMeters * 0.000621371; // Convert meters to miles

      if (distanceMiles <= 30) {
        callback(true);
      } else {
        callback(false);
      }
    } else {
      callback("error");
    }
  });
};

export default checkPostcodeDistance;
