import { useState, useEffect, useRef } from 'react';

const useInView = () => {
  const ref = useRef();
  const [isInView, setIsInView] = useState(false);

  const observer = new IntersectionObserver(([entry]) => {
    setIsInView(entry.isIntersecting);
  }, { rootMargin: '-100px 0px', threshold: 0 });

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref, observer]);

  return [ref, isInView];
};


export default useInView;
