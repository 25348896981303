import React from 'react';
import './ErrorPageNotFound.css';

const ErrorPage = () => {
  return (
    <div className="error-page">
      <div className="content">
        <h1 className="error-code">404</h1>
        <p className="error-text">Oops! Page not found.</p>
      </div>
    </div>
  );
};

export default ErrorPage;
