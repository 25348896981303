import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection, doc, getDoc, updateDoc} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Helmet } from 'react-helmet';
import { RotatingLines } from 'react-loader-spinner';
import { FaEdit as EditIcon } from 'react-icons/fa';
import { LuSend as SendIcon } from 'react-icons/lu';

import { db } from 'config/firebaseConfig';
import 'App.css';
import './AdminQuotes.css';

const AdminQuotes = () => {
  const navigate = useNavigate();
  const [quotes, setQuotes] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ field: 'date', direction: 'ascending' });
  const [createJobLoading, setCreateJobLoading] = useState({});
  const [resendEmailQuoteLoading, setResendEmailQuoteLoading] = useState({});
  const [emailOptions, setEmailOptions] = useState({
    sendCustomerEmail: true,
    sendAdminEmail: true
  });
  const functions = getFunctions();

  useEffect(() => {
    //Fetch quotes collection
    const fetchQuotes = async () => {
      setLoading(true);
      const quotesCollectionRef = collection(db, 'quotes');
      const querySnapshot = await getDocs(quotesCollectionRef);
      const quotesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setQuotes(quotesData);

      // Initialize email options for each quote
      const initialEmailOptions = {};
      quotesData.forEach(quote => {
        initialEmailOptions[quote.id] = { sendCustomerEmail: true, sendAdminEmail: true };
      });
      setEmailOptions(initialEmailOptions);
      sortQuotes('date');
    };

    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsData);
    };

    const fetchData = async () => {
      await fetchQuotes();
      await fetchJobs();
      await fetchProducts();
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  //Sort quotes based on sortConfig if active
  useEffect(() => {
    if (sortConfig !== null) {
      const sortedQuotes = [...quotes].sort((a, b) => {
        if (a[sortConfig.field] < b[sortConfig.field]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.field] > b[sortConfig.field]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      setQuotes(sortedQuotes);
    }

  }, [sortConfig]);

  function errorMsg(error = "Error") {
    return <span className="red">{error}</span>;
  }

  //Fetch jobs collection
  const fetchJobs = async () => {
    const jobsCollectionRef = collection(db, 'pendingJobs');
    const querySnapshot = await getDocs(jobsCollectionRef);
    const jobsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setJobs(jobsData);
  };

  //Toggle sort direction between ascending or descending
  const sortQuotes = (field) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.field === field && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ field, direction });
  };

  //Turn quote into pendingJob and send selected emails
  const createJob = async (quoteId) => {
    const isConfirmed = window.confirm("Are you sure you want to turn this quote into a job? Emails to selected parties will be sent!");
    if (isConfirmed) {
      setCreateJobLoading(prev => ({ ...prev, [quoteId]: true }));
      const functions = getFunctions();
      const acceptQuote = httpsCallable(functions, 'acceptQuote');
      acceptQuote({
        quoteId,
        sendCustomerEmail: emailOptions[quoteId].sendCustomerEmail,
        sendAdminEmail: emailOptions[quoteId].sendAdminEmail
      })
      //Update local state
      .then((result) => {
        alert(result.data.adminMessage);
        // Re-fetch the jobs to update the state
        fetchJobs();
      })
      .catch((error) => {
        alert(error.message);
      })
      .finally(() => {
        setCreateJobLoading(prev => ({ ...prev, [quoteId]: false }));
      });
    }
  };

  //Gets product by id from the downloaded products collection. Returns null if no product found.
  function getProduct(productId) {
    const product = products.find(p => p.id === productId);
    return product;
  }

  // Function to handle adding or editing a discount
  const handleDiscountChange = async (quoteId, existingDiscount) => {
    const newDiscountPercentage = prompt('Enter discount percentage:', existingDiscount || '');
    // Convert the input to a number for validation
    let newDiscountNumber = parseFloat(newDiscountPercentage);

    if (newDiscountPercentage === null) {
      // User clicked "Cancel", exit without doing anything
      return;
    }

    if (newDiscountNumber === existingDiscount) {
      alert("This discount has already been applied!");
      return;
    }

    if (isNaN(newDiscountNumber) || newDiscountNumber < 0 || newDiscountNumber > 100) {
      alert("Please enter a valid discount number!");
      return;
    }

    if (newDiscountNumber === 0 && !existingDiscount) {
      alert("No discount is already applied!")
    }

    const quoteRef = doc(db, "quotes", quoteId);
    try {
      const quoteSnap = await getDoc(quoteRef);
      if (quoteSnap.exists()) {
        let { totalCost, discountAmount = 0 } = quoteSnap.data();

        // If there's an existing discount, revert totalCost back to its original value by adding discountAmount back
        if (existingDiscount) {
          totalCost += discountAmount;
        }

        let newTotalCost = totalCost;
        let newDiscountAmount = discountAmount;

        //If it is zero, the full total cost should be returned after readding the discountAmount. No need to work out new discount.
        if (newDiscountNumber !== 0) {
          // Calculate new discountAmount and adjust totalCost
          newDiscountAmount = Number((totalCost * (newDiscountNumber / 100)).toFixed(2));
          newTotalCost = Number((totalCost - newDiscountAmount).toFixed(2));
        } else {
          newTotalCost = Number(newTotalCost.toFixed(2));
          newDiscountAmount = "";
          newDiscountNumber = "";
        }

        // Update Firestore document
        await updateDoc(quoteRef, {
          totalCost: newTotalCost,
          discountAmount: newDiscountAmount,
          discount: newDiscountNumber,
          discountCode: "", //reset discount code since discount edited by admin
        });

        // Update local state
        setQuotes(prevQuotes => prevQuotes.map(quote => quote.id === quoteId ? { ...quote, totalCost: newTotalCost, discountAmount: newDiscountAmount, discount: newDiscountNumber, discountCode: ""} : quote));
      } else {
        console.log("No such quote!");
      }
    } catch (error) {
      console.error("Error updating quote:", error);
    }
  };

  const handleResendQuote = async (quoteId) => {
    const isConfirmed = window.confirm("Are you sure you want to resend the email quote to the customer?");
    if (isConfirmed) {
      setResendEmailQuoteLoading(prev => ({ ...prev, [quoteId]: true }));
      const resendEmailQuote = httpsCallable(functions, 'resendEmailQuote');

      try {
        const result = await resendEmailQuote({ quoteId });
        console.log(result.data);
        setResendEmailQuoteLoading(prev => ({ ...prev, [quoteId]: false }));
        alert('Quote successfully resent.');
      } catch (error) {
        console.error('Error resending quote:', error);
        alert('Failed to resend quote.');
      }
    }
  };

  return (
    <div className="admin-quotes">
      <Helmet>
        <title>Admin Quotes - BoilerRight</title>
        <link rel="canonical" href="https://www.boiler-right.co.uk/admin-quotes" />
        <meta name="robots" content="noindex, nofollow"/>
      </Helmet>
      <h1>Customer Quotes</h1>
      {loading ? (
        <div className="loader-container">
          <RotatingLines
            className="main-app-loader"
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            visible={true}
          />
        </div>
      ) : (
        <div className="table-container">
          <table>
            <thead>
            <tr>
              {/* Replace these headers with your actual quote fields */}
              <th onClick={() => sortQuotes('id')}>Quote ID</th>
              <th onClick={() => sortQuotes('date')}>Date</th>
              <th onClick={() => sortQuotes('customerName')}>Customer</th>
              <th onClick={() => sortQuotes('address')}>Address</th>
              <th onClick={() => sortQuotes('email')}>Email</th>
              <th onClick={() => sortQuotes('telephone')}>Telephone</th>
              <th onClick={() => sortQuotes('desiredCombiBoiler')}>Desired Boiler</th>
              <th onClick={() => sortQuotes('desiredProgrammer')}>Desired Programmer</th>
              <th onClick={() => sortQuotes('priceCombiReplacement')}>Price Combi Replacement</th>
              <th onClick={() => sortQuotes('priceFlue')}>Price Flue</th>
              <th onClick={() => sortQuotes('priceStandoffKit')}>Price Standoff Kit (If Applicable)</th>
              <th onClick={() => sortQuotes('priceProgrammer')}>Price Programmer</th>
              <th onClick={() => sortQuotes('totalCost')}>Price Total</th>
              <th onClick={() => sortQuotes('discount')}>Discount Code (If Applicable)</th>
              <th onClick={() => sortQuotes('discount')}>Discount (If Applicable)</th>
              <th onClick={() => sortQuotes('discountAmount')}>Discount Amount (If Applicable)</th>
              <th onClick={() => sortQuotes('currentBoilerWorking')}>Current Boiler Working</th>
              <th onClick={() => sortQuotes('flueExit')}>Flue Exit</th>
              <th onClick={() => sortQuotes('pipesFromCeiling')}>Pipes From Ceiling</th>
              <th onClick={() => sortQuotes('homeType')}>Home Type</th>
              <th onClick={() => sortQuotes('numberOfBathrooms')}>No. of Bathrooms</th>
              <th onClick={() => sortQuotes('numberOfRadiators')}>Max No. of Radiators</th>
              <th>Resend Quote</th>
              <th>Create Job</th>
            </tr>
            </thead>
            <tbody>
              {quotes.map(quote => (
                <tr key={quote.id}>
                  <td>{quote.id}</td>
                  <td>{quote.date.toDate().toLocaleString()}</td>
                  <td>
                    {
                      [
                        quote.firstname,
                        quote.lastname
                      ].filter(Boolean).join(' ')
                    }
                  </td>
                  <td>
                    {
                      [
                        quote.address.firstLine,
                        quote.address.town,
                        quote.address.postcode
                      ].filter(Boolean).join(', ')
                    }
                  </td>
                  <td>{quote.email}</td>
                  <td>{quote.telephone}</td>
                  <td>{getProduct(quote.desiredCombiBoiler)?.displayName || errorMsg()}</td>
                  <td>{getProduct(quote.desiredProgrammer)?.displayName || errorMsg()}</td>
                  <td>£{quote.priceCombiReplacement}</td>
                  <td>£{quote.priceFlue}</td>
                  <td>{quote.priceStandoffKit !== 'N/A' ? `£${quote.priceStandoffKit}` : 'N/A'}</td>
                  <td>£{quote.priceProgrammer}</td>
                  <td>£{quote.totalCost}</td>
                  <td>{quote.discountCode ? quote.discountCode : "N/A"}</td>
                  <td>
                    {!jobs.some(job => job.id === quote.id) ? (
                      <>
                        {quote.discount ? (
                          <>
                            {`${quote.discount}%`}
                            <div>
                              <EditIcon className="edit-icon" onClick={() => handleDiscountChange(quote.id, quote.discount)}/>
                            </div>
                          </>
                        ) : (
                          <button className="add-discount-but" onClick={() => handleDiscountChange(quote.id)}>+</button>
                        )}
                      </>
                    ) : (
                      quote.discount ? `${quote.discount}%` : 'N/A'
                    )}
                  </td>
                  <td>{quote.discountAmount ? `£${quote.discountAmount}` : 'N/A'}</td>
                  <td>{quote.currentBoilerWorking}</td>
                  <td>{quote.flueExit}</td>
                  <td>{quote.pipesFromCeiling}</td>
                  <td>{quote.homeType}</td>
                  <td>{quote.numberOfBathrooms}</td>
                  <td>{quote.numberOfRadiators}</td>
                  <td>
                    {resendEmailQuoteLoading[quote.id] ? (
                      <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        width="30"
                        visible={true}
                      />
                    ) : (
                      <>
                        <SendIcon className="send-icon" onClick={() => handleResendQuote(quote.id)}/>
                      </>
                    )}
                  </td>
                  <td>
                    {createJobLoading[quote.id] ? (
                      <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        width="30"
                        visible={true}
                      />
                    ) : (
                      <>
                      <div className="job-creation-checkbox">
                        <label>
                          <input
                            className="larger-checkbox"
                            type="checkbox"
                            checked={jobs.some(job => job.id === quote.id)}
                            onChange={() => createJob(quote.id)}
                            disabled={jobs.some(job => job.id === quote.id)}
                          />
                        </label>
                      </div>
                      {!jobs.some(job => job.id === quote.id) && (
                        <div className="email-recipients">
                          <label>
                            <input
                              type="checkbox"
                              checked={emailOptions[quote.id]?.sendCustomerEmail}
                              onChange={() => setEmailOptions(prev => ({
                                ...prev,
                                [quote.id]: { ...prev[quote.id], sendCustomerEmail: !prev[quote.id]?.sendCustomerEmail }
                              }))}
                            />
                            Customer Email
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              checked={emailOptions[quote.id]?.sendAdminEmail}
                              onChange={() => setEmailOptions(prev => ({
                                ...prev,
                                [quote.id]: { ...prev[quote.id], sendAdminEmail: !prev[quote.id]?.sendAdminEmail }
                              }))}
                            />
                            Admin Email
                          </label>
                        </div>
                      )}
                    </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AdminQuotes;
