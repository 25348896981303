import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { RotatingLines } from 'react-loader-spinner';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import { GlobalContext } from '../../GlobalContext/GlobalContext';

import './JobCompletion.css';

function JobCompletion() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isOnline } = useContext(GlobalContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [jobCompletionID, setJobCompletionID] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [jobExists, setJobExists] = useState(false);
  const [message, setMessage] = useState("");
  const [processing, setProcessing] = useState(true);
  const [job, setJob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [jobCompletionForm, setJobCompletionForm] = useState({
    installedToManInstructions: '',
    condensePipeTermination: '',
    primaryWaterFilter: '',
    scaleReducer: '',
    miniExpansionVessel: '',
    flueScrewsSecured: '',
    condenseTrapPrimed: '',
    deaerationUsed: '',
    gasInletPressure: '',
    gasRateCheck: '',
    flueGasAnalysis: '',
    systemFlush: '',
    hotWaterCheck: '',
    systemTestedBalanced: '',
    benchmarkChecklistCompleted: '',
    warrantyRegistered: '',
    temperaturesSet: '',
    controlsExplained: ''
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const jobCompletionID = params.get("jobCompletionID");
    const editForm = params.get("editForm") === 'true';

    setJobCompletionID(jobCompletionID);
    setEditForm(editForm);

    async function fetchJob() {
      if (!jobCompletionID) {
        navigate('/page-not-found');
        return;
      }

      setProcessing(true);

      try {
        const fetchJobByCompletionID = httpsCallable(getFunctions(), 'fetchJobByCompletionID');
        const result = await fetchJobByCompletionID({ jobCompletionID });
        const jobData = result.data.job;

        if (!result.data.jobExists || ["Pending Initial Visit", "Pending Assignment", "Complete", "Deleted"].includes(jobData.jobStatus) || (editForm && jobData.jobStatus === "In Progress")) {
          navigate('/page-not-found');
          return;
        }

        if (editForm || jobData.jobStatus !== "In Progress") {
          setJobCompletionForm(jobData.engineerCompletionForm);
        }

        setJob(jobData);
        setJobExists(true);
      } catch (error) {
        setMessage(`Error fetching job: ${error.message}`);
      } finally {
        setProcessing(false);
      }
    }

    fetchJob();
  }, [location.search, navigate]);

  useEffect(() => {
    if (job && job.jobStatus === "In Progress") {
      const loadedState = { ...jobCompletionForm };

      Object.keys(jobCompletionForm).forEach(key => {
        const storageKey = `${jobCompletionID}-${key}`;
        const savedValue = localStorage.getItem(storageKey);
        if (savedValue !== null) {
          loadedState[key] = savedValue;
        }
      });

      setJobCompletionForm(loadedState);
    }
  }, [job]);

  useEffect(() => {
    if (job && job.jobStatus === "In Progress") {
      Object.entries(jobCompletionForm).forEach(([key, value]) => {
        const storageKey = `${jobCompletionID}-${key}`;
        localStorage.setItem(storageKey, value);
      });
    }
  }, [jobCompletionForm, job, jobCompletionID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setJobCompletionForm(prevForm => ({
      ...prevForm,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();  // This prevents the default form submission behavior
    setIsLoading(true);

    try {
      const functions = getFunctions();
      const actionFunc = editForm ? 'updateJobCompletionForm' : 'setStatusAwaitingSignOff';
      const action = httpsCallable(functions, actionFunc);

      const response = await action({ jobCompletionID: job.jobCompletionID, jobId: job.id, engineerCompletionForm: jobCompletionForm });
      setModalMessage(response.data.result);
      setModalIsOpen(true);
    } catch (error) {
      console.error('Error:', error);
      setIsErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModals = () => {
    setModalIsOpen(false);
    setIsErrorModalOpen(false);
    navigate('/');
  };

  const inputDisabled = (job) => {
    return !editForm && job.jobStatus !== "In Progress";
  };

  return (
    <div className="job-completion">
      <Helmet>
        <title>Job Completion - BoilerRight</title>
        <link rel="canonical" href="https://www.boiler-right.co.uk/job-completion" />
        <meta name="robots" content="noindex"/>
      </Helmet>
      {processing ? (
        <div className="loader-container">
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            visible={true}
          />
        </div>
      ) : (
        <div className="c-primary-page-sec">
          <div className="container">
            {jobExists ? (
              <>
                <h1>Job Completion Form</h1>
                <form onSubmit={handleSubmit}>
                  <div className="job-details">

                    <div className="input-group">
                      <label htmlFor="address">Address:</label>
                      <input
                        className="disabled-input"
                        id="address"
                        type="text"
                        value={job ? `${job.address.firstLine}, ${job.address.town}, ${job.address.postcode}` : ''}
                        disabled
                      />
                    </div>

                    <div className="input-group">
                      <label htmlFor="engineer">Engineer:</label>
                      <input
                        className="disabled-input"
                        id="engineer"
                        type="text"
                        value={job ? `${job.engineerAssigned}` : ''}
                        disabled
                      />
                    </div>

                    <div className="input-group">
                      <label>Has the boiler been installed in accordance with the manufacturer's instructions and all applicable regulations and standards?</label>
                        <div className="radio-group">
                          <label htmlFor="installedToManInstructionsYes">Yes</label>
                            <input
                              className={inputDisabled(job) ? "disabled-input" : ""}
                              type="radio"
                              id="installedToManInstructionsYes"
                              name="installedToManInstructions"
                              value="yes"
                              checked={jobCompletionForm.installedToManInstructions === "yes"}
                              onChange={handleInputChange}
                              disabled={!editForm && job.jobStatus !== "In Progress"}
                              required
                            />

                          <label htmlFor="installedToManInstructionsNo">No</label>
                            <input
                              className={inputDisabled(job) ? "disabled-input" : ""}
                              type="radio"
                              id="installedToManInstructionsNo"
                              name="installedToManInstructions"
                              value="no"
                              checked={jobCompletionForm.installedToManInstructions === "no"}
                              onChange={handleInputChange}
                              disabled={!editForm && job.jobStatus !== "In Progress"}
                              required
                            />
                        </div>
                    </div>

                    <div className="input-group">
                      <label>The condense pipe should preferably terminate inside the property. If the condense pipe termination point is outside the property, has it been installed with the correct pipe size and is it adequately insulated?</label>
                      <div className="radio-group">
                        <label htmlFor="condensePipeTerminationYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="condensePipeTerminationYes"
                          name="condensePipeTermination"
                          value="yes"
                          checked={jobCompletionForm.condensePipeTermination === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="condensePipeTerminationNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="condensePipeTerminationNo"
                          name="condensePipeTermination"
                          value="no"
                          checked={jobCompletionForm.condensePipeTermination === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Has an appropriate primary water filter been fitted on the return pipe as close to the boiler as possible?</label>
                      <div className="radio-group">
                        <label htmlFor="primaryWaterFilterYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="primaryWaterFilterYes"
                          name="primaryWaterFilter"
                          value="yes"
                          checked={jobCompletionForm.primaryWaterFilter === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="primaryWaterFilterNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="primaryWaterFilterNo"
                          name="primaryWaterFilter"
                          value="no"
                          checked={jobCompletionForm.primaryWaterFilter === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>If the boiler was installed in a hard water area (above 200ppm), has a suitable scale reducer been fitted on the cold water inlet to the boiler?</label>
                      <div className="radio-group">
                        <label htmlFor="scaleReducerYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="scaleReducerYes"
                          name="scaleReducer"
                          value="yes"
                          checked={jobCompletionForm.scaleReducer === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="scaleReducerNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="scaleReducerNo"
                          name="scaleReducer"
                          value="no"
                          checked={jobCompletionForm.scaleReducer === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Has a mini-expansion vessel been fitted in the property or is there an existing mini expansion vessel? Please note that this is now required by all boiler manufacturers and the lack of an appropriately fitted mini expansion vessel can invalidate the boiler warranty!</label>
                      <div className="radio-group">
                        <label htmlFor="miniExpansionVesselYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="miniExpansionVesselYes"
                          name="miniExpansionVessel"
                          value="yes"
                          checked={jobCompletionForm.miniExpansionVessel === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="miniExpansionVesselNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="miniExpansionVesselNo"
                          name="miniExpansionVessel"
                          value="no"
                          checked={jobCompletionForm.miniExpansionVessel === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Where specified by the manufacturer, have screws been used to safely secure the flue in place? Please note that not securing the flue with screws where specified by the manufacturer may invalidate the warranty!</label>
                      <div className="radio-group">
                        <label htmlFor="flueScrewsSecuredYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="flueScrewsSecuredYes"
                          name="flueScrewsSecured"
                          value="yes"
                          checked={jobCompletionForm.flueScrewsSecured === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="flueScrewsSecuredNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="flueScrewsSecuredNo"
                          name="flueScrewsSecured"
                          value="no"
                          checked={jobCompletionForm.flueScrewsSecured === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Where specified by the manufacturer, has the condense trap been primed before running the boiler?</label>
                      <div className="radio-group">
                        <label htmlFor="condenseTrapPrimedYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="condenseTrapPrimedYes"
                          name="condenseTrapPrimed"
                          value="yes"
                          checked={jobCompletionForm.condenseTrapPrimed === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="condenseTrapPrimedNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="condenseTrapPrimedNo"
                          name="condenseTrapPrimed"
                          value="no"
                          checked={jobCompletionForm.condenseTrapPrimed === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Where specified by the manufacturer, has the deaeration function been used on the boiler before running it? This function now often runs automatically on modern boilers.</label>
                      <div className="radio-group">
                        <label htmlFor="deaerationUsedYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="deaerationUsedYes"
                          name="deaerationUsed"
                          value="yes"
                          checked={jobCompletionForm.deaerationUsed === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="deaerationUsedNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="deaerationUsedNo"
                          name="deaerationUsed"
                          value="no"
                          checked={jobCompletionForm.deaerationUsed === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Have you checked the gas inlet pressure at the boiler and is it at least 18mb or in line with manufacturer's instruction?</label>
                      <div className="radio-group">
                        <label htmlFor="gasInletPressureYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="gasInletPressureYes"
                          name="gasInletPressure"
                          value="yes"
                          checked={jobCompletionForm.gasInletPressure === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="gasInletPressureNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="gasInletPressureNo"
                          name="gasInletPressure"
                          value="no"
                          checked={jobCompletionForm.gasInletPressure === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Have you carried out a gas rate check and is it in line with manufacturer's instructions, taking into account the acceptable tolerance range?</label>
                      <div className="radio-group">
                        <label htmlFor="gasRateCheckYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="gasRateCheckYes"
                          name="gasRateCheck"
                          value="yes"
                          checked={jobCompletionForm.gasRateCheck === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="gasRateCheckNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="gasRateCheckNo"
                          name="gasRateCheck"
                          value="no"
                          checked={jobCompletionForm.gasRateCheck === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Have you carried out a flue gas analysis and are the readings in line with manufacturer's instructions? Please also ensure that where CO2 requirements are explicitly stated by the manufacturer, these must also comply!</label>
                      <div className="radio-group">
                        <label htmlFor="flueGasAnalysisYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="flueGasAnalysisYes"
                          name="flueGasAnalysis"
                          value="yes"
                          checked={jobCompletionForm.flueGasAnalysis === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="flueGasAnalysisNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="flueGasAnalysisNo"
                          name="flueGasAnalysis"
                          value="no"
                          checked={jobCompletionForm.flueGasAnalysis === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Has the system been chemically flushed with an appropriate cleaning agent and refilled with a suitable corrosion inhibitor?</label>
                      <div className="radio-group">
                        <label htmlFor="systemFlushYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="systemFlushYes"
                          name="systemFlush"
                          value="yes"
                          checked={jobCompletionForm.systemFlush === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="systemFlushNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="systemFlushNo"
                          name="systemFlush"
                          value="no"
                          checked={jobCompletionForm.systemFlush === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Has the hot water been checked at all outlets?</label>
                      <div className="radio-group">
                        <label htmlFor="hotWaterCheckYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="hotWaterCheckYes"
                          name="hotWaterCheck"
                          value="yes"
                          checked={jobCompletionForm.hotWaterCheck === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="hotWaterCheckNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="hotWaterCheckNo"
                          name="hotWaterCheck"
                          value="no"
                          checked={jobCompletionForm.hotWaterCheck === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Has the heating been tested and are all radiators getting hot?</label>
                      <div className="radio-group">
                        <label htmlFor="systemTestedBalancedYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="systemTestedBalancedYes"
                          name="systemTestedBalanced"
                          value="yes"
                          checked={jobCompletionForm.systemTestedBalanced === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="systemTestedBalancedNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="systemTestedBalancedNo"
                          name="systemTestedBalanced"
                          value="no"
                          checked={jobCompletionForm.systemTestedBalanced === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Has the benchmark checklist been completed and handed over to the customer?</label>
                      <div className="radio-group">
                        <label htmlFor="benchmarkChecklistCompletedYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="benchmarkChecklistCompletedYes"
                          name="benchmarkChecklistCompleted"
                          value="yes"
                          checked={jobCompletionForm.benchmarkChecklistCompleted === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="benchmarkChecklistCompletedNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="benchmarkChecklistCompletedNo"
                          name="benchmarkChecklistCompleted"
                          value="no"
                          checked={jobCompletionForm.benchmarkChecklistCompleted === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Has the boiler warranty been registered?</label>
                      <div className="radio-group">
                        <label htmlFor="warrantyRegisteredYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="warrantyRegisteredYes"
                          name="warrantyRegistered"
                          value="yes"
                          checked={jobCompletionForm.warrantyRegistered === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="warrantyRegisteredNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="warrantyRegisteredNo"
                          name="warrantyRegistered"
                          value="no"
                          checked={jobCompletionForm.warrantyRegistered === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Have the central heating and hot water temperatures been set to the requirements of the customer?</label>
                      <div className="radio-group">
                        <label htmlFor="temperaturesSetYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="temperaturesSetYes"
                          name="temperaturesSet"
                          value="yes"
                          checked={jobCompletionForm.temperaturesSet === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="temperaturesSetNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="temperaturesSetNo"
                          name="temperaturesSet"
                          value="no"
                          checked={jobCompletionForm.temperaturesSet === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label>Have the boiler controls and any external controls been explained to the customer?</label>
                      <div className="radio-group">
                        <label htmlFor="controlsExplainedYes">Yes</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="controlsExplainedYes"
                          name="controlsExplained"
                          value="yes"
                          checked={jobCompletionForm.controlsExplained === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                        <label htmlFor="controlsExplainedNo">No</label>
                        <input
                          className={inputDisabled(job) ? "disabled-input" : ""}
                          type="radio"
                          id="controlsExplainedNo"
                          name="controlsExplained"
                          value="no"
                          checked={jobCompletionForm.controlsExplained === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "In Progress"}
                          required
                        />
                      </div>
                    </div>

                  </div>

                  {/* Conditional render for Submit/Update button based on the form state */}
                  {job && job.jobStatus === "In Progress" && (
                    <div className="center-button">
                      <button type="submit" className="initial-visit-btn btn c-submit-btn" disabled={isLoading || !isOnline}>
                        Send
                      </button>
                    </div>
                  )}
                  {editForm && (
                    <div className="center-button">
                      <button type="submit" className="initial-visit-btn btn c-submit-btn" disabled={isLoading || !isOnline}>
                        Update
                      </button>
                    </div>
                  )}

                  {isLoading &&
                    <div className="load-cont">
                      <RotatingLines
                        className="main-app-loader"
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="30"
                        visible={true}
                      />
                    </div>
                  }

                </form>
              </>
            ) : (
              <p>{message}</p>
            )}
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={handleCloseModals}
            contentLabel="Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }
            }}
          >
            <h2>{editForm ? "Update Confirmation" : "Submission Confirmation"}</h2>
            <p>{modalMessage}</p>
            <button className='btn c-modal-btn' onClick={handleCloseModals}>
              Close
            </button>
          </Modal>
          <Modal
            isOpen={isErrorModalOpen}
            onRequestClose={handleCloseModals}
            contentLabel="Error Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)'
                }
            }}
          >
            <h2>Error</h2>
            <p>Something went wrong. Please try again or contact support.</p>
            <button className='btn c-modal-btn' onClick={handleCloseModals}>
              Close
            </button>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default JobCompletion;
