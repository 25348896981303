import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../GlobalContext/GlobalContext';
import './QuoteToolDownWrapper.css';
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from 'config/firebaseConfig';

const QuoteToolDownWrapper = ({ children }) => {
  const { isQuoteToolDown } = useContext(GlobalContext);
  const [adminLoggedIn, setAdminLoggedIn] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          setAdminLoggedIn(!!idTokenResult.claims.admin);
        });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let timer;
    if (isQuoteToolDown && !adminLoggedIn) {
      timer = setTimeout(() => {
        setShowOverlay(true);
      }, 1000);
    } else {
      setShowOverlay(false);
      if (timer) {
        clearTimeout(timer);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isQuoteToolDown, adminLoggedIn]);

  return (
    <div className="quote-tool-down-wrapper" style={{ position: 'relative' }}>
      {children}
      {showOverlay && (
        <div className="quote-tool-down-overlay">
          <p className="quote-tool-down-message">
            The instant quote tool is currently down. Please contact us directly for a quote. We apologise for the inconvenience.
          </p>
        </div>
      )}
    </div>
  );
};

export default QuoteToolDownWrapper;
