import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, writeBatch, deleteDoc, setDoc, query, where, getDoc, onSnapshot} from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { RotatingLines } from 'react-loader-spinner';
import { firebaseAuth, db } from 'config/firebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';

import 'App.css';
import './AdminSettings.css';

const AdminSettings = () => {
  const navigate = useNavigate();
  const functions = getFunctions();
  const [isLoading, setIsLoading] = useState(false);
  const [sendTestSmsLoading, setSendTestSmsLoading] = useState(false);
  const [jsonInput, setJsonInput] = useState('');
  const [currentSetting, setCurrentSetting] = useState('');
  const [engineerData, setEngineerData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    dateOfBirth: '',
    gasSafeNumber: '',
    company: ''
  });
  const [customBoilerData, setCustomBoilerData] = useState({
    available: false,
    custom: true
  });
  const [customProgrammerData, setCustomProgrammerData] = useState({
    custom: true
  });
  const settingsDocRef = doc(db, 'settings', 'settings');
  const [smsNotificationsEnabled, setSmsNotificationsEnabled] = useState(false);
  const [serviceDownEnabled, setServiceDownEnabled] = useState(false);
  const [quoteToolDownEnabled, setQuoteToolDownEnabled] = useState(false);
  const [discountCodes, setDiscountCodes] = useState([]);
  const [loadingDiscountCodes, setLoadingDiscountCodes] = useState(false);

  useEffect(() => {
    if(currentSetting === "uploadProducts" || currentSetting === "viewProducts") {
      fetchProducts();
    }
  }, [currentSetting]);

  useEffect(() => {
    // Real-time subscription to the Firestore document
    const unsubscribe = onSnapshot(settingsDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setSmsNotificationsEnabled(!!docSnapshot.data().smsNotificationsEnabled);
        setServiceDownEnabled(!!docSnapshot.data().serviceDownEnabled);
        setQuoteToolDownEnabled(!!docSnapshot.data().quoteToolDownEnabled);
      } else {
        console.log("No such document!");
        setDoc(settingsDocRef, { smsNotificationsEnabled: false, serviceDownEnabled: false, quoteToolDownEnabled: false });
      }
    }, error => {
      console.error("Error fetching settings:", error);
    });

    // Cleanup function to unsubscribe from the listener on component unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (currentSetting === 'discountCodes') {
      const fetchCodes = async () => {
        setLoadingDiscountCodes(true);
        const codes = await fetchDiscountCodes();
        setDiscountCodes(codes);
        setLoadingDiscountCodes(false);
      };

      fetchCodes();
    }
  }, [currentSetting]);

  // Function to fetch products from Firebase
  const fetchProducts = async () => {
    const productsRef = collection(db, 'products');
    try {
      const snapshot = await getDocs(productsRef);
      const products = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJsonInput(JSON.stringify(products, null, 2)); // Set the fetched products as a formatted JSON string
    } catch (error) {
      console.error('Error fetching products: ', error);
      window.alert('Error fetching products');
    }
  };

  function resetObjectData(data, exceptions = []) {
    const newData = {};
    Object.keys(data).forEach(key => {
        if (exceptions.includes(key)) {
            newData[key] = data[key];  // Keep the current value for exceptions
        } else {
            newData[key] = '';  // Reset all other fields to an empty string
        }
    });
    return newData;
  }

  const updateProducts = async () => {
    const isConfirmed = window.confirm("Are you sure you want to update the products collection? The current products collection will be backed up!");
    if (isConfirmed) {
      setIsLoading(true);

      // Validate JSON
      let products;
      try {
        products = JSON.parse(jsonInput);
        if (!Array.isArray(products)) {
          throw new Error('JSON is not an array');
        }
      } catch (error) {
        console.error('Invalid JSON input:', error);
        window.alert('Invalid JSON input');
        return;
      }

      const productsRef = collection(db, 'products');
      const productsBackupRef = collection(db, 'productsBackup');

      // Backup existing documents to productsBackup
      try {
        const snapshot = await getDocs(productsRef);
        let backupBatch = writeBatch(db);

        snapshot.docs.forEach((docmnt, index) => {
          const backupDocRef = doc(productsBackupRef, docmnt.id);
          backupBatch.set(backupDocRef, docmnt.data());

          if ((index + 1) % 500 === 0 || index === snapshot.docs.length - 1) {
            backupBatch.commit();
            backupBatch = writeBatch(db);
          }
        });
      } catch (error) {
        window.alert('Error backing up documents: ' + error);
        console.error('Error backing up documents: ', error);
        return;
      }

      // Delete all documents in the products collection
      try {
        const snapshot = await getDocs(productsRef);
        let deleteBatch = writeBatch(db);

        snapshot.docs.forEach((docmnt, index) => {
          deleteBatch.delete(docmnt.ref);

          if ((index + 1) % 500 === 0 || index === snapshot.docs.length - 1) {
            deleteBatch.commit();
            deleteBatch = writeBatch(db);
          }
        });
      } catch (error) {
        window.alert('Error deleting documents: ' + error);
        console.error('Error deleting documents: ', error);
        return;
      }

      // Add all products from JSON input
      try {
        let addBatch = writeBatch(db);

        products.forEach((product, index) => {
          const docRef = doc(productsRef, product.id);
          addBatch.set(docRef, product);

          if ((index + 1) % 500 === 0 || index === products.length - 1) {
            addBatch.commit();
            addBatch = writeBatch(db);
          }
        });
      } catch (error) {
        window.alert('Error adding documents: ' + error);
        console.error('Error adding documents: ', error);
        return;
      }

      setIsLoading(false);
      window.alert("Product object uploaded successfully!");
    }
  };

  const restoreProductsFromBackup = async () => {
    const isConfirmed = window.confirm("Are you sure you want to restore the products collection from the backup? The current products collection will be deleted!");
    if (isConfirmed) {
      setIsLoading(true);

      const productsRef = collection(db, 'products');
      const productsBackupRef = collection(db, 'productsBackup');

      // Delete all documents in the products collection
      try {
        const productsSnapshot = await getDocs(productsRef);
        let deleteBatch = writeBatch(db);

        productsSnapshot.docs.forEach((docmnt, index) => {
          deleteBatch.delete(docmnt.ref);

          if ((index + 1) % 500 === 0 || index === productsSnapshot.docs.length - 1) {
            deleteBatch.commit();
            deleteBatch = writeBatch(db);
          }
        });
      } catch (error) {
        console.error('Error deleting products: ', error);
        window.alert('Error deleting products: ' + error);
        return;
      }

      // Copy documents from productsBackup to products
      try {
        const backupSnapshot = await getDocs(productsBackupRef);
        let restoreBatch = writeBatch(db);

        backupSnapshot.docs.forEach((docmnt, index) => {
          const newDocRef = doc(productsRef, docmnt.id);
          restoreBatch.set(newDocRef, docmnt.data());

          if ((index + 1) % 500 === 0 || index === backupSnapshot.docs.length - 1) {
            restoreBatch.commit();
            restoreBatch = writeBatch(db);
          }
        });
      } catch (error) {
        console.error('Error restoring products from backup: ', error);
        window.alert('Error restoring products from backup: ' + error);
        return;
      }

      fetchProducts();

      setIsLoading(false);
      window.alert("Products restored from backup successfully!");
    }
  };

  const handleInputChange = (setterFunction) => (e) => {
    const { name, value, type } = e.target;
    const isNumber = type === 'number' && value !== '';
    const formattedValue = isNumber ? parseFloat(value) : value;
    setterFunction(prevState => ({
        ...prevState,
        [name]: formattedValue
    }));
  };

  const addEngineer = async (event) => {
    const isConfirmed = window.confirm("Are you sure you want to add this engineer to the engineers database?");
    if (isConfirmed) {
      event.preventDefault(); // Prevents the default form submission behavior

      setIsLoading(true);
      try {
        const engineersRef = collection(db, 'engineers');

        // // Query the 'engineers' collection for an existing gas safe number
        // const querySnapshot = await getDocs(query(engineersRef, where("gasSafeNumber", "==", engineerData.gasSafeNumber)));
        // if (!querySnapshot.empty) {
        //   window.alert('Error: Gas Safe Number already exists in the database.');
        //   return;
        // }

        const engineerRef = doc(engineersRef);
        await setDoc(engineerRef, engineerData);
        window.alert('Engineer added successfully!');
        setEngineerData({ firstname: '', lastname: '', email: '', dateOfBirth: '', gasSafeNumber: '', company: '' }); // Reset form data
        setIsLoading(false);
      } catch (error) {
        window.alert('Error adding engineer: ', error);
        console.error('Error adding engineer: ', error);
      }
    }
  };

  const addCustomBoiler = async (event) => {
    const isConfirmed = window.confirm("Are you sure you want to add this custom boiler to the products collection?");
    if (isConfirmed) {
        event.preventDefault(); // Prevents the default form submission behavior

        setIsLoading(true);
        try {
            const customBoilersRef = collection(db, 'products');

            // Ensure the ID is not empty and not undefined
            if (!customBoilerData.id) {
                window.alert('The custom boiler must have a valid ID.');
                setIsLoading(false);
                return;
            }

            // Check if a document with the same ID already exists
            const customBoilerRef = doc(customBoilersRef, customBoilerData.id);
            const docSnap = await getDoc(customBoilerRef);

            if (docSnap.exists()) {
                window.alert('Error: A product with this ID already exists.');
                setIsLoading(false);
                return; // Stop the function if the product already exists
            }

            // Proceed to add a new document with a specific ID if it does not exist
            await setDoc(customBoilerRef, customBoilerData);
            window.alert('Custom boiler added successfully!');
            setCustomBoilerData(resetObjectData(customBoilerData, ['available', 'custom'])); // Reset form data to initial state
            setIsLoading(false);
        } catch (error) {
            window.alert('Error adding custom boiler: ' + error);
            console.error('Error adding custom boiler: ', error);
            setIsLoading(false);
        }
    }
  };

  const addCustomProgrammer = async (event) => {
    const isConfirmed = window.confirm("Are you sure you want to add this custom programmer to the products collection?");
    if (isConfirmed) {
        event.preventDefault(); // Prevents the default form submission behavior

        setIsLoading(true);
        try {
            const customProgrammersRef = collection(db, 'products');

            // Ensure the ID is not empty and not undefined
            if (!customProgrammerData.id) {
                window.alert('The custom programmer must have a valid ID.');
                setIsLoading(false);
                return;
            }

            // Check if a document with the same ID already exists
            const customProgrammerRef = doc(customProgrammersRef, customProgrammerData.id);
            const docSnap = await getDoc(customProgrammerRef);

            if (docSnap.exists()) {
                window.alert('Error: A product with this ID already exists.');
                setIsLoading(false);
                return; // Stop the function if the product already exists
            }

            // Proceed to add a new document with a specific ID if it does not exist
            await setDoc(customProgrammerRef, customProgrammerData);
            window.alert('Custom programmer added successfully!');
            setCustomProgrammerData(resetObjectData(customProgrammerData, ['custom'])); // Reset form data to initial state
            setIsLoading(false);
        } catch (error) {
            window.alert('Error adding custom programmer: ' + error);
            console.error('Error adding custom programmer: ', error);
            setIsLoading(false);
        }
    }
  };

  const handleSmsNotificationsCheckboxChange = async (event) => {
    const newIsChecked = event.target.checked;
    setSmsNotificationsEnabled(newIsChecked); // Optimistically update UI

    // Update Firestore
    try {
      await setDoc(settingsDocRef, { smsNotificationsEnabled: newIsChecked }, { merge: true });
    } catch (error) {
      console.error('Error updating settings:', error);
      setSmsNotificationsEnabled(!newIsChecked); // Revert UI update if there's an error
    }
  };

  const handleServiceDownCheckboxChange = async (event) => {
    const newIsChecked = event.target.checked;
    setServiceDownEnabled(newIsChecked); // Optimistically update UI

    // Update Firestore
    try {
      await setDoc(settingsDocRef, { serviceDownEnabled: newIsChecked }, { merge: true });
    } catch (error) {
      console.error('Error updating settings:', error);
      setServiceDownEnabled(!newIsChecked); // Revert UI update if there's an error
    }
  };

  const handleQuoteToolDownCheckboxChange = async (event) => {
    const newIsChecked = event.target.checked;
    setQuoteToolDownEnabled(newIsChecked); // Optimistically update UI

    // Update Firestore
    try {
      await setDoc(settingsDocRef, { quoteToolDownEnabled: newIsChecked }, { merge: true });
    } catch (error) {
      console.error('Error updating settings:', error);
      setQuoteToolDownEnabled(!newIsChecked); // Revert UI update if there's an error
    }
  };

  const renderUploadProducts = () => (
    <div className="setting">
      <h2 className="setting-title">Upload New Products Object</h2>
      <textarea
        className="product-upload-text-area"
        value={jsonInput}
        onChange={e => setJsonInput(e.target.value)}
        placeholder="Paste JSON object containing product information here..."
      />
      <div>
        <button onClick={updateProducts}>Upload</button>
      </div>
      <div>
        <button onClick={restoreProductsFromBackup}>Restore From Backup</button>
      </div>
      {
        isLoading && (
          <div className="loader-container">
            <RotatingLines
              className="main-app-loader"
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="25"
              visible={true}
            />
          </div>
      )}
    </div>
  );

  const renderViewProducts = () => (
    <div className="setting">
      <h2 className="setting-title">View Products Object</h2>
      <pre className="product-view-text-area">
        {jsonInput}
      </pre>
      {
        isLoading && (
          <div className="loader-container">
            <RotatingLines
              className="main-app-loader"
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="25"
              visible={true}
            />
          </div>
      )}
    </div>
  );

  const renderAddEngineer = () => (
    <div className="setting">
      <h2 className="setting-title">Add New Engineer</h2>
      <form onSubmit={addEngineer}>
        <div className="form-group">
          <input
            type="text"
            name="firstname"
            value={engineerData.firstname}
            onChange={handleInputChange(setEngineerData)}
            placeholder="First Name"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="lastname"
            value={engineerData.lastname}
            onChange={handleInputChange(setEngineerData)}
            placeholder="Surname"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            name="email"
            value={engineerData.email}
            onChange={handleInputChange(setEngineerData)}
            placeholder="Email"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="date"
            name="dateOfBirth"
            value={engineerData.dateOfBirth}
            onChange={handleInputChange(setEngineerData)}
            placeholder="Date of Birth"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="gasSafeNumber"
            value={engineerData.gasSafeNumber}
            onChange={handleInputChange(setEngineerData)}
            placeholder="Gas Safe Number"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="company"
            value={engineerData.company}
            onChange={handleInputChange(setEngineerData)}
            placeholder="Company"
            required
          />
        </div>
        <div className="form-group">
          <button type="submit">Add</button>
        </div>
        {
          isLoading && (
            <div className="loader-container">
              <RotatingLines
                className="main-app-loader"
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="25"
                visible={true}
              />
            </div>
        )}
      </form>
    </div>
  );

  const renderAddCustomBoiler = () => (
    <div className="setting">
      <h2 className="setting-title">Add Custom Boiler</h2>
      <form onSubmit={addCustomBoiler}>
        <div className="form-group">
          <input
            type="text"
            name="id"
            value={customBoilerData.id}
            onChange={handleInputChange(setCustomBoilerData)}
            placeholder="ID"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="displayName"
            value={customBoilerData.displayName}
            onChange={handleInputChange(setCustomBoilerData)}
            placeholder="Display Name"
            required
          />
        </div>
        <div className="form-group">
          <select className="select-box" id="subcategory" name="subcategory" onChange={handleInputChange(setCustomBoilerData)} value={customBoilerData.subcategory} required>
              <option value="" disabled selected>Subcategory</option>
              <option value="gasCombiBoiler">Gas Combi Boiler</option>
          </select>
        </div>
        <div className="form-group">
          <input
            type="text"
            name="make"
            value={customBoilerData.make}
            onChange={handleInputChange(setCustomBoilerData)}
            placeholder="Make"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            step="0.01"
            name="price"
            value={customBoilerData.price}
            onChange={handleInputChange(setCustomBoilerData)}
            placeholder="Price"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            name="heatOutput"
            value={customBoilerData.heatOutput}
            onChange={handleInputChange(setCustomBoilerData)}
            placeholder="Heat Output (KWh)"
            required
          />
        </div>
        <div className="form-group">
          <select className="select-box" id="magneticFilterIncluded" name="magneticFilterIncluded" onChange={handleInputChange(setCustomBoilerData)} value={customBoilerData.magneticFilterIncluded} required>
            <option value="" disabled selected>Magnetic Filter Included?</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
        <div className="form-group">
          <input
            type="number"
            step="0.01"
            name="priceStandardFlue"
            value={customBoilerData.priceStandardFlue}
            onChange={handleInputChange(setCustomBoilerData)}
            placeholder="Price Flue"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            step="0.01"
            name="priceFlueRoofKit"
            value={customBoilerData.priceFlueRoofKit}
            onChange={handleInputChange(setCustomBoilerData)}
            placeholder="Price Flue Roof Kit"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            step="0.01"
            name="priceStandoffKit"
            value={customBoilerData.priceStandoffKit}
            onChange={handleInputChange(setCustomBoilerData)}
            placeholder="Price Standoff Kit"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            name="guarantee"
            value={customBoilerData.guarantee}
            onChange={handleInputChange(setCustomBoilerData)}
            placeholder="Guarantee"
            required
          />
        </div>
        <div className="form-group">
          <button type="submit">Add</button>
        </div>
        {
          isLoading && (
            <div className="loader-container">
              <RotatingLines
                className="main-app-loader"
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="25"
                visible={true}
              />
            </div>
        )}
      </form>
    </div>
  );

  const renderAddCustomProgrammer = () => (
    <div className="setting">
      <h2 className="setting-title">Add Custom Programmer</h2>
      <form onSubmit={addCustomProgrammer}>
        <div className="form-group">
          <input
            type="text"
            name="id"
            value={customProgrammerData.id}
            onChange={handleInputChange(setCustomProgrammerData)}
            placeholder="ID"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="displayName"
            value={customProgrammerData.displayName}
            onChange={handleInputChange(setCustomProgrammerData)}
            placeholder="Display Name"
            required
          />
        </div>
        <div className="form-group">
          <select className="select-box" id="subcategory" name="subcategory" onChange={handleInputChange(setCustomProgrammerData)} value={customProgrammerData.subcategory} required>
              <option value="" disabled selected>Subcategory</option>
              <option value="wirelessProgrammer">Wireless Programmer</option>
          </select>
        </div>
        <div className="form-group">
          <input
            type="number"
            step="0.01"
            name="price"
            value={customProgrammerData.price}
            onChange={handleInputChange(setCustomProgrammerData)}
            placeholder="Price"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="description"
            value={customProgrammerData.description}
            onChange={handleInputChange(setCustomProgrammerData)}
            placeholder="Description (Optional)"
          />
        </div>
        <div className="form-group">
          <button type="submit">Add</button>
        </div>
        {
          isLoading && (
            <div className="loader-container">
              <RotatingLines
                className="main-app-loader"
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="25"
                visible={true}
              />
            </div>
        )}
      </form>
    </div>
  );

  const renderSmsSettings = () => (
    <div className="setting">
      <label>
        SMS Notifications Enabled
        <input
          className="larger-checkbox"
          type="checkbox"
          checked={smsNotificationsEnabled}
          onChange={handleSmsNotificationsCheckboxChange}
        />
      </label>
      <div className="settings-button">
        {!sendTestSmsLoading ? (
            <button onClick={() => sendTestSMS()}>Send Test SMS</button>
          ) : (
            <div className="loader-container">
              <RotatingLines
                className="main-app-loader"
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="25"
                visible={true}
              />
            </div>
          )
        }
      </div>
    </div>
  );

  const renderDiscountCodes = () => (
    <div className="setting">
      <h2 className="setting-title">Discount Codes</h2>
      {loadingDiscountCodes ? (
        <div className="loader-container">
          <RotatingLines
            className="main-app-loader"
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="25"
            visible={true}
          />
        </div>
      ) : (
        <ul className="discount-codes-list">
          {discountCodes.map((code) => (
            <li key={code.id} className="discount-code-item">
              <span className="discount-code-name">{code.id} ({code.percentage}%)</span>
              <div>
                <input
                  type="checkbox"
                  checked={code.active}
                  onChange={() => handleToggleDiscountCodeActive(code.id, code.active)}
                  className="discount-code-checkbox"
                />
                <label className="discount-code-label">Active</label>
            </div>
              <button onClick={() => handleDeleteDiscountCode(code.id)} className="x-button">X</button>
            </li>
          ))}
        </ul>
      )}
      <button onClick={handleAddDiscountCode} className="add-discount-code-button">Add</button>
    </div>
  );

  const renderOther = () => (
    <div className="setting">
      <div className="other-setting">
        <label>
          Service Down
          <input
            className="other-settings-larger-checkbox"
            type="checkbox"
            checked={serviceDownEnabled}
            onChange={handleServiceDownCheckboxChange}
          />
        </label>
      </div>
      <div className="other-setting">
        <label>
          Quote Tool Down
          <input
            className="other-settings-larger-checkbox"
            type="checkbox"
            checked={quoteToolDownEnabled}
            onChange={handleQuoteToolDownCheckboxChange}
          />
        </label>
      </div>
    </div>
  );

  const handleAddDiscountCode = async () => {
    const name = window.prompt("Enter the discount code name:");
    if (!name) return;

    const percentage = window.prompt("Enter the discount percentage:");
    if (!percentage || isNaN(percentage)) {
      window.alert("Invalid percentage value");
      return;
    }

    const newDiscountCode = {
      percentage: parseFloat(percentage),
      active: true
    };

    try {
      await setDoc(doc(db, 'discountCodes', name), newDiscountCode);
      setDiscountCodes([...discountCodes, { id: name, ...newDiscountCode }]);
    } catch (error) {
      console.error('Error adding discount code: ', error);
      window.alert('Error adding discount code');
    }
  };

  const handleDeleteDiscountCode = async (id) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete the discount code "${id}"?`);
    if (!isConfirmed) return;

    try {
      await deleteDoc(doc(db, 'discountCodes', id));
      setDiscountCodes(discountCodes.filter(code => code.id !== id));
    } catch (error) {
      console.error('Error deleting discount code: ', error);
      window.alert('Error deleting discount code');
    }
  };

  const fetchDiscountCodes = async () => {
    const discountCodesRef = collection(db, 'discountCodes');
    try {
      const snapshot = await getDocs(discountCodesRef);
      const discountCodes = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      return discountCodes;
    } catch (error) {
      console.error('Error fetching discount codes: ', error);
      window.alert('Error fetching discount codes');
      return [];
    }
  };

  const handleToggleDiscountCodeActive = async (id, currentStatus) => {
    try {
      const discountCodeRef = doc(db, 'discountCodes', id);
      await setDoc(discountCodeRef, { active: !currentStatus }, { merge: true });

      setDiscountCodes(discountCodes.map(code =>
        code.id === id ? { ...code, active: !currentStatus } : code
      ));
    } catch (error) {
      console.error('Error updating discount code: ', error);
      window.alert('Error updating discount code');
    }
  };

  const sendTestSMS = async () => {

    setSendTestSmsLoading(true);
    const sendSmsFunction = httpsCallable(functions, 'sendSms');

    try {
      const result = await sendSmsFunction({
        message: 'This is a test message from my React app!',
        to: '+447544486202', // Replace with the recipient's phone number
      });
      if (result.data.success) {
        alert('Message sent successfully!');
      } else {
        alert('Failed to send message: ' + result.data.error);
      }
      setSendTestSmsLoading(false);
    } catch (error) {
      console.error('Error sending SMS:', error);
      alert('Failed to send message.');
      setSendTestSmsLoading(false);
    }
  };

  return (
    <div className="admin-settings">
      <Helmet>
        <title>Admin Settings - BoilerRight</title>
        <link rel="canonical" href="https://www.boiler-right.co.uk/admin-settings" />
        <meta name="robots" content="noindex, nofollow"/>
      </Helmet>
      <h1>Settings</h1>

      {currentSetting === '' && (
        <div className="settings-buttons">
          <div className="settings-button">
            <button onClick={() => setCurrentSetting('uploadProducts')}>Upload New Products Object</button>
          </div>
          <div className="settings-button">
            <button onClick={() => setCurrentSetting('viewProducts')}>View Products Object</button>
          </div>
          <div className="settings-button">
            <button onClick={() => setCurrentSetting('addCustomBoiler')}>Add Custom Boiler</button>
          </div>
          <div className="settings-button">
            <button onClick={() => setCurrentSetting('addCustomProgrammer')}>Add Custom Programmer</button>
          </div>
          <div className="settings-button">
            <button onClick={() => setCurrentSetting('addEngineer')}>Add Engineer</button>
          </div>
          <div className="settings-button">
            <button onClick={() => setCurrentSetting('smsSettings')}>SMS Settings</button>
          </div>
          <div className="settings-button">
            <button onClick={() => setCurrentSetting('discountCodes')}>Discount Codes</button>
          </div>
          <div className="settings-button">
            <button onClick={() => setCurrentSetting('other')}>Other</button>
          </div>
        </div>
      )}

      {currentSetting === 'uploadProducts' && renderUploadProducts()}
      {currentSetting === 'viewProducts' && renderViewProducts()}
      {currentSetting === 'addCustomBoiler' && renderAddCustomBoiler()}
      {currentSetting === 'addCustomProgrammer' && renderAddCustomProgrammer()}
      {currentSetting === 'addEngineer' && renderAddEngineer()}
      {currentSetting === 'smsSettings' && renderSmsSettings()}
      {currentSetting === 'discountCodes' && renderDiscountCodes()}
      {currentSetting === 'other' && renderOther()}
    </div>
  );
};

export default AdminSettings;
