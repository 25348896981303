const boilerManufacturerInfo = [

    { name: "Ideal",
      description: ["Offering a varied lineup of combi boilers including the Logic Max, Logic Plus and the high-end Vogue Max series, Ideal Boilers emphasizes reliability, with warranty periods spanning from 7 to 12 years.",
      "Renowned for their energy-efficient design, Ideal Boilers has earned accolades, including the Quiet Mark award, for their commitment to quiet operation and sustainability.",
      "Catering to diverse homeowner requirements and budgets, Ideal ensures an efficient and dependable heating solution for all."
      ]
    },
    { name: "Baxi",
      description: [
        "Baxi's legacy in delivering efficient heating solutions shines brightly with its combi boiler range.",
        "The series, including the 200, 400, 600 and 800, showcase Baxi's approach to offering choice, with varying warranty periods tailored to meet the diverse needs and budgets of homeowners. Each series is available with multiple heat outputs to cater to specific heating requirements, from smaller flats to large family homes.",
        "Across all series and their variants, Baxi's Combi boilers stand as a testament to the brand’s dedication to user-friendliness, sustainability, and delivering reliable heating solutions for every home."
      ]
    },
    { name: "Main",
      description: [
        "Owned by the Baxi brand, the Main Eco Compact series blends efficiency with economy.",
        "These combi boilers are thoughtfully crafted for modern homes with their compact design that effortlessly fits into standard kitchen cupboards. While the 25 kW variant is suitable for small to medium-sized households, the 30 kW excels in catering to slightly larger homes.",
        "Both models assure reliability, user-friendly interfaces, and a dedication to green heating solutions and come with a 5-year parts & labour warranty."
      ]
    },
    { name: "Worcester Bosch",
      description: [
      "Description for Worcester. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    ]
  }

];

export default boilerManufacturerInfo;
