import React from 'react';
import { Helmet } from 'react-helmet';

const Blank = () => {
  return (
    <div className="blank">
      <Helmet>
        <title>Blank Page - BoilerRight</title>
        <link rel="canonical" href="https://www.boiler-right.co.uk/blank" />
        <meta name="robots" content="noindex"/>
        {process.env.REACT_APP_FIREBASE_ENV === "development" && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>
      <div className="c-primary-page-sec">
        <div className="container">
          <div className="center-container">
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blank;
