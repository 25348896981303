import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { RotatingLines } from 'react-loader-spinner'
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { GlobalContext } from '../../GlobalContext/GlobalContext';

import './InitialVisit.css'

function InitialVisit() {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { isOnline } = useContext(GlobalContext);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [initialVisitFormID, setInitialVisitFormID] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [jobExists, setJobExists] = useState(false);
  const [message, setMessage] = useState("");
  const [processing, setProcessing] = useState(true);
  const [job, setJob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initialVisitForm, setInitialVisitForm] = useState({
    date: new Date().toISOString().split('T')[0], // preset to today's date
    mainsFlowRate: '',
    mainsWaterPressure: '',
    flowRateMeetsRequirements: '',
    gasPipeUpgradeRequired: '',
    recommendedActionGasPipeUpgrade: '',
    recommendedActionFlowRate: '',
    recommendedActionGasPipe: '',
    recommendedActionPipeSize: '',
    boilerFloor: '',
    longLadderForFlue: '',
    boilerRoom: '',
    boilerLocationCompliance: '',
    boilerInsulationRequired: '',
    recommendedActionBoilerLocation: '',
    flueLocationCompliance: '',
    recommendedActionFlueLocation: '',
    condensePipeTermination: '',
    additionalCondenseFittings: '',
    condenseTerminationStandards: '',
    recommendedActionCondenseTermination: '',
    installationCanProceed: '',
    recommendedActionInstallation: '',
    coAlarmRequired: '',
    mainsIsolationPoint: '',
    heatingDrainPoint: '',
    coldRequiredAsap: '',
  });

  useEffect(() => {
    // Retrieve and assign URL parameters and set state
    const params = new URLSearchParams(location.search);
    const initialVisitFormID = params.get("initialVisitFormID");
    const editForm = params.get("editForm") === 'true';

    setInitialVisitFormID(initialVisitFormID);
    setEditForm(editForm);

    // Fetch job details if an ID is provided
    const fetchJob = async () => {
      if (!initialVisitFormID) {
        navigate('/page-not-found');
        return;
      }

      setProcessing(true);

      try {
        const fetchJobByInitialVisitFormID = httpsCallable(getFunctions(), 'fetchJobByInitialVisitFormID');
        const result = await fetchJobByInitialVisitFormID({ initialVisitFormID });

        const jobData = result.data.job;

        //Page should not be viewable if no job, job marked as "Complete" or attempting to access editable form before having submitted form a first time
        if (!result.data.jobExists || jobData.jobStatus === "Complete" || jobData.jobStatus === "Deleted" || (editForm && jobData.jobStatus === "Pending Initial Visit")) {
          navigate('/page-not-found');
          return;
        }

        // Load form data from Firestore if in editable mode or job status is not "Pending Initial Visit", i.e. form has already been submitted once
        if (editForm || jobData.jobStatus !== "Pending Initial Visit") {
          setInitialVisitForm(jobData.initialVisitForm);
        }

        setJob(jobData);
        setJobExists(true);
      } catch (error) {
        setMessage(`Error fetching job: ${error.message}`);
      } finally {
        setProcessing(false);
      }
    };

    fetchJob().catch(error => {
      setMessage(`Error: ${error.message}`);
    });
  }, [location.search, navigate]);

  // Load stored values from localStorage when the component mounts, but only when completing form for the first time
  useEffect(() => {
    if (job && job.jobStatus === "Pending Initial Visit") {
      // Create a copy of initialState to populate with values from localStorage
      const loadedState = { ...initialVisitForm };

      Object.keys(initialVisitForm).forEach(key => {
        const storageKey = `${initialVisitFormID}-${key}`;
        const savedValue = localStorage.getItem(storageKey);
        if (savedValue !== null) {
          loadedState[key] = savedValue;
        }
      });

      setInitialVisitForm(loadedState);
    }
  }, [job]);

  // Save stored values to localStorage when form changes, but only when completing form for the first time
  useEffect(() => {
    if (job && job.jobStatus === "Pending Initial Visit") {
      for (const [key, value] of Object.entries(initialVisitForm)) {
        const storageKey = `${initialVisitFormID}-${key}`;
        localStorage.setItem(storageKey, value);
      }
    }
  }, [initialVisitForm, job, initialVisitFormID]);

  const setStatusPendingAssignment = async (initialVisitFormID) => {
    setIsLoading(true);
    const functions = getFunctions();
    const setStatus = httpsCallable(functions, 'setStatusPendingAssignment');

    try {
      const response = await setStatus({ initialVisitFormID, initialVisitForm });
      setModalMessage(response.data.result);
      setModalIsOpen(true);
    } catch (error) {
      console.error('Error:', error);
      setIsErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const updateInitialVisitForm = async (initialVisitFormID) => {
    setIsLoading(true);
    const functions = getFunctions();
    const updateForm = httpsCallable(functions, 'updateInitialVisitForm');

    try {
      const response = await updateForm({ initialVisitFormID, initialVisitForm });
      setModalMessage(response.data.result);
      setModalIsOpen(true);
    } catch (error) {
      console.error('Error:', error);
      setIsErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInitialVisitForm(prevForm => ({
      ...prevForm,
      [name]: value
    }));
  };

  const handleCloseModals = () => {
    setModalIsOpen(false);
    setIsErrorModalOpen(false);
    navigate('/');
  };

  const inputDisabled = (job) => {
    return job.jobStatus !== "Pending Initial Visit" && !editForm
  };

  const renderConditionalInput = (condition, name, label, inputType = 'text') => {
    return condition ? (
      <div className="input-group">
        <label htmlFor={name}>{label}</label>
        <input
          type={inputType}
          name={name}
          id={name}
          value={initialVisitForm[name]}
          onChange={handleInputChange}
        />
      </div>
    ) : null;
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    // Assuming you're deciding between creating a new submission or updating an existing one
    if (editForm) {
      await updateInitialVisitForm(initialVisitFormID); // Your existing logic for updating a form
    } else {
      await setStatusPendingAssignment(initialVisitFormID); // Your existing logic for a new form submission
    }
  };

  return (
    <div className="initial-visit">
      <Helmet>
        <title>Initial Visit - BoilerRight</title>
        <link rel="canonical" href="https://www.boiler-right.co.uk/initial-visit" />
        <meta name="robots" content="noindex"/>
        {process.env.REACT_APP_FIREBASE_ENV === "development" && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>
      {processing ? (
        <div className="loader-container">
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            visible={true}
          />
        </div>
      ) : (
        <div className="c-primary-page-sec">
          <div className="container">
            {jobExists ? (
              <>
                <h1>Initial Visit Form</h1>
                <div className="job-details">
                <div className="job-details">
                  <form onSubmit={handleSubmit}>

                    {/* Customer Name */}
                    <div className="input-group">
                      <label htmlFor="customerName">Customer Name:</label>
                      <input
                        className="disabled-input"
                        id="customerName"
                        type="text"
                        value={job ? `${job.firstname} ${job.lastname}` : ''}
                        readOnly
                      />
                    </div>

                    {/* Customer Address */}
                    <div className="input-group">
                      <label htmlFor="address">Address:</label>
                      <input
                        className="disabled-input"
                        id="address"
                        type="text"
                        value={job ? `${job.address.firstLine}, ${job.address.town}, ${job.address.postcode}` : ''}
                        readOnly
                      />
                    </div>

                    {/* Date of Initial Visit */}
                    <div className="input-group">
                      <label htmlFor="date">Date of Initial Visit:</label>
                      <input
                        className={"disabled-input"}
                        name="date"
                        id="date"
                        type="date"
                        value={initialVisitForm.date}
                        onChange={handleInputChange}
                        readOnly
                      />
                    </div>

                    {/* Confirm customer provided details */}
                    <div className="input-group confirm-details-group">
                      <label htmlFor="desiredCombiBoiler">Desired Combi Boiler:</label>
                      <input
                        className="disabled-input confirm-details-group-input"
                        id="desiredCombiBoiler"
                        type="text"
                        value={job ? `${job.desiredCombiBoiler}` : ''}
                        readOnly
                      />

                      <label htmlFor="desiredProgrammer">Desired Programmer:</label>
                      <input
                        className="disabled-input confirm-details-group-input"
                        id="desiredProgrammer"
                        type="text"
                        value={job ? `${job.desiredProgrammer}` : ''}
                        readOnly
                      />

                      <label htmlFor="flueExit">Flue Exit:</label>
                      <input
                        className="disabled-input confirm-details-group-input"
                        id="flueExit"
                        type="text"
                        value={job ? `${job.flueExit}` : ''}
                        readOnly
                      />

                      <label htmlFor="pipesFromCeiling">Pipes from Ceiling:</label>
                      <input
                        className="disabled-input confirm-details-group-input"
                        id="pipesFromCeiling"
                        type="text"
                        value={job ? `${job.pipesFromCeiling}` : ''}
                        readOnly
                      />

                      <label htmlFor="numberOfBathrooms">Number of Bathrooms:</label>
                      <input
                        className="disabled-input confirm-details-group-input"
                        id="numberOfBathrooms"
                        type="text"
                        value={job ? `${job.numberOfBathrooms}` : ''}
                        readOnly
                      />

                      <label htmlFor="numberOfRadiators">Number of Radiators:</label>
                      <input
                        className="disabled-input confirm-details-group-input"
                        id="numberOfRadiators"
                        type="text"
                        value={job ? `${job.numberOfRadiators}` : ''}
                        readOnly
                      />
                    </div>

                    {/* Mains Water Flow Rate */}
                    <div className="input-group">
                      <label htmlFor="mainsFlowRate">Mains Water Flow Rate* (l/m):</label>
                      <input
                        className={inputDisabled(job) ? "disabled-input" : ""}
                        name="mainsFlowRate"
                        id="mainsFlowRate"
                        type="number"
                        value={initialVisitForm.mainsFlowRate}
                        onChange={handleInputChange}
                        readOnly={!editForm && job.jobStatus !== "Pending Initial Visit"}
                        required
                      />
                    </div>

                    {/* Mains Water Pressure*/}
                    <div className="input-group">
                      <label htmlFor="mainsWaterPressure">Mains Water Pressure, If Applicable (bar):</label>
                      <input
                        className={inputDisabled(job) ? "disabled-input" : ""}
                        name="mainsWaterPressure"
                        id="mainsWaterPressure"
                        type="number"
                        value={initialVisitForm.mainsWaterPressure}
                        onChange={handleInputChange}
                        readOnly={!editForm && job.jobStatus !== "Pending Initial Visit"}
                      />
                    </div>

                    {/* Is flow rate/pressure adequate */}
                      <div className="input-group">
                        <label>Does the mains water flow rate/pressure meet the minimum requirements for the quoted boiler?*</label>
                          <div className="radio-group">
                            <label htmlFor="flowRateMeetsRequirementsYes">Yes</label>
                              <input
                                type="radio"
                                id="flowRateMeetsRequirementsYes"
                                name="flowRateMeetsRequirements"
                                value="yes"
                                checked={initialVisitForm.flowRateMeetsRequirements === "yes"}
                                onChange={handleInputChange}
                                disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                                required
                              />

                            <label htmlFor="flowRateMeetsRequirementsNo">No</label>
                              <input
                                type="radio"
                                id="flowRateMeetsRequirementsNo"
                                name="flowRateMeetsRequirements"
                                value="no"
                                checked={initialVisitForm.flowRateMeetsRequirements === "no"}
                                onChange={handleInputChange}
                                disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                                required
                              />
                          </div>
                      </div>

                    {/* Conditional: Recommended Action for poor flow rate/pressure */}
                    {initialVisitForm.flowRateMeetsRequirements === 'no' && (
                      <div className="input-group">
                        <label htmlFor="recommendedActionFlowRate">Recommended Action Poor Flow Rate/Pressure:</label>
                        <textarea
                          className={`large-textarea ${inputDisabled(job) ? "disabled-input" : ""}`}
                          name="recommendedActionFlowRate"
                          id="recommendedActionFlowRate"
                          value={initialVisitForm.recommendedActionFlowRate}
                          onChange={handleInputChange}
                          readOnly={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                      </div>
                    )}

                    {/* Gas Pipe Size */}
                    <div className="input-group">
                      <label>Does the gas pipe need to be upgraded?* (Min. 22mm diameter normally required. If distance from meter to boiler is large, a pipe size calculation should be carried out)</label>
                      <div className="radio-group">
                        <label htmlFor="gasPipeUpgradeRequiredYes">Yes</label>
                        <input
                          type="radio"
                          id="gasPipeUpgradeRequiredYes"
                          name="gasPipeUpgradeRequired"
                          value="yes"
                          checked={initialVisitForm.gasPipeUpgradeRequired === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                        <label htmlFor="gasPipeUpgradeRequiredNo">No</label>
                        <input
                          type="radio"
                          id="gasPipeUpgradeRequiredNo"
                          name="gasPipeUpgradeRequired"
                          value="no"
                          checked={initialVisitForm.gasPipeUpgradeRequired === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                      </div>
                    </div>

                    {/* Conditional: Recommended Action for gas pipe upgrade */}
                    {initialVisitForm.gasPipeUpgradeRequired === 'yes' && (
                      <div className="input-group">
                        <label htmlFor="recommendedActionGasPipeUpgrade">Please state what size the gas pipe should be upgraded to and describe the best way of doing so:</label>
                        <textarea
                          className={`large-textarea ${inputDisabled(job) ? "disabled-input" : ""}`}
                          name="recommendedActionGasPipeUpgrade"
                          id="recommendedActionGasPipeUpgrade"
                          value={initialVisitForm.recommendedActionGasPipeUpgrade}
                          onChange={handleInputChange}
                          readOnly={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                      </div>
                    )}

                    {/* Boiler in permitted location */}
                    <div className="input-group">
                      <label>Is the boiler in a permitted location and does it meet all required clearances?*</label>
                      <div className="radio-group">
                        <label htmlFor="boilerLocationComplianceYes">Yes</label>
                        <input
                          type="radio"
                          id="boilerLocationComplianceYes"
                          name="boilerLocationCompliance"
                          value="yes"
                          checked={initialVisitForm.boilerLocationCompliance === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                        <label htmlFor="boilerLocationComplianceNo">No</label>
                        <input
                          type="radio"
                          id="boilerLocationComplianceNo"
                          name="boilerLocationCompliance"
                          value="no"
                          checked={initialVisitForm.boilerLocationCompliance === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                      </div>
                    </div>

                    {/* Conditional: Recommended Action for Boiler Location */}
                    {initialVisitForm.boilerLocationCompliance === 'no' && (
                      <div className="input-group">
                        <label htmlFor="recommendedActionBoilerLocation">Recommended Action for Boiler Location:</label>
                        <textarea
                          className={`large-textarea ${inputDisabled(job) ? "disabled-input" : ""}`}
                          name="recommendedActionBoilerLocation"
                          id="recommendedActionBoilerLocation"
                          value={initialVisitForm.recommendedActionBoilerLocation}
                          onChange={handleInputChange}
                          readOnly={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                      </div>
                    )}


                    {/* Flue Location and Compliance */}
                    <div className="input-group">
                      <label>Is the flue in a permitted location and does it meet all other requirements?*</label>
                      <div className="radio-group">
                        <label htmlFor="flueLocationComplianceYes">Yes</label>
                        <input
                          type="radio"
                          id="flueLocationComplianceYes"
                          name="flueLocationCompliance"
                          value="yes"
                          checked={initialVisitForm.flueLocationCompliance === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                        <label htmlFor="flueLocationComplianceNo">No</label>
                        <input
                          type="radio"
                          id="flueLocationComplianceNo"
                          name="flueLocationCompliance"
                          value="no"
                          checked={initialVisitForm.flueLocationCompliance === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                      </div>
                    </div>

                    {/* Conditional: Recommended Action for Flue Location */}
                    {initialVisitForm.flueLocationCompliance === 'no' && (
                      <div className="input-group">
                        <label htmlFor="recommendedActionFlueLocation">Recommended Action for Flue Location:</label>
                        <textarea
                          className={`large-textarea ${inputDisabled(job) ? "disabled-input" : ""}`}
                          name="recommendedActionFlueLocation"
                          id="recommendedActionFlueLocation"
                          value={initialVisitForm.recommendedActionFlueLocation}
                          onChange={handleInputChange}
                          readOnly={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                      </div>
                    )}

                    {/* Condense Pipe Termination Location */}
                    <div className="input-group">
                      <label htmlFor="condensePipeTermination">Where does the condense pipe terminate?*</label>
                      <select
                        className={inputDisabled(job) ? "disabled-input" : ""}
                        name="condensePipeTermination"
                        id="condensePipeTermination"
                        value={initialVisitForm.condensePipeTermination}
                        onChange={handleInputChange}
                        disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                        required
                      >
                        <option value="">Select</option>
                        <option value="inside">Inside</option>
                        <option value="outside">Outside</option>
                      </select>
                    </div>

                    {/* Condense Termination Standards Compliance */}
                    <div className="input-group">
                      <label>Does the condense pipe termination point meet the required standards?*</label>
                      <div className="radio-group">
                        <label htmlFor="condenseTerminationStandardsYes">Yes</label>
                        <input
                          type="radio"
                          id="condenseTerminationStandardsYes"
                          name="condenseTerminationStandards"
                          value="yes"
                          checked={initialVisitForm.condenseTerminationStandards === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                        <label htmlFor="condenseTerminationStandardsNo">No</label>
                        <input
                          type="radio"
                          id="condenseTerminationStandardsNo"
                          name="condenseTerminationStandards"
                          value="no"
                          checked={initialVisitForm.condenseTerminationStandards === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                      </div>
                    </div>

                    {/* Conditional: Recommended Action for Condense Termination */}
                    {initialVisitForm.condenseTerminationStandards === 'no' && (
                      <div className="input-group">
                        <label htmlFor="recommendedActionCondenseTermination">Recommended Action for Condense Termination:</label>
                        <textarea
                          className={`large-textarea ${inputDisabled(job) ? "disabled-input" : ""}`}
                          name="recommendedActionCondenseTermination"
                          id="recommendedActionCondenseTermination"
                          value={initialVisitForm.recommendedActionCondenseTermination}
                          onChange={handleInputChange}
                          readOnly={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                      </div>
                    )}

                    {/* Installation Can Proceed */}
                    <div className="input-group">
                      <label>Can the installation proceed?*</label>
                      <div className="radio-group">
                        <label htmlFor="installationCanProceedYes">Yes</label>
                        <input
                          type="radio"
                          id="installationCanProceedYes"
                          name="installationCanProceed"
                          value="yes"
                          checked={initialVisitForm.installationCanProceed === "yes"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                        <label htmlFor="installationCanProceedNo">No</label>
                        <input
                          type="radio"
                          id="installationCanProceedNo"
                          name="installationCanProceed"
                          value="no"
                          checked={initialVisitForm.installationCanProceed === "no"}
                          onChange={handleInputChange}
                          disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                      </div>
                    </div>

                    {/* Conditional: Recommended Action if Installation Cannot Proceed */}
                    {initialVisitForm.installationCanProceed === 'no' && (
                      <div className="input-group">
                        <label htmlFor="recommendedActionInstallation">Recommended Action if Installation Cannot Proceed:</label>
                        <textarea
                          className={`large-textarea ${inputDisabled(job) ? "disabled-input" : ""}`}
                          name="recommendedActionInstallation"
                          id="recommendedActionInstallation"
                          value={initialVisitForm.recommendedActionInstallation}
                          onChange={handleInputChange}
                          readOnly={!editForm && job.jobStatus !== "Pending Initial Visit"}
                          required
                        />
                      </div>
                    )}

                    {/* Conditional: Additional information collection if installation can proceed */}
                    {initialVisitForm.installationCanProceed === 'yes' && (
                      <>

                        {/* Boiler Floor */}
                        <div className="input-group">
                          <label htmlFor="boilerFloor">What floor is the boiler on?</label>
                          <select
                            className={inputDisabled(job) ? "disabled-input" : ""}
                            name="boilerFloor"
                            id="boilerFloor"
                            value={initialVisitForm.boilerFloor}
                            onChange={handleInputChange}
                            disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                            required
                          >
                            <option value="">Select</option>
                            <option value="ground">Ground</option>
                            <option value="first">First</option>
                            <option value="second">Second</option>
                            <option value="other">Other</option>
                          </select>
                        </div>

                        {/* Is ladder required */}
                        <div className="input-group">
                          <label>Is a long ladder required to reach the flue?</label>
                          <div className="radio-group">
                            <label htmlFor="longLadderForFlueYes">Yes</label>
                            <input
                              type="radio"
                              id="longLadderForFlueYes"
                              name="longLadderForFlue"
                              value="yes"
                              checked={initialVisitForm.longLadderForFlue === "yes"}
                              onChange={handleInputChange}
                              disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                              required
                            />
                            <label htmlFor="longLadderForFlueNo">No</label>
                            <input
                              type="radio"
                              id="longLadderForFlueNo"
                              name="longLadderForFlue"
                              value="no"
                              checked={initialVisitForm.longLadderForFlue === "no"}
                              onChange={handleInputChange}
                              disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                              required
                            />
                          </div>
                        </div>

                        {/* Boiler Room */}
                        <div className="input-group">
                          <label htmlFor="boilerRoom">Which room is the boiler in?</label>
                          <select
                            className={inputDisabled(job) ? "disabled-input" : ""}
                            name="boilerRoom"
                            id="boilerRoom"
                            value={initialVisitForm.boilerRoom}
                            onChange={handleInputChange}
                            disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                            required
                          >
                            <option value="">Select</option>
                            <option value="Kitchen">Kitchen</option>
                            <option value="Living Room">Living Room</option>
                            <option value="Bathroom">Bathroom</option>
                            <option value="Bedroom">Bedroom</option>
                            <option value="Hallway">Hallway</option>
                            <option value="Landing">Landing</option>
                            <option value="Loft">Loft</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>

                        {/* Boiler insulation requirements */}
                        <div className="input-group">
                          <label>Does the boiler pipework require insulation, i.e. located in an unheated space?</label>
                          <div className="radio-group">
                            <label htmlFor="boilerInsulationRequiredYes">Yes</label>
                            <input
                              type="radio"
                              id="boilerInsulationRequiredYes"
                              name="boilerInsulationRequired"
                              value="yes"
                              checked={initialVisitForm.boilerInsulationRequired === "yes"}
                              onChange={handleInputChange}
                              disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                              required
                            />
                            <label htmlFor="boilerLocationComplianceNo">No</label>
                            <input
                              type="radio"
                              id="boilerInsulationRequiredNo"
                              name="boilerInsulationRequired"
                              value="no"
                              checked={initialVisitForm.boilerInsulationRequired === "no"}
                              onChange={handleInputChange}
                              disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                              required
                            />
                          </div>
                        </div>

                        {/* Additional Condense Fittings */}
                        <div className="input-group">
                          <label htmlFor="additionalCondenseFittings">What additional condense fittings are required, if any?</label>
                          <textarea
                            className={`large-textarea ${inputDisabled(job) ? "disabled-input" : ""}`}
                            name="additionalCondenseFittings"
                            id="additionalCondenseFittings"
                            value={initialVisitForm.additionalCondenseFittings}
                            onChange={handleInputChange}
                            readOnly={!editForm && job.jobStatus !== "Pending Initial Visit"}
                            required
                          />
                        </div>

                        {/* CO Alarm Requirement */}
                        <div className="input-group">
                          <label>Is a CO alarm required? (Only mandatory for rental properties)</label>
                          <div className="radio-group">
                            <label htmlFor="coAlarmRequiredYes">Yes</label>
                            <input
                              type="radio"
                              id="coAlarmRequiredYes"
                              name="coAlarmRequired"
                              value="yes"
                              checked={initialVisitForm.coAlarmRequired === "yes"}
                              onChange={handleInputChange}
                              disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                              required
                            />
                            <label htmlFor="coAlarmRequiredNo">No</label>
                            <input
                              type="radio"
                              id="coAlarmRequiredNo"
                              name="coAlarmRequired"
                              value="no"
                              checked={initialVisitForm.coAlarmRequired === "no"}
                              onChange={handleInputChange}
                              disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                              required
                            />
                          </div>
                        </div>

                        {/* Mains Water Isolation Point */}
                        <div className="input-group">
                          <label htmlFor="mainsIsolationPoint">Where is the nearest functioning mains water isolation point?</label>
                          <input
                            className={inputDisabled(job) ? "disabled-input" : ""}
                            name="mainsIsolationPoint"
                            id="mainsIsolationPoint"
                            type="text"
                            value={initialVisitForm.mainsIsolationPoint}
                            onChange={handleInputChange}
                            readOnly={!editForm && job.jobStatus !== "Pending Initial Visit"}
                            required
                          />
                        </div>

                        {/* Mains Water Isolation Point */}
                        <div className="input-group">
                          <label htmlFor="heatingDrainPoint">Where is the most practical central heating drain point?</label>
                          <input
                            className={inputDisabled(job) ? "disabled-input" : ""}
                            name="heatingDrainPoint"
                            id="heatingDrainPoint"
                            type="text"
                            value={initialVisitForm.heatingDrainPoint}
                            onChange={handleInputChange}
                            readOnly={!editForm && job.jobStatus !== "Pending Initial Visit"}
                            required
                          />
                        </div>

                        {/* Special requirements */}
                        <div className="input-group">
                          <label>Does the customer require the cold water supply to be turned back on as soon as possible?</label>
                          <div className="radio-group">
                            <label htmlFor="coldRequiredAsapYes">Yes</label>
                            <input
                              type="radio"
                              id="coldRequiredAsapYes"
                              name="coldRequiredAsap"
                              value="yes"
                              checked={initialVisitForm.coldRequiredAsap === "yes"}
                              onChange={handleInputChange}
                              disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                              required
                            />
                            <label htmlFor="coldRequiredAsapNo">No</label>
                            <input
                              type="radio"
                              id="coldRequiredAsapNo"
                              name="coldRequiredAsap"
                              value="no"
                              checked={initialVisitForm.coldRequiredAsap === "no"}
                              onChange={handleInputChange}
                              disabled={!editForm && job.jobStatus !== "Pending Initial Visit"}
                              required
                            />
                          </div>
                        </div>

                      </>
                    )}

                    {/* Conditional render for Submit/Update button based on the form state */}
                    {job && job.jobStatus === "Pending Initial Visit" && (
                      <div className="center-button">
                        <button type="submit" className="initial-visit-btn btn c-submit-btn" disabled={isLoading || !isOnline}>
                          Send
                        </button>
                      </div>
                    )}
                    {editForm && (
                      <div className="center-button">
                        <button type="submit" className="initial-visit-btn btn c-submit-btn" disabled={isLoading || !isOnline}>
                          Update
                        </button>
                      </div>
                    )}

                    {isLoading &&
                      <div className="load-cont">
                        <RotatingLines
                          className="main-app-loader"
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="30"
                          visible={true}
                        />
                      </div>
                    }

                  </form>
                </div>
              </div>
            </>
            ) : (
              <p>{message}</p>
            )}
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => handleCloseModals()} // Function to close the modal when clicking outside
            contentLabel="Meep"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }
            }}
          >
            <h2>Thanks</h2>
            <p>{modalMessage}</p>
            <button className='btn c-modal-btn' onClick={() => handleCloseModals()}>
              Close
            </button>
          </Modal>
          <Modal
            isOpen={isErrorModalOpen}
            onRequestClose={() => handleCloseModals()} // Function to close the modal when clicking outside
            contentLabel="Meep"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }
            }}
          >
            <h2>Error</h2>
            <p>Something went wrong. Please try again or contact the site administrator.</p>
            <button className='btn c-modal-btn' onClick={() => handleCloseModals()}>
              Close
            </button>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default InitialVisit;
