import Modal from 'react-modal';

const ErrorModal = ({ isOpen, onRequestClose, children, optionalErrorTitle, optionalErrorMessage }) => {
  const errorTitle = optionalErrorTitle ? optionalErrorTitle : "Error"
  const errorMessage = optionalErrorMessage ? optionalErrorMessage : "Something went wrong. Please try again or contact the site administrator."

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Error Modal"
      style={{
          overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
      }}
    >
      <h2>{errorTitle}</h2>
      <p>{errorMessage}</p>
      <button onClick={onRequestClose}>Close</button>
    </Modal>
  );
}

export default ErrorModal;
