import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from "firebase/firestore";
import Modal from 'react-modal';
import { IoIosArrowRoundBack as BackArrow } from 'react-icons/io';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {Link, useNavigate} from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner'
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from 'config/firebaseConfig';


import './InstantQuoteTool.css';
import ErrorModal from 'components/Modals/ErrorModal/ErrorModal'
import BoilerManufacturerInfo from './BoilerManufacturerInfo.js';
const imageContext = require.context('./Images', false, /\.(jpg|webp|svg)$/);

const InstantQuoteTool = ({ address }) => {
  const [step, setStep] = useState(0);
  const [minRequiredHeatOutput, setMinRequiredHeatOutput] = useState(0);
  const [stepHistory, setStepHistory] = useState([]);
  const [gasCombiBoilers, setGasCombiBoilers] = useState([]);
  const [wirelessProgrammers, setWirelessProgrammers] = useState([]);
  const [isConsentGiven, setConsent] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMake, setSelectedMake] = useState('Main');
  const [selectedGuarantee, setSelectedGuarantee] = useState('2');
  const [answers, setAnswers] = useState({
    numberOfRadiators: '',
    numberOfBathrooms: '',
    homeType: '',
    flueExit: '',
    currentBoilerWorking: '',
    desiredCombiBoiler: '',
    desiredProgrammer: '',
    pipesFromCeiling: '',
    firstname: '',
    lastname: '',
    telephone: '',
    email: '',
    discount: '',
    discountCode: '',
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [adminLoggedIn, setAdminLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          setAdminLoggedIn(!!idTokenResult.claims.admin);
        });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchProducts = async (subcategory, setterFunction) => {
      const functions = getFunctions();
      const getProductsBySubcategory = httpsCallable(functions, 'getProductsBySubcategory');

      try {
        const result = await getProductsBySubcategory({ subcategory });
        setterFunction(result.data);
      } catch (error) {
        console.error(`Error fetching ${subcategory} products:`, error);
      }
    };

    fetchProducts('gasCombiBoiler', setGasCombiBoilers);
    fetchProducts('wirelessProgrammer', setWirelessProgrammers);
  }, []);

  //Preload all images for this component into cache
  useEffect(() => {
    Object.values(images).forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  const handleStep0Button = () => {
    setStep(1);
  }

  const handleNextStep = () => {
    // Check if the current question is answered
    const currentQuestion = {
      1: 'numberOfRadiators',
      2: 'numberOfBathrooms',
      3: 'homeType',
      4: 'flueExit',
      5: 'pipesFromCeiling',
      6: 'currentBoilerWorking',
      7: 'desiredCombiBoiler',
      8: 'desiredProgrammer'
    }[step];

    setShowNotes(false);
    window.scrollTo(0, 0);

    if (!answers[currentQuestion]) {
      setError('Please select an answer');
      return;
    }

    setStepHistory(prevHistory => [...prevHistory, step]);

    setError(null);
    if (step === 6) {
      const numberOfRadiators = parseInt(answers.numberOfRadiators, 10);
      const numberOfBathrooms = parseInt(answers.numberOfBathrooms, 10);

      if (numberOfRadiators > 20 || numberOfBathrooms >= 3) {
        setMinRequiredHeatOutput(35);
      }
      else if (numberOfRadiators > 10 || numberOfBathrooms >= 2) {
        setMinRequiredHeatOutput(28)
      }
      else {
        setMinRequiredHeatOutput(24)
      }

      setStep(step + 1);
    }
    else {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    if (stepHistory.length === 0) {
      return;
    }

    const previousStep = stepHistory[stepHistory.length - 1];
    setStep(previousStep);
    setStepHistory((prevStepHistory) => prevStepHistory.slice(0, -1));
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAnswers({ ...answers, [name]: value });
    setError(null); // clear error when user fills the answer
  };

  const submitAnswers = async (e) => {
    e.preventDefault();

    const functions = getFunctions();
    const addQuoteData = httpsCallable(functions, 'addQuoteData');

    try {
      setIsLoading(true);
      await addQuoteData({
        address: address,
        numberOfRadiators: parseInt(answers.numberOfRadiators || "0", 10),
        numberOfBathrooms: parseInt(answers.numberOfBathrooms || "0", 10),
        homeType: answers.homeType,
        flueExit: answers.flueExit,
        currentBoilerWorking: answers.currentBoilerWorking,
        desiredCombiBoiler: answers.desiredCombiBoiler,
        desiredProgrammer: answers.desiredProgrammer,
        pipesFromCeiling: answers.pipesFromCeiling,
        firstname: answers.firstname,
        lastname: answers.lastname,
        telephone: answers.telephone,
        email: answers.email,
        discount: parseInt(answers.discount || "0", 10),
        discountCode: answers.discountCode
      });
      setIsLoading(false);
      setModalIsOpen(true);
    } catch (error) {
      console.error('Error saving answers:', error);
      setIsErrorModalOpen(true);
    }
  };

  const toggleNotes = () => {
    setShowNotes(!showNotes);
  };

  const handleClose = () => {
    setModalIsOpen(false);
  };

  const images = imageContext.keys().reduce((imageMap, imagePath) => {
    const boilerId = imagePath.replace(/^\.\/|(\.[^\.]+)$/g, ''); // Removes './' prefix and any file extension
    imageMap[boilerId] = imageContext(imagePath);
    return imageMap;
  }, {});

  function getImageUrl(selectedBoilerId) {
    return images[selectedBoilerId] || "";  // You can add a fallback image path in place of the empty string here
  }

  const handleRadNumInput = (number) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      numberOfRadiators: number,
    }));
  };

  const handleBathNumInput = (number) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      numberOfBathrooms: number,
    }));
  };

  const handleHomeTypeInput = (homeType) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      homeType: homeType,
    }));
  };

  const handleFlueExitInput = (flueExit) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      flueExit: flueExit,
    }));
  };

  const handleStandoffKitInput = (pipesFromCeiling) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      pipesFromCeiling: pipesFromCeiling,
    }));
  };

  const handleCombiBoilerWorkingInput = (currentBoilerWorking) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      currentBoilerWorking: currentBoilerWorking,
    }));
  };

  return (
    <div className="instant-quote-tool">
      <div>
        {step === 0 && (
          <div className="question-container">
            <p className="step0-text">Answer the following questions to get your quote for a <span className="step0-highlighted-text">gas combi boiler replacement</span>.</p>
            <p className="step0-text-small">Interested in a different boiler service? Please <Link className="step0-link" to="/request-callback">contact us</Link> directly.</p>
            <button className="btn btn c-modal-btn" onClick={handleStep0Button}>Ok</button>
          </div>
        )}
        {step === 1 && (
          <div className="question-container">
            <p className="question-text">
              How many radiators are there in your property? Please include any radiators you plan to add in the future.
            </p>
            <div className="button-container">
              <button
                className={`number-button ${answers.numberOfRadiators === 9 ? 'selected' : ''}`}
                onClick={() => handleRadNumInput(9)}
              >
                1-9
                <img className="number-button-img" src={getImageUrl("radiator")} alt="Radiator" style={{ width: '30px', height: '25px'}} />
              </button>
              <button
                className={`number-button ${answers.numberOfRadiators === 19 ? 'selected' : ''}`}
                onClick={() => handleRadNumInput(19)}
              >
                10-19
                <img className="number-button-img" src={getImageUrl("radiator")} alt="Radiator" style={{ width: '30px', height: '25px'}} />
              </button>
              <button
                className={`number-button ${answers.numberOfRadiators === 20 ? 'selected' : ''}`}
                onClick={() => handleRadNumInput(20)}
              >
                20+
                <img className="number-button-img" src={getImageUrl("radiator")} alt="Radiator" style={{ width: '35px', height: '30px'}} />
              </button>
            </div>
            <button className="btn c-modal-btn" onClick={handleNextStep}>Next</button>
          </div>
        )}
        {step === 2 && (
          <div className="question-container">
            <p className="question-text">How many separate baths or showers (non-electric) does your property have? Please include any you plan to add in the future.</p>
            <div className="notes-container">
              <div className ="notes-button" onClick={toggleNotes}>
                {showNotes ? '-' : '+'}
              </div>
              {showNotes && (
                <p className="question-note">If you have more than 3 separate baths or non-electric showers, a combi boiler will not normally be able to meet your hot water needs. Please contact us directly to discuss other options.</p>
              )}
            </div>
            <div className="button-container">
              <button
                className={`number-button ${answers.numberOfBathrooms === 1 ? 'selected' : ''}`}
                onClick={() => handleBathNumInput(1)}
              >
                1
                <img className="number-button-img" src={getImageUrl("bathtub")} alt="bathtub" style={{ width: '35px', height: '30px'}} />
              </button>
              <button
                className={`number-button ${answers.numberOfBathrooms === 2 ? 'selected' : ''}`}
                onClick={() => handleBathNumInput(2)}
              >
                2
                <img className="number-button-img" src={getImageUrl("bathtub")} alt="bathtub" style={{ width: '35px', height: '30px'}} />
              </button>
              <button
                className={`number-button ${answers.numberOfBathrooms === 3 ? 'selected' : ''}`}
                onClick={() => handleBathNumInput(3)}
              >
                3
                <img className="number-button-img" src={getImageUrl("bathtub")} alt="bathtub" style={{ width: '35px', height: '30px'}} />
              </button>
            </div>
            <button className="btn c-modal-btn" onClick={handleNextStep}>Next</button>
          </div>
        )}
        {step === 3 && (
          <div className="question-container">
            <p className="question-text">Which of these best describes your property?</p>
            <div className="button-container">
              <button
                className={`number-button ${answers.homeType === "Detached" ? 'selected' : ''}`}
                onClick={() => handleHomeTypeInput("Detached")}
              >
                Detached
                <img className="number-button-img" src={getImageUrl("house-detached")} alt="detach" style={{ width: '35px', height: '30px'}} />
              </button>
              <button
                className={`number-button ${answers.homeType === "Semi-detached" ? 'selected' : ''}`}
                onClick={() => handleHomeTypeInput("Semi-detached")}
              >
                Semi-detached
                <img className="number-button-img" src={getImageUrl("house-semi-detached")} alt="semi-detach" style={{ width: '40px', height: '35px'}} />
              </button>
              <button
                className={`number-button ${answers.homeType === "Terraced" ? 'selected' : ''}`}
                onClick={() => handleHomeTypeInput("Terraced")}
              >
                Terraced
                <img className="number-button-img" src={getImageUrl("house-terraced")} alt="terraced" style={{ width: '40px', height: '35px'}} />
              </button>
              <button
                className={`number-button ${answers.homeType === "Bungalow" ? 'selected' : ''}`}
                onClick={() => handleHomeTypeInput("Bungalow")}
              >
                Bungalow
                <img className="number-button-img" src={getImageUrl("house-bungalow")} alt="bungalow" style={{ width: '40px', height: '35px'}} />
              </button>
              <button
                className={`number-button ${answers.homeType === "Flat" ? 'selected' : ''}`}
                onClick={() => handleHomeTypeInput("Flat")}
              >
                Flat
                <img className="number-button-img" src={getImageUrl("house-flat")} alt="flat" style={{ width: '25px', height: '40px'}} />
              </button>
            </div>
            <button className="btn c-modal-btn" onClick={handleNextStep}>Next</button>
          </div>
        )}
        {step === 4 && (
          <div className="question-container">
            <p className="question-text">Does your boiler flue exit the property through the outside wall or the roof?</p>
            <div className="notes-container">
              <div className ="notes-button" onClick={toggleNotes}>
                {showNotes ? '-' : '+'}
              </div>
              {showNotes && (
                <p className="question-note">The flue is the large pipe at the top of the boiler which dispels products of combustion to the outside.</p>
              )}
            </div>
            <div className="button-container">
              <button
                className={`number-button ${answers.flueExit === "Outside Wall" ? 'selected' : ''}`}
                onClick={() => handleFlueExitInput("Outside Wall")}
              >
                Wall
                <img className="number-button-img" src={getImageUrl("flue-wall")} alt="flue-wall" style={{ width: '35px', height: '40px'}} />
              </button>
              <button
                className={`number-button ${answers.flueExit === "Roof" ? 'selected' : ''}`}
                onClick={() => handleFlueExitInput("Roof")}
              >
                Roof
                <img className="number-button-img rotated" src={getImageUrl("flue-wall")} alt="flue-roof" style={{ width: '35px', height: '40px'}} />
              </button>
            </div>
            <button className="btn c-modal-btn" onClick={handleNextStep}>Next</button>
          </div>
        )}
        {step === 5 && (
          <div className="question-container">
            <p className="question-text">Are there any pipes running down from the ceiling behind the boiler?</p>
            <div className="notes-container">
              <div className ="notes-button" onClick={toggleNotes}>
                {showNotes ? '-' : '+'}
              </div>
              {showNotes && (
                <p className="question-note">Some boilers will require a separate standoff kit to cater for pipes running behind it.</p>
              )}
            </div>
            <div className="button-container">
              <button
                className={`number-button ${answers.pipesFromCeiling === "Yes" ? 'selected' : ''}`}
                onClick={() => handleStandoffKitInput("Yes")}
              >
                Yes
                <img className="number-button-img" src={getImageUrl("boiler-ico-top-pipes")} alt="boiler-pipes-top" style={{ width: '50px', height: '50px'}} />
              </button>
              <button
                className={`number-button ${answers.pipesFromCeiling === "No" ? 'selected' : ''}`}
                onClick={() => handleStandoffKitInput("No")}
              >
                No
                <img className="number-button-img" src={getImageUrl("boiler-ico-bottom-pipes")} alt="boiler-pipes-bottom" style={{ width: '50px', height: '50px'}} />
              </button>
            </div>
            <button className="btn c-modal-btn" onClick={handleNextStep}>Next</button>
          </div>
        )}
        {step === 6 && (
          <div className="question-container">
            <p className="question-text">Is your current boiler working?</p>
            <div className="notes-container">
              <div className ="notes-button" onClick={toggleNotes}>
                {showNotes ? '-' : '+'}
              </div>
              {showNotes && (
                <p className="question-note">We always try to accommodate next day installation for urgent replacements where necessary.</p>
              )}
            </div>
            <div className="button-container">
              <button
                className={`number-button ${answers.currentBoilerWorking === "Yes" ? 'selected' : ''}`}
                onClick={() => handleCombiBoilerWorkingInput("Yes")}
              >
                Working
                <img className="number-button-img" src={getImageUrl("cogwheel-working")} alt="cogwheel-working-img" style={{ width: '28px', height: '33px'}} />
              </button>
              <button
                className={`number-button ${answers.currentBoilerWorking === "No" ? 'selected' : ''}`}
                onClick={() => handleCombiBoilerWorkingInput("No")}
              >
                Broken
                <img className="number-button-img" src={getImageUrl("cogwheel-not-working")} alt="cogwheel-not-working-img" style={{ width: '30px', height: '35px'}} />
              </button>
            </div>
            <button className="btn c-modal-btn" onClick={handleNextStep}>Next</button>
          </div>
        )}
        {step === 7 && (
          <div className="question-container">
            <p className="question-text">We've worked out that your new boiler needs to be at least {minRequiredHeatOutput} kW. Please select one from below (unsuitable options have been greyed out):</p>
            <div className="inline-radio-title extra-padding-manuf">Manufacturer</div>
            <div className="inline-radio-label-container">
              <label className="inline-radio-label">
                <input type="radio" value="Baxi" checked={selectedMake === 'Baxi'} onChange={(e) => setSelectedMake(e.target.value)} />
                <span className="custom-radio"></span>
                Baxi
              </label>
              <label className="inline-radio-label">
                <input type="radio" value="Main" checked={selectedMake === 'Main'} onChange={(e) => setSelectedMake(e.target.value)} />
                <span className="custom-radio"></span>
                Main
              </label>
              <label className="inline-radio-label">
                <input type="radio" value="Ideal" checked={selectedMake === 'Ideal'} onChange={(e) => setSelectedMake(e.target.value)} />
                <span className="custom-radio"></span>
                Ideal
              </label>
              {adminLoggedIn && (
                <label className="inline-radio-label">
                  <input type="radio" value="Custom" checked={selectedMake === 'Custom'} onChange={(e) => setSelectedMake(e.target.value)} />
                  <span className="custom-radio"></span>
                  Custom
                </label>
              )}
            </div>
            <div className="select-container">
              <select className="styled-select" name="desiredCombiBoiler" onChange={handleChange}>
                <option value="">
                  {
                    gasCombiBoilers.find(product => product.id === answers.desiredCombiBoiler)
                      ? `${gasCombiBoilers.find(product => product.id === answers.desiredCombiBoiler).displayName}`
                      : 'select'
                  }
                </option>
                {selectedMake === 'Custom'
                  ? gasCombiBoilers
                      .filter(product => product.subcategory === 'gasCombiBoiler' && product.custom === true)
                      .map(product => (
                        <option key={product.id} value={product.id} disabled={product.heatOutput < minRequiredHeatOutput} className={product.heatOutput < minRequiredHeatOutput ? "option-disabled" : "option-enabled"}>
                          {product.displayName}
                        </option>
                      ))
                  : gasCombiBoilers
                      .filter(product => product.available && product.make === selectedMake && product.guarantee >= parseInt(selectedGuarantee))
                      .map(product => (
                        <option key={product.id} value={product.id} disabled={product.heatOutput < minRequiredHeatOutput} className={product.heatOutput < minRequiredHeatOutput ? "option-disabled" : "option-enabled"}>
                          {product.displayName}
                        </option>
                      ))
                }
              </select>
            </div>
            <div className="description-container">
              {answers.desiredCombiBoiler && <div className="image-div">
                  <img className="boiler-imgs" src={getImageUrl(answers.desiredCombiBoiler)} alt="Selected combi boiler" />
              </div>}
              {answers.desiredCombiBoiler && <div className="description">
                  <p className="description-paragraph description-centered"><b>Boiler:</b> {gasCombiBoilers.find(product => product.id === answers.desiredCombiBoiler).displayName} </p>
                  <p className="description-paragraph description-centered"><b>Base Price:</b> £{gasCombiBoilers.find(product => product.id === answers.desiredCombiBoiler).price} </p>
                  <p className="description-paragraph description-centered"><b>Guarantee:</b> {gasCombiBoilers.find(product => product.id === answers.desiredCombiBoiler).guarantee} years</p>
                  <p className="description-paragraph description-centered"><b>Heat Output:</b> {gasCombiBoilers.find(product => product.id === answers.desiredCombiBoiler).heatOutput} kWh </p>
                  <p className="description-paragraph">
                    {BoilerManufacturerInfo.find(m => m.name === gasCombiBoilers.find(product => product.id === answers.desiredCombiBoiler)?.make)?.description?.map((paragraph, index) => (
                        <p className="description-paragraph description-extra-padding" key={index}>{paragraph}</p>
                    ))}
                </p>
              </div>}
            </div>
            <button className="btn c-modal-btn" onClick={handleNextStep}>Next</button>
          </div>
        )}
        {step === 8 && (
          <div className="question-container">
            <p className="question-text">Please select a wireless programmer from the list.</p>
            <div className="notes-container">
              <div className ="notes-button" onClick={toggleNotes}>
                {showNotes ? '-' : '+'}
              </div>
              {showNotes && (
                <p className="question-note">A programmable room thermostat must be fitted with all new boilers to comply with building regulations. </p>
              )}
            </div>
            <div className="select-container">
              <select className="styled-select" name="desiredProgrammer" onChange={handleChange}>
                <option value="">
                  {
                    wirelessProgrammers.find(product => product.id === answers.desiredProgrammer)
                      ? `${wirelessProgrammers.find(product => product.id === answers.desiredProgrammer).displayName}`
                      : 'select'
                  }
                </option>
                {wirelessProgrammers.filter(product => !product.custom).map(product => (
                  <option key={product.id} value={product.id}>
                    {product.displayName}
                  </option>
                ))}
                {adminLoggedIn && wirelessProgrammers.filter(product => product.custom).map(product => (
                  <option key={product.id} value={product.id}>
                    {product.displayName} [custom]
                  </option>
                ))}
              </select>
            </div>
            <div className="description-container">
              {answers.desiredProgrammer && <div className="image-div">
                  <img className="programmer-imgs" src={getImageUrl(answers.desiredProgrammer)} alt="Selected programmer" />
              </div>}
              {answers.desiredProgrammer && <div className="description">
                  <p className="description-paragraph description-centered"><b>Programmer:</b> {wirelessProgrammers.find(product => product.id === answers.desiredProgrammer).displayName} </p>
                  <p className="description-paragraph description-centered"><b>Base Price:</b> £{wirelessProgrammers.find(product => product.id === answers.desiredProgrammer).price} </p>
                  <p className="description-paragraph description-extra-padding-programmer">{wirelessProgrammers.find(product => product.id === answers.desiredProgrammer).description} </p>
                  <p className="description-paragraph description-extra-padding-programmer">{wirelessProgrammers.find(product => product.id === answers.desiredProgrammer).note} </p>
              </div>}
            </div>
            <button className="btn c-modal-btn" onClick={handleNextStep}>Next</button>
          </div>
        )}
        {step > 8 && (
          <div className="question-container">
            <p><b>Please enter your details to receive your quote.</b></p>
            <div className="contact-form w-100">
              <form onSubmit={submitAnswers}>
                {/* <label htmlFor="first-name">First Name:</label> */}
                <input
                  id="first-name"
                  className="form-control"
                  placeholder='First Name'
                  type="text"
                  name="firstname"
                  onChange={handleChange}
                  value={answers.firstname ? answers.firstname : ""}
                  required
                  autoComplete="given-name"
                />

                {/* <label htmlFor="last-name">Last Name:</label> */}
                <input
                  id="last-name"
                  className="form-control"
                  placeholder='Last Name'
                  type="text"
                  name="lastname"
                  onChange={handleChange}
                  required
                  value={answers.lastname ? answers.lastname : ""}
                  autoComplete="family-name"
                />

                {/* <label htmlFor="telephone">Telephone:</label> */}
                <input
                  id="telephone"
                  className="form-control"
                  placeholder='Telephone'
                  type="number"
                  name="telephone"
                  onChange={handleChange}
                  required
                  value={answers.telephone ? answers.telephone : ""}
                  autoComplete="tel"
                />

                {/* <label htmlFor="email">Email:</label> */}
                <input
                  id="email"
                  className="form-control"
                  placeholder='Email'
                  type="email"
                  name="email"
                  onChange={handleChange}
                  required
                  value={answers.email ? answers.email : ""}
                  autoComplete="email"
                />

                <input
                  id="discountCode"
                  className="form-control"
                  placeholder='Discount Code'
                  type="string"
                  name="discountCode"
                  onChange={handleChange}
                  value={answers.discountCode ? answers.discountCode : ""}
                  autoComplete="off"
                />

                {adminLoggedIn && (
                  <input
                    id="discount"
                    className="form-control"
                    placeholder='Discount'
                    type="number"
                    name="discount"
                    onChange={handleChange}
                    value={answers.discount ? answers.discount : ""}
                    autoComplete="off"
                  />
                )}

                <label className="consent-checkbox pt-0 pb-4">
                  <input
                    id="consent-checkbox"
                    type="checkbox"
                    checked={isConsentGiven}
                    onChange={(e) => setConsent(e.target.checked)}
                    required
                  />
                  <span className="consent-checkbox-text">I have read and agree to the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></span>
                </label>

                <button className="btn c-submit-btn" type="submit" disabled={isLoading}>
                  Submit
                </button>
                {isLoading &&
                  <div className="loader-container">
                    <RotatingLines
                      className="submit-loader"
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={true}
                    />
                  </div>}

              </form>
            </div>
          </div>
        )}
      </div>
       {error && <p style={{ color: 'red' }}>{error}</p>}
       {step > 1 && (<BackArrow className="back-arrow" onClick={handlePreviousStep}></BackArrow>)}

       <Modal
           isOpen={modalIsOpen}
           onRequestClose={() => setModalIsOpen(false)} // Function to close the modal when clicking outside
           contentLabel="Meep"
           style={{
               overlay: {
                   backgroundColor: 'rgba(0, 0, 0, 0.5)'
               }
           }}
         >
         <h2>Thanks</h2>
         <p>Your quote will be emailed to you shortly. Please check your spam folder if you can't see it after a few minutes. If you need to make any changes, simply use the 'back' button and submit a new quote request.</p>
         <button className='btn c-modal-btn' onClick={handleClose}>
           Close
         </button>
     </Modal>
     <ErrorModal isOpen={isErrorModalOpen} onRequestClose={() => setIsErrorModalOpen(false)}/>
    </div>
  );
};

export default InstantQuoteTool;
