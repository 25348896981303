import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../GlobalContext/GlobalContext';

import './OfflineWrapper.css';

// Custom hook for getting the current path
const useCurrentPath = () => {
  const location = useLocation();
  return location.pathname;
};

// OfflineWrapper component
const OfflineWrapper = ({ excludedPaths, children }) => {
  const { isOnline } = useContext(GlobalContext);
  const currentPath = useCurrentPath();
  const isExcluded = excludedPaths.includes(currentPath);
  const offlineMessage = isExcluded
    ? "You're currently offline. Some features may be unavailable!"
    : "You're currently offline. Please check your connection.";

  return (
    <div className="offline-wrapper" style={{ position: 'relative' }}>
      {children}
      {!isOnline && (
        <div className={isExcluded ? "offline-interactive-overlay" : "offline-overlay"}>
          <p className="offline-message">
            {offlineMessage}
          </p>
        </div>
      )}
    </div>
  );
};

export default OfflineWrapper;
