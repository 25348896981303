import React, { useState, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import menuImg from '../../images/menu-right.svg'
import closeImg from '../../images/close.svg'
import './Navbar.css';
import { useNavigate} from 'react-router-dom';
import { firebaseAuth} from 'config/firebaseConfig';
import { FaSignOutAlt } from 'react-icons/fa';

const Navbar = ({ isAdmin, logoImg, logoIconImg }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [clickedLink, setClickedLink] = useState("/");
  const toggleOpen = () => setIsOpen(!isOpen);
  const mainMenu = useRef(null);
  const navigate = useNavigate();

  let location = useLocation();

  useEffect(() => {
    const img = new Image();
    img.src = logoImg;
    const img2 = new Image();
    img2.src = logoIconImg;
  }, []);

  const handleLinkClick = (path) => {
    if(isOpen) {
      toggleOpen();
    }
    setClickedLink(path);
  };

  useEffect(() => {
    if (!isOpen) {
      setActiveLink(clickedLink);
    }
  }, [isOpen, clickedLink]);

  const signOut = async () => {
    try {
      await firebaseAuth.signOut();
      navigate('/'); // Navigate to the login page after successful sign-out
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const normalLinks = [
    { path: "/", name: "Home" },
    { path: "/services", name: "Services" },
    { path: "/about-us", name: "About" },
    { path: "/contact-us", name: "Contact" },
  ];

  const adminLinks = [
    { path: "/admin-quotes", name: "Quotes" },
    { path: "/admin-jobs", name: "Jobs" },
    { path: "/admin-engineers", name: "Engineers" },
    { path: "/admin-settings", name: "Settings" },
  ];

  const links = isAdmin ? adminLinks : normalLinks;

  const handleHambarger = ()=> {
    mainMenu.current.classList.add('show');
  }
  const handleMenuClose = ()=> {
    mainMenu.current.classList.remove('show');
  }

  const [isScrolled, setIsScrolled] = useState(false);

  const prevScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const lowerThreshold = 200;
      const upperThreshold = 210;

      if (currentScrollY > upperThreshold && !isScrolled) {
        setIsScrolled(true);
      } else if (currentScrollY < lowerThreshold && isScrolled) {
        setIsScrolled(false);
      }

      prevScrollY.current = currentScrollY;
    };

    // Add the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]); // isScrolled is still necessary in the dependency array


  return (
    <>
    <header className={`c-main-header ${location.pathname !== '/' ? 'inner-header':''} ${isScrolled ? 'fixed' : ''}`}>
    <div className="container">
      {
        isAdmin &&
          <a href="" className="sign-out-button" onClick={signOut}><FaSignOutAlt/></a>
      }
      <nav className="navbar navbar-expand-lg">
        <div className="c-logo-wpr">
        <Link to="/" className="navbar-brand" onClick={() => handleLinkClick("/")} title="Go to Home Page"><img src={logoImg} alt="Boiler Right Logo"/></Link>
        </div>
        <div className="c-nav-right">
          <div className="c-nav-top">
            <button className="c-manu-btn" type="button" onClick={handleHambarger} aria-label="Menu Button"><img src={menuImg} alt="Hamburger Menu Icon"/></button>
            <div className="offcanvas offcanvas-end c-nav-wpr" tabIndex="-1" id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel" ref={mainMenu}>

              <div className="offcanvas-body c-nav-wpr-body" onClick={handleMenuClose}>
                <button type="button" className="c-btn-close"><img
                    src={closeImg} alt=""/></button>
                <ul className="navbar-nav">
                  {links.map(link => (
                    <li key={link.path}><NavLink
                    to={link.path}
                    onClick={() => handleLinkClick(link.path)}
                    className={({ isActive }) => isActive ? 'active' : ''}
                    >
                    {link.name}
                    </NavLink></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
            {
              !isAdmin &&
              <div className="c-header-phone">
                <a href="tel:01724656427" aria-label="Call us at 01724 656427"><i className="fa-solid fa-phone"></i> <span>01724 656427</span></a>
              </div>
            }
        </div>
      </nav>
    </div>
  </header>
    </>
  );
};

export default Navbar;
