import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import aboutImg from '../../images/tradesmen-by-their-van.jpg'
import './AboutUs.css';

function AboutUs() {
  return (
        <div>
            <Helmet>
              <title>About - BoilerRight</title>
              <link rel="canonical" href="https://www.boiler-right.co.uk/about-us" />
              <meta name="description" content="Learn more about BoilerRight, Scunthorpe's trusted boiler replacement team. We stand apart with our unwavering commitment to customer satisfaction." />
              {process.env.REACT_APP_FIREBASE_ENV === "development" && <meta name="robots" content="noindex, nofollow" />}
            </Helmet>
            <div className='c-primary-page-sec'>
                <h1 className='c-page-title' data-aos="fade-up">About <span>Us</span></h1>
                <div className='container about-cont'>
                    <div className='row'>
                        <div className='col-lg-4 mb-lg-0 text-center text-lg-start' data-aos="fade-right">
                            <img className="about-us-image" src={aboutImg} alt="BoilerRight About section image of two boiler replacement engineers standing next to their van"/>
                        </div>
                        <div className='col-lg-8' data-aos="fade-left">
                            <p>BoilerRight is not just another boiler replacement company; we are a dedicated team of professionals who truly care about the needs and comfort of our customers. Based in Scunthorpe, we serve the North Lincolnshire area exclusively and are committed to providing a hassle-free, efficient and affordable service.</p>
                            <p>We pride ourselves in collaborating with a team of highly skilled engineers who carry out the majority of our installations. These seasoned installers bring extensive, hands-on experience to every job they undertake and are passionate about the work they do. Furthermore, every installation is subject to rigorous quality checks to ensure it meets our standards.</p>
                            <p>Customer satisfaction is our top priority, and this extends far beyond the installation itself. By concentrating our efforts in the North Lincolnshire area, we can ensure that our customers receive personalised attention and a timely service. From initial visit to final checks, and every step in between, we are there to guide and assist you, addressing any concerns and answering all your questions.</p>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default AboutUs;
