import React from 'react';
import { Helmet } from 'react-helmet';

import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div>
    <Helmet>
      <title>Privacy Policy - BoilerRight</title>
      <link rel="canonical" href="https://www.boiler-right.co.uk/privacy-policy" />
      <meta name="description" content="BoilerRight's Privacy Policy outlines our commitment to safeguarding your personal data. Discover how we collect, use, and protect the information you provide us." />
      {process.env.REACT_APP_FIREBASE_ENV === "development" && <meta name="robots" content="noindex, nofollow" />}
    </Helmet>
    <div className='c-primary-page-sec'>
          <h1 className='c-page-title'>Privacy <span>Policy</span></h1>
          <div className='container'>
          <p>This is the privacy policy of BoilerRight. (‘we’, ‘our’, or ‘us’). You can contact us at contact@boiler-right.co.uk</p>

          <h2>Introduction</h2>
          <p>This policy describes how we collect, store, transfer and use personal data. It tells you about your privacy rights and how the law protects you.</p>
          <p>In the context of the law and this policy, ‘personal data’ is information that clearly identifies you as an individual or which could be used to identify you if combined with other information. Acting in any way on personal data is referred to as ‘processing’.</p>
          <p>Except as set out below, we do not share, or sell, or disclose to a third party, any information collected through our website.</p>

          <h2>Personal data we process</h2>

          <h3>1. How we obtain personal data</h3>
          <p>The information we process about you includes information:</p>
          <ul>
            <li>you have directly provided to us</li>
            <li>as a result of monitoring how you use our website or our services</li>
          </ul>
          <h3>2. Types of personal data we collect directly</h3>
            <p>When you use our website or our services, we ask you to provide personal data. This can be categorised into the following groups:</p>
            <ul>
                <li>personal identifiers, such as your first and last names, your title and your date of birth</li>
                <li>contact information, such as your email address, your telephone number and your postal addresses for billing, delivery and communication</li>
                <li>records of communication between us including messages sent through our website, email messages and telephone conversations</li>
                <li>marketing preferences that tell us what types of marketing you would like to receive</li>
            </ul>

            <h3>3. Types of personal data we collect from your use of our services</h3>
            <p>By using our website and our services, we process:</p>
            <ul>
                <li>technical information about the hardware and the software you use to access our website and use our services, including your Internet Protocol (IP) address, your browser type and version and your device’s operating system</li>
                <li>usage information, including the frequency you use our services, the pages of our website that you visit, whether you receive messages from us and whether you reply to those messages</li>
                <li>transaction information that includes the details of the products services you have bought from us and payments made to us for those services</li>
                <li>your preferences to receive marketing from us; how you wish to communicate with us; and responses and actions in relation to your use of our services.</li>
            </ul>

            <h3>4. Our use of aggregated information</h3>
            <p>We may aggregate anonymous information such as statistical or demographic data for any purpose. Anonymous information is that which does not identify you as an individual. Aggregated information may be derived from your personal data but is not considered as such in law because it does not reveal your identity.</p>
            <p>For example, we may aggregate usage information to assess whether a feature of our website is useful.</p>
            <p>However, if we combine or connect aggregated information with your personal data so that it can identify you in any way, we treat the combined information as personal data, and it will be used in accordance with this privacy policy.</p>

            <h3>5. Special personal data</h3>
            <p>Special personal data is data about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data. It also includes information about criminal convictions and offences.</p>
            <p>We do not collect any special personal data about you.</p>

            <h2>The bases on which we process information about you</h2>
            <p>The law requires us to determine under which of six defined bases we process different categories of your personal data, and to notify you of the basis for each category.</p>
            <p>If a basis on which we process your personal data is no longer relevant then we shall immediately stop processing your data.</p>
            <p>If the basis changes then if required by law we shall notify you of the change and of any new basis under which we have determined that we can continue to process your information.</p>

            <h3>6. Information we process because we have a contractual obligation with you</h3>
            <p>When you buy a product or service from us, or otherwise agree to our terms and conditions, a contract is formed between you and us.</p>
            <p>In order to carry out our obligations under that contract we must process the information you give us. Some of this information may be personal data.</p>
            <p>We may use it in order to:</p>
            <ul>
                <li>verify your identity for security purposes when you use our services</li>
                <li>sell products to you</li>
                <li>provide you with our services</li>
                <li>provide you with suggestions and advice on products, services and how to obtain the most from using our website</li>
            </ul>
            <p>We process this information on the basis there is a contract between us, or that you have requested we use the information before we enter into a legal contract.</p>
            <p>We shall continue to process this information until the contract between us ends or is terminated by either party under the terms of the contract.</p>

            <h3>7. Information we process with your consent</h3>
            <p>Through certain actions when otherwise there is no contractual relationship between us, such as when you browse our website or ask us to provide you more information about our business, including our products and services, you provide your consent to us to process information that may be personal data.</p>
            <p>Wherever possible, we aim to obtain your explicit consent to process this information, for example, we ask you to agree to our use of non-essential cookies when you access our website.</p>
            <p>We continue to process your information on this basis until you withdraw your consent or it can be reasonably assumed that your consent no longer exists.</p>
            <p>You may withdraw your consent at any time by instructing us at contact@boiler-right.co.uk.</p>

            <h2>How and when we process your personal data</h2>

            <h3>8. Payment information</h3>
            <p>While we may collect payment information from you over the phone for the purpose of processing transactions, this information is never stored or recorded by us. Our employees and contractors do not have access to your payment details, whether obtained through our website, over the phone, or via any other method.</p>
            <p>During the payment process, you may be transferred to a secure page on the website of a reputable payment service provider, such as Stripe. This page may appear to be branded to look like a page on our website, but it is not under our control. We adhere strictly to this practice to ensure the security of your payment information.</p>

            <h3>9. Information obtained from third parties</h3>
            <p>Although we do not disclose your personal data to any third party (except as set out in this policy), we sometimes receive data that is indirectly made up from your personal data from third parties whose services we use.</p>

            <h3>10. Service providers and business partners</h3>
            <p>We may share your personal data with businesses that provide services to us, or with business partners.</p>
            <p>As examples:</p>
            <ul>
                <li>we may pass your payment information to our payment service provider to take payments from you</li>
                <li>we may pass your contact information to advertising agencies to use to promote our services to you</li>
            </ul>

            <h2>Use of information we collect through automated systems</h2>

            <h3>11. Cookies</h3>
            <p>Cookies are small text files that are placed on your computer's hard drive by your web browser when you visit a website that uses them. They allow information gathered on one web page to be stored until it is needed for use at a later date.</p>
            <p>They are commonly used to provide you with a personalised experience while you browse a website, for example, allowing your preferences to be remembered.</p>
            <p>They can also provide core functionality such as security, network management, and accessibility; record how you interact with the website so that the owner can understand how to improve the experience of other visitors; and serve you advertisements that are relevant to your browsing history.</p>
            <p>Some cookies may last for a defined period of time, such as one visit (known as a session), one day or until you close your browser. Others last indefinitely until you delete them.</p>
            <p>Your web browser should allow you to delete any cookie you choose. It should also allow you to prevent or limit their use. Your web browser may support a plug-in or add-on that helps you manage which cookies you wish to allow to operate.</p>
            <p>The law requires you to give explicit consent for use of any cookies that are not strictly necessary for the operation of a website.</p>
            <p>When you first visit our website, we ask you whether you wish us to use cookies. If you choose not to accept them, we shall not use them for your visit except to record that you have not consented to their use for any other purpose.</p>
            <p>If you choose not to use cookies or you prevent their use through your browser settings, you may not be able to use all the functionality of our website.</p>
            <p>We use cookies in the following ways:</p>
            <ul>
                <li>to track how you use our website</li>
                <li>to record whether you have seen specific messages we display on our website</li>
                <li>to record your answers to surveys and questionnaires on our site while you complete them</li>
                <li>to record the conversation thread during a live chat with our support team</li>
            </ul>

            <h3>12. Personal identifiers from your browsing activity</h3>
            <p>Requests by your web browser to our servers for web pages and other content on our website are recorded.</p>
            <p>We record information such as your geographical location, your Internet service provider and your IP address. We also record information about the software you are using to browse our website, such as the type of computer or device and the screen resolution.</p>
            <p>We use this information in aggregate to assess the popularity of the webpages on our website and how we perform in providing content to you.</p>
            <p>If combined with other information we know about you from previous visits, the data possibly could be used to identify you personally.</p>

            <h2>Use of information we collect through automated systems</h2>
            <h3>13. Re-marketing</h3>
            <p>Re-marketing involves placing a ‘tracking technology’ such as a cookie, a ‘web beacon’ (also known as an ‘action tag’ or a ‘single-pixel GIF’) to track which pages you visit and to serve you relevant adverts for our services when you visit some other website.</p>
            <p>The benefit of re-marketing technology is that we can provide you with more useful and relevant adverts, and not show you ones repeatedly that you may have already seen.</p>
            <p>We may use a third-party advertising service to provide us with re-marketing services from time to time. If you have consented to our use of such tracking technologies, you may see advertisements for our products and services on other websites.</p>
            <p>We do not provide your personal data to advertisers or to third-party re-marketing service providers. However, if you are already a member of a website whose affiliated business provides such services, that affiliated business may learn of your preferences in relation to your use of our website.</p>

            <h2>Other matters</h2>
            <h3>14. Your rights</h3>
            <p>The law requires us to tell you about your rights and our obligations to you in regard to the processing and control of your personal data.</p>
            <p>We do this now, by requesting that you read the information provided at <a href="http://www.knowyourprivacyrights.org">www.knowyourprivacyrights.org</a></p>

            <h3>15. Use of our services by children</h3>
            <p>We do not sell products or provide services for purchase by children, nor do we market to children.</p>
            <p>If you are under 18, you may use our website only with consent from a parent or guardian.</p>

            <h3>16. Encryption of data sent between us</h3>
            <p>We use Secure Sockets Layer (SSL) certificates to verify our identity to your browser and to encrypt any data you give us.</p>
            <p>Whenever information is transferred between us, you can check that it is done so using SSL by looking for a closed padlock symbol or other trust mark in your browser’s URL bar or toolbar.</p>

            <h3>17. Data may be processed outside the UK</h3>
            <p>Our websites are hosted in the UK.</p>
            <p>We may also use outsourced services in countries outside the UK from time to time in other aspects of our business.</p>
            <p>Accordingly data obtained within the UK or any other country could be processed outside the UK.</p>
            <p>We use the following safeguards with respect to data transferred outside the UK:</p>
            <ul>
                <li>the data protection clauses in our contracts with data processors include transfer clauses written by or approved by a supervisory authority.</li>
                <li>we comply with a code of conduct approved by a supervisory authority.</li>
            </ul>

            <h3>18. Control over your own information</h3>
            <p>At any time, you may contact us to request that we provide you with the personal data we hold about you.</p>

            <h3>19. Communicating with us</h3>
            <p>When you contact us, whether by telephone, through our website or by email, we collect the data you have given to us in order to reply with the information you need.</p>
            <p>We record your request and our reply in order to increase the efficiency of our business.</p>
            <p>We may keep personally identifiable information associated with your message, such as your name and email address so as to be able to track our communications with you to provide a high quality service.</p>

            <h3>20. Complaining</h3>
            <p>If you are in any way dissatisfied about how we process your personal data, you have a right to lodge a complaint with the Information Commissioner's Office (ICO). This can be done at <a href="https://ico.org.uk/make-a-complaint/">https://ico.org.uk/make-a-complaint/</a>. We would, however, appreciate the opportunity to talk to you about your concern before you approach the ICO.</p>

            <h3>21. Retention period</h3>
            <p>Except as otherwise mentioned in this privacy policy, we keep your personal data only for as long as required by us:</p>
            <ul>
                <li>to provide you with the services you have requested</li>
            </ul>

            <h3>22. Compliance with the law</h3>
            <p>Our privacy policy complies with the law in the United Kingdom, specifically with the Data Protection Act 2018 (the ‘Act’) accordingly incorporating the EU General Data Protection Regulation (‘GDPR’) and the Privacy and Electronic Communications Regulations (‘PECR’).</p>

            <h3>23. Review of this privacy policy</h3>
            <p>We shall update this privacy policy from time to time as necessary.</p>
          </div>
    </div>
    </div>
  );
}

export default PrivacyPolicy;
