import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Link } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { RotatingLines } from 'react-loader-spinner'
import { Helmet } from 'react-helmet';
import { logEvent } from "firebase/analytics";
import AOS from 'aos';

import './App.css';
import OfflineWrapper from 'components/OfflineWrapper/OfflineWrapper';
import ServiceDownWrapper from 'components/ServiceDownWrapper/ServiceDownWrapper';
import 'aos/dist/aos.css';
import 'config/firebaseConfig';
import { analytics } from 'config/firebaseConfig';
import Navbar from 'components/Navbar/Navbar';
import Home from 'views/Home/Home';
import Services from 'views/Services/Services';
import AboutUs from 'views/AboutUs/AboutUs';
import ContactUs from 'views/ContactUs/ContactUs';
import Blank from 'views/Blank/Blank';
import AddressCheck from 'views/AddressCheck/AddressCheck';
import InstantQuote from 'views/InstantQuote/InstantQuote';
import Admin from 'views/Admin/Admin';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import AdminQuotes from 'views/Admin/AdminQuotes';
import AdminJobs from 'views/Admin/AdminJobs';
import AdminSettings from 'views/Admin/AdminSettings';
import AdminEngineers from 'views/Admin/AdminEngineers';
import AcceptQuote from 'views/AcceptQuote/AcceptQuote';
import PrivacyPolicy from 'views/PrivacyPolicy/PrivacyPolicy';
import RequestCallback from 'views/RequestCallback/RequestCallback';
import JobCompletion from 'views/JobCompletion/JobCompletion';
import InitialVisit from 'views/InitialVisit/InitialVisit';
import AcceptCookies from 'components/AcceptCookies/AcceptCookies';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import ErrorPageNotFound from 'components/ErrorPageNotFound/ErrorPageNotFound';
import { GlobalProvider } from './GlobalContext/GlobalContext';
import logoImg from './images/logo.svg';
import phoneIcon from './images/phone-icon.png';
import bannerBg from './images/banner-bg.jpg';
import heroImg from './images/pro-1.png';
import shape from './images/SHAPE.png';
import icon1 from './images/icon-1.png';
import icon2 from './images/icon-2.png';
import icon3 from './images/icon-3.png';
import icon4 from './images/icon-4.png';
import icon5 from './images/icon-5.png';
import icon6 from './images/icon-6.png';
import icon7 from './images/icon-7.png';
import icon8 from './images/icon-8.png';
import mailIcon from './images/mail-icon.png';
import shareIcon from './images/share-icon.png';
import faqImg from './images/img-1.jpg';
import tradesmenByTheirVanImg from './images/tradesmen-by-their-van.jpg';
import contactImg from './images/contact-img.jpg';
import footerLogo from './images/logo-bw.svg';

import PartnersCarousel from 'components/PartnersCarousel/PartnersCarousel';
import AccordionItem from 'components/FAQItem/AccordionItem';


//Sends current page info to GA, necessary due to SPA architecture.
function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_path: location.pathname + location.search,
      page_title: document.title,
      page_location: window.location.href
    });
  }, [location]);

  return null;
}

function App() {
  const [opacity, setOpacity] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [interactiveOfflinePages, setInteractiveOfflinePages] = useState(['/initial-visit', '/job-completion']);

  useEffect(() => {
    let loadedImages = 0;
    const images = [logoImg, phoneIcon, bannerBg, heroImg, shape, icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8, mailIcon, shareIcon, faqImg, tradesmenByTheirVanImg, contactImg];

    images.forEach(image => {
      const img = new window.Image();
      img.src = image;
      img.onload = () => {
        loadedImages++;
        if (loadedImages === images.length) {
          setTimeout(() => {
            setImagesLoaded(true);
            setOpacity(1);
            AOS.init({
              duration :1000,
              once: true,
            });
        }, 200);
        }
      };
    });
  }, []);

  function ConditionalNavbar() {
    const location = useLocation();

    // Don't render the Navbar on /Admin and /AdminPage routes
    if (location.pathname.toLowerCase() === '/admin') {
      return <Navbar logoImg={logoImg} />;
    } else if ((location.pathname.toLowerCase().includes('admin'))) {
      return <Navbar logoImg={logoImg} isAdmin={true}/>;
    }

    return <Navbar logoImg={logoImg} />;
  }

  const AnimatedRoutes = () => {
    const location = useLocation();

    return (
      <TransitionGroup>
        <CSSTransition key={location.key} timeout={500} classNames="page">
          <Routes location={location}>
            <Route index element={<Home homeImg={heroImg} />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blank" element={<Blank />} />
            <Route path="/address-check" element={<AddressCheck />} />
            <Route path="/instant-quote" element={<InstantQuote />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/accept-quote" element={<AcceptQuote />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/request-callback" element={<RequestCallback />} />
            <Route path="/job-completion" element={<JobCompletion />} />
            <Route path="/initial-visit" element={<InitialVisit />} />
            <Route path="/admin-quotes" element={<PrivateRoute />} >
              <Route index element={<AdminQuotes />} />
            </Route>
            <Route path="/admin-jobs" element={<PrivateRoute />} >
              <Route index element={<AdminJobs />} />
            </Route>
            <Route path="/admin-engineers" element={<PrivateRoute />} >
              <Route index element={<AdminEngineers />} />
            </Route>
            <Route path="/admin-settings" element={<PrivateRoute />} >
              <Route index element={<AdminSettings />} />
            </Route>
            <Route path="*" element={<ErrorPageNotFound />} /> {/* This will be displayed for non-existing routes */}
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    );
  };

  const ConditionalFaq = ()=> {
    const location = useLocation();

    const [items, setItems] = useState([
      { title: "How long does a boiler replacement take?", content: "A typical boiler replacement will normally be completed in a day. If we anticipate that the job will take longer than this, we will let you know ahead of time.", isOpen: false },
      { title: "What's included in the quoted price?", content: "Your quote includes material and labour costs. All our boilers are installed with a new magnetic filter and scale inhibitor for optimal protection. We also provide a system flush in line with the manufacturer's requirements. However, please note that the quoted price is subject to a site survey and may not include certain costs, such as upgrading a gas pipe where this may be required. If this is the case, we will advise you during our initial visit and issue a separate quote.", isOpen: false },
      { title: "When do I need to pay for the installation?", content: "We don't currently request payment until after the installation is complete and you are fully satisfied with the work done.", isOpen: false },
      { title: "Do I need to do anything before the installation day?", content: "You don't have to do anything before the installation day. However, please be aware that your water supply may need to be turned off for a while. If you have any concerns about this, please don't hesitate to mention them to us. We can always make special arrangements to help minimise the disruption.", isOpen: false },
      { title: "Can you upgrade my older system to a combi boiler?", content: "Although we specialise in boiler replacements, we can also often provide other boiler installation services such as upgrading an older system to a combi boiler or moving an existing boiler to a new location. If you are interested in this, please contact us directly.", isOpen: false },
      { title: "How do I cancel my order?", content: "If you change your mind and no longer want to use our service, simply contact us with your details and we will cancel the order for you. This will incur a £50 administration fee if we have already conducted our initial visit and ordered the new boiler and components, otherwise there is no charge.", isOpen: false },
    ]);

    const toggleItem = (index) => {
      const newItems = items.map((item, i) => ({
        ...item,
        isOpen: i === index ? !item.isOpen : false,
      }));
      setItems(newItems);
    };

    if (location.pathname.toLowerCase().includes('admin') || location.pathname === '/privacy-policy' || location.pathname === '/job-completion' || location.pathname === '/initial-visit') {
      return null
    } else {
      return <section className="c-faq-sec" data-aos="fade-in" data-aos-offset="-50">
        <div className="container">
          <div className="c-faq-wpr">
              <h2>Frequently Asked Questions</h2>
              <div className="c-faq-list">
              {items.map((item, index) => (
                  <AccordionItem
                  key={index}
                  title={item.title}
                  content={item.content}
                  isOpen={item.isOpen}
                  onClick={() => toggleItem(index)}
                  />
              ))}
              </div>
          </div>
        </div>
        <div className="c-faq-img"><img src={faqImg} alt=""/></div>
    </section>
    }
  }

  if (!imagesLoaded) {
    return (
      <div className="main-app-loader-container">
        <RotatingLines
          className="main-app-loader"
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
    </div>
    );
  }

  return (
    <Router>
      <GlobalProvider>
        <OfflineWrapper excludedPaths={interactiveOfflinePages}>
          <ServiceDownWrapper>
            <PageTracker />
            <ScrollToTop />
            <div className="App" style={{ opacity: opacity, transition: 'opacity 1s ease-in' }}>
              <ConditionalNavbar />
              <div className="main-content">
                <AnimatedRoutes />
              </div>
             <ConditionalFaq/>
            <footer className='o-footer w-100'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-3 mb-4 mb-lg-0'>
                    <img className="logo-footer" alt='footer-logo' src={footerLogo} />
                  </div>
                  <div className='col-lg-8 c-site-info'>
                    <div className='row'>
                      <div className='col-md-4 mb-4 mb-md-0'>
                        <h4><img src={phoneIcon} alt='icon' /> Call Us</h4>
                        <a href='tel:01724 656427'>01724 656427</a>
                      </div>
                      <div className='col-md-4 mb-4 mb-md-0'>
                        <h4><img src={mailIcon} alt='icon' /> Mail Us</h4>
                        <a href='mailto:contact@boiler-right.co.uk'>contact@boiler-right.co.uk</a>
                      </div>
                      <div className='col-md-4'>
                        <h4><img src={shareIcon} alt='icon' />Follow Us</h4>
                        <ul className='c-social-icon'>
                          <li><a href='https://www.facebook.com/boiler.right/' target="_blank" aria-label="Link to Facebook Button"><i className="fa-brands fa-facebook-f"></i></a></li>
                          <li><a href='https://www.instagram.com/boiler.right/' target="_blank" aria-label="Link to Instagram Button"><i className="fa-brands fa-instagram"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='o-footer-nav-area'>
                  <div className='row'>
                    <div className='col-lg-8 offset-lg-3'>
                      <div className='row'>
                        <div className='col-lg-4 mb-4'>
                          <nav className='c-nav-sub'>
                            <ul>
                              <li><Link to="/">Home</Link></li>
                              <li><Link to="/services">Services</Link></li>
                              <li><Link to="/about-us">About</Link></li>
                              <li><Link to="/contact-us">Contact</Link></li>
                            </ul>
                          </nav>
                        </div>
                        <div className='col-lg-4 mb-4'>
                          <nav className='c-nav-sub'>
                            <ul>
                              <li><Link to='/address-check'>Instant Quote</Link></li>
                              <li><Link to='/request-callback'>Request Call Back</Link></li>
                            </ul>
                          </nav>
                        </div>
                        <div className='col-lg-4 mb-4'>
                          <nav className='c-nav-sub'>
                            <ul>
                              <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                            </ul>
                          </nav>
                        </div>
                        <div className='col-lg-4'>
                          <p>2023 © All Rights Reserved.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <AcceptCookies/>
            </footer>

            </div>
          </ServiceDownWrapper>
        </OfflineWrapper>
      </GlobalProvider>
    </Router>
  );
}

export default App;
