import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import serviceImg from '../../images/female-plumber-working.jpg'
import './Services.css';

function Services({femalePlumberWorkingImg}) {
  return (
    <div>
      <Helmet>
        <title>Services - BoilerRight</title>
        <link rel="canonical" href="https://www.boiler-right.co.uk/services" />
        <meta name="description" content="BoilerRight offers an unparalleled boiler replacement service in the Scunthorpe area. Our customer-focused approach covers everything from an initial visit to post-installation support. As a local company, we prioritize personalized, exceptional service every step of the way." />
        {process.env.REACT_APP_FIREBASE_ENV === "development" && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>
      <div className='c-primary-page-sec'>
          <h1 className='c-page-title' data-aos="fade-up">Our <span>Services</span></h1>
          <div className='container services-cont'>
              <div className='row'>
                  <div className='col-lg-6 mb-4 mb-lg-0 text-center text-lg-start' data-aos="fade-right">
                      <img className="services-image" src={serviceImg} alt="BoilerRight services section image of a female engineer holding a notepad."/>
                  </div>
                  <div className='col-lg-6' data-aos="fade-left">
                      <p>At BoilerRight, our focus is on delivering a top-tier, customer-focused boiler replacement service. Our team of Gas Safe-certified engineers is committed to providing a professional service that not only meets but exceeds your expectations. </p>
                      <p>We understand the importance of a reliable heating system and therefore ensure that the installation of your new boiler is carried out with the attention and care it deserves. All our installations will also, of course, adhere to all relevant safety regulations and standards. Our prices are highly competitive and, by offering a wide range of boilers and accessories, we can guarantee that our service will fit comfortably within your budget.</p>
                  </div>
                  <div>
                    <h2>Our Boiler Replacement Process</h2>
                    <div className='mb-4 mb-md-5'>
                      <h3><i className="fas fa-chevron-right"></i>Initial Visit</h3>
                      <p>After accepting our quote, we'll reach out to you to arrange a brief initial visit. This allows us to confirm all the provided details and perform some additional checks to ensure your new installation goes smoothly. We always try to book this visit to fit around your schedule.</p>
                    </div>
                    <div className='mb-4 mb-md-5'>
                      <h3><i className="fas fa-chevron-right"></i>Installation Confirmation</h3>
                      <p>Following the initial visit, we'll send you a confirmation message with the installation date and details. And if your boiler has broken down, we will try to schedule a next-day installation to get you back up and running in no time.</p>
                    </div>

                    <div className='mb-4 mb-md-5'>
                      <h3><i className="fas fa-chevron-right"></i>Installation Day</h3>
                      <p>Our certified Gas Safe engineers will install your new boiler professionally and safely, following all regulations and industry standards. After the installation, they will take the time to guide you through the operation of your new boiler and answer any questions you may have.</p>
                    </div>

                    <div className='mb-4 mb-md-5'>
                      <h3><i className="fas fa-chevron-right"></i>Post-Installation Support</h3>
                      <p>We'll provide guidance to help you understand how to use your new system efficiently and how the guarantee on your boiler works. And if you require further assistance, we'll be happy to help. <strong>As a local company, delivering exceptional and personal service is our top priority.</strong></p>
                    </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}

export default Services;
