import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';

import checkPostcodeDistance from 'utils/PostcodeChecker/PostcodeChecker';
import './AddressCheck.css';
import useGooglePlaceAutoComplete from "components/AutoCompleteWidget/AutoCompleteWidget";
import ErrorModal from 'components/Modals/ErrorModal/ErrorModal'
import QuoteToolDownWrapper from 'components/QuoteToolDownWrapper/QuoteToolDownWrapper'

function App() {
  const address1 = useRef();
  const googleAutoCompleteSvc = useGooglePlaceAutoComplete();
  const [addressFirstLine, setAddressFirstLine] = useState('');
  const [addressTown, setAddressTown] = useState('');
  const [addressCountry, setAddressCountry] = useState('');
  const [addressPostcode, setAddressPostcode] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [addressNotServedModalOpen, setAddressNotServedModalOpen] = useState(false);
  const [addressErrorModalOpen, setAddressErrorModalOpen] = useState(false);
  const { handleSubmit, setFocus, formState: { errors } } = useForm({});

  const handlePostcodeCheck = (data) => {
    if (data === true) {
      const address = {
        firstLine: addressFirstLine,
        town: addressTown,
        country: addressCountry,
        postcode: addressPostcode
      }
      navigate('/instant-quote', { state: { address } });
    } else if (data === false) {
      setAddressNotServedModalOpen(true);
    } else {
      setAddressErrorModalOpen(true);
    }
  }

  const onSubmit = (data) => {
    const inputPostcode = addressPostcode;
    checkPostcodeDistance(inputPostcode, handlePostcodeCheck);
  };

  let autoComplete = "";

  const handleAddressSelect = async () => {
    let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);
    address1.current.value = addressObj.address1;
    setAddressFirstLine(addressObj.address1);
    setAddressTown(addressObj.postalTown);
    setAddressCountry(addressObj.countryLong);
    setAddressPostcode(addressObj.postalCode);
    setFocus("address1");
  };

  useEffect(() => {
    async function loadGoogleMaps() {
        // initialize the Google Place Autocomplete widget and bind it to an input element.
        // eslint-disable-next-line
        autoComplete = await googleAutoCompleteSvc.initAutoComplete(address1.current, handleAddressSelect);
    }
    loadGoogleMaps();

    address1.current = document.getElementById('address1');
  }, []);

  return (
    <div >
      <QuoteToolDownWrapper>
          <Helmet>
            <title>Address Check - BoilerRight</title>
            <link rel="canonical" href="https://www.boiler-right.co.uk/address-check" />
            <meta name="robots" content="noindex"/>
            {process.env.REACT_APP_FIREBASE_ENV === "development" && <meta name="robots" content="noindex, nofollow" />}
          </Helmet>

          <div className='c-primary-page-sec'>
              <h1 className='c-page-title' data-aos="fade-up">Let's Check if we Serve <span>your Area</span></h1>
              <div className='container'>
                <div className="row">
                  <div className="col-md-6 offset-md-3" data-aos="fade-left">
                  <p className="address-check-intro-text">We're based in Scunthorpe and serve the North Lincolnshire area exclusively. This allows us to provide a more reliable and personal service.</p>
                  <div className="contact-form">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-field-container">
                        <input
                            id="address1"
                            type="text"
                            className="form-control"
                            placeholder="Start typing first line of address..."
                            ref={address1}
                            onChange={e => setAddressFirstLine(e.target.value)}
                            value={addressFirstLine}
                            required
                        />
                    </div>
                    <div className="form-field-container">
                        <input
                            id="city"
                            type="text"
                            className="form-control"
                            placeholder="Town/City"
                            onChange={e => setAddressTown(e.target.value)}
                            value={addressTown}
                            required
                            autoComplete="off"
                        />
                    </div>
                    <div className="form-field-container">
                      <div className="short-form-field-container">
                          <div>
                              <input
                                  id="country"
                                  type="text"
                                  className="form-control"
                                  placeholder="Country"
                                  onChange={e => setAddressCountry(e.target.value)}
                                  value={addressCountry}
                                  required
                                  autoComplete="off"
                              />
                          </div>
                          <div>
                              <input
                                  id="postcode"
                                  type="text"
                                  placeholder="Postcode"
                                  className="form-control"
                                  onChange={e => setAddressPostcode(e.target.value)}
                                  value={addressPostcode}
                                  required
                                  autoComplete="off"
                              />
                          </div>
                      </div>
                    </div>
                    <div className="text-center"><button className="btn c-submit-btn" type="submit">Check</button>
                    <p className="message">{message}</p></div>
                  </form>
                </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              isOpen={addressNotServedModalOpen}
              onRequestClose={() => setAddressNotServedModalOpen(false)} // Function to close the modal when clicking outside
              contentLabel="Meep"
              style={{
                  overlay: {
                      backgroundColor: 'rgba(0, 0, 0, 0.5)'
                  }
              }}
            >
              <h2>Sorry</h2>
              <p>We don't currently serve your area. Check back again soon!</p>
              <button className='btn c-modal-btn' onClick={() => setAddressNotServedModalOpen(false)}>
                Close
              </button>
          </Modal>
          <Modal
            isOpen={addressErrorModalOpen}
            onRequestClose={() => setAddressErrorModalOpen(false)} // Function to close the modal when clicking outside
            contentLabel="Meep"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }
            }}
          >
            <h2>Hmm...</h2>
            <p>Looks like there's a problem with the address you entered.</p>
            <button className='btn c-modal-btn' onClick={() => setAddressErrorModalOpen(false)}>
              Close
            </button>
        </Modal>
      </QuoteToolDownWrapper>
    </div>
  );
}

export default App;
