import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../GlobalContext/GlobalContext';

import './ServiceDownWrapper.css';

const useCurrentPath = () => {
  const location = useLocation();
  return location.pathname;
};

const ServiceDownWrapper = ({ children }) => {
  const { isServiceDown } = useContext(GlobalContext);
  const currentPath = useCurrentPath();

  const isAdminPath = currentPath.toLowerCase().includes('admin');

  return (
    <div className="service-down-wrapper" style={{ position: 'relative' }}>
      {children}
      {isServiceDown && !isAdminPath && (
        <div className="service-down-overlay">
          <p className="service-down-message">
            We are currently performing scheduled maintenance on our website. The service will be temporarily unavailable. We apologize for any inconvenience.
          </p>
        </div>
      )}
    </div>
  );
};

export default ServiceDownWrapper;
