import React, { useState } from 'react';
import Modal from 'react-modal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { RotatingLines } from 'react-loader-spinner';
import { Helmet } from 'react-helmet';

import { collection, addDoc } from 'firebase/firestore';
import './RequestCallback.css';
import ErrorModal from 'components/Modals/ErrorModal/ErrorModal';
import contactImg from '../../images/contact-img.jpg'

function RequestCallback() {
  const [name, setName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const functions = getFunctions();
    const addCallbackRequestData = httpsCallable(functions, 'addCallbackRequestData');

    try {
      setIsLoading(true);
      await addCallbackRequestData({ name: name, telephone: telephone });
      setIsLoading(false);
      setModalIsOpen(true);
      setName('');
      setTelephone('');
    } catch (error) {
      console.error('Error submitting enquiry:', error);
      setIsErrorModalOpen(true);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Request Callback - BoilerRight</title>
        <link rel="canonical" href="https://www.boiler-right.co.uk/request-callback" />
        <meta name="description" content="Request a callback from BoilerRight Scunthorpe. Fill out our simple form, and our dedicated team will be in touch promptly to assist you with your boiler needs." />
        {process.env.REACT_APP_FIREBASE_ENV === "development" && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>

      <div className='c-primary-page-sec'>
        <h1 className='c-page-title' data-aos="fade-up">Request a <span>Callback</span></h1>
        <div className='container'>
              <div className='row'>
                  <div className='col-lg-5 rc-img-container' data-aos="fade-right">
                    <img className="callback-img" src={contactImg} alt="BoilerRight Request a Callback section image of a person communicating via a tablet computer"/>
                  </div>
                  <div className='col-lg-7' data-aos="fade-left" data-aos-offset="-100">
                    <p className="rc-centred-info">
                    Please enter your details below and we'll call you right back.
                    </p>
                    <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder='Name'
            className='form-control'
            autoComplete="name"
          />

          <input
            type="number"
            id="telephone"
            name="telephone"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            required
            placeholder='Telephone'
            className='form-control'
            autoComplete="tel"
          />
          <div className="rc-submit-btn-cont">
            <button className="btn c-submit-btn" type="submit" disabled={isLoading}>
              Submit
            </button>
          </div>
          {isLoading &&
            <div className="rc-loader-container">
              <RotatingLines
                className="submit-loader"
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="30"
                visible={true}
              />
            </div>}
          <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setModalIsOpen(false)} // Function to close the modal when clicking outside
              contentLabel="Meep"
              style={{
                  overlay: {
                      backgroundColor: 'rgba(0, 0, 0, 0.5)'
                  }
              }}
            >
            <h2>Thanks</h2>
            <p>We've received your contact details and will get back to you shortly</p>
            <button className='btn c-modal-btn' onClick={() => setModalIsOpen(false)}>
              Close
            </button>
        </Modal>
        <Modal
          isOpen={isErrorModalOpen}
          onRequestClose={() => setIsErrorModalOpen(false)} // Function to close the modal when clicking outside
          contentLabel="Meep"
          style={{
              overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)'
              }
          }}
        >
          <h2>Error</h2>
          <p>Something went wrong. Please try again or contact the site administrator.</p>
          <button className='btn c-modal-btn' onClick={() => setIsErrorModalOpen(false)}>
            Close
          </button>
      </Modal>
        </form>
      </div>
                  </div>
              </div>
        </div>
      </div>


    </div>
  );
}

export default RequestCallback;
