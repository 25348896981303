// Import the functions you need from the SDKs you need
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, doc, getDoc} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

// Firebase configuration

const prodConfig = {
  apiKey: "AIzaSyBP2jJE7-uUX-r3wr-XAoYcnyPWHigsYAk",
  authDomain: "boiler-buddy.firebaseapp.com",
  projectId: "boiler-buddy",
  storageBucket: "boiler-buddy.appspot.com",
  messagingSenderId: "1064896042247",
  appId: "1:1064896042247:web:2c4909950c764b4ca121a2",
  measurementId: "G-LG1L018MW0"
};

const devConfig = {
  apiKey: "AIzaSyBYxfSds_pWMmwhH1quB_hDY34Sh4o4IZ0",
  authDomain: "website-test-70080.firebaseapp.com",
  databaseURL: "https://website-test-70080.firebaseio.com",
  projectId: "website-test-70080",
  storageBucket: "website-test-70080.appspot.com",
  messagingSenderId: "374518369962",
  appId: "1:374518369962:web:7079b63c4ffabfa37a0ad3"
};

const firebaseConfig = process.env.REACT_APP_FIREBASE_ENV === 'production' ? prodConfig : devConfig;

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp)
const analytics = getAnalytics(firebaseApp);

export { db, collection, addDoc, doc, getDoc, getFunctions, httpsCallable, firebaseAuth, functions, analytics};
export default firebaseApp;
