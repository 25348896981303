import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection, doc, deleteDoc} from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { RotatingLines } from 'react-loader-spinner';

import { db } from 'config/firebaseConfig';
import 'App.css';
import './AdminEngineers.css';

const AdminEngineers = () => {
  const navigate = useNavigate();
  const [engineers, setEngineers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState(null);

  //Fetch engineers collection
  useEffect(() => {
    const fetchEngineers = async () => {
      setLoading(true);
      const engineersCollectionRef = collection(db, 'engineers');
      const querySnapshot = await getDocs(engineersCollectionRef);
      const engineersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEngineers(engineersData);
      setLoading(false);
    };

    fetchEngineers().catch(console.error);
  }, []);

  //Sort quotes based on sortConfig if active
  useEffect(() => {
    if (sortConfig !== null) {
      const sortedEngineers = [...engineers].sort((a, b) => {
        if (a[sortConfig.field] < b[sortConfig.field]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.field] > b[sortConfig.field]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      setEngineers(sortedEngineers);
    }
  }, [sortConfig]);

  //Toggle sort direction between ascending or descending
  const sortEngineers = (field) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.field === field && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ field, direction });
  };

  const deleteEngineer = async (engineerId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this engineer?");
    if (isConfirmed) {
      try {
        await deleteDoc(doc(db, 'engineers', engineerId));
        setEngineers(engineers.filter(engineer => engineer.id !== engineerId));
      } catch (error) {
        console.error("Error deleting engineer: ", error);
        alert("Failed to delete engineer. Please try again.");
      }
    }
  };

  return (
    <div className="admin-engineers">
      <Helmet>
        <title>Admin Engineers - BoilerRight</title>
        <link rel="canonical" href="https://www.boiler-right.co.uk/admin-engineers" />
        <meta name="robots" content="noindex, nofollow"/>
      </Helmet>
      <h1>Gas Engineers</h1>
      {loading ? (
        <div className="loader-container">
          <RotatingLines
            className="main-app-loader"
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            visible={true}
          />
        </div>
      ) : (
        <div className="table-container">
          <table>
            <thead>
            <tr>
              {/* Replace these headers with your actual engineer fields */}
              <th onClick={() => sortEngineers('id')}>ID</th>
              <th onClick={() => sortEngineers('gasSafeNumber')}>Gas Safe Number</th>
              <th onClick={() => sortEngineers('lastname')}>Name</th>
              <th onClick={() => sortEngineers('company')}>Company</th>
              <th onClick={() => sortEngineers('email')}>Email</th>
              <th onClick={() => sortEngineers('dateOfBirth')}>Date of Birth</th>
              <th>Delete Engineer</th>
            </tr>
            </thead>
            <tbody>
              {engineers.map(engineer => (
                <tr key={engineer.id}>
                  {/* Replace these cells with your actual engineer fields */}
                  <td>{engineer.id}</td>
                  <td>{engineer.gasSafeNumber}</td>
                  <td>
                    {
                      [
                        engineer.firstname,
                        engineer.lastname
                      ].filter(Boolean).join(' ')
                    }
                  </td>
                  <td>{engineer.company}</td>
                  <td>{engineer.email}</td>
                  <td>{engineer.dateOfBirth}</td>
                  <td>
                    <button className="x-button "onClick={() => deleteEngineer(engineer.id)}>X</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AdminEngineers;
