import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection, doc, deleteDoc, updateDoc, deleteField, getDoc, setDoc, arrayUnion, runTransaction, query, where} from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { RotatingLines } from 'react-loader-spinner';
import { getFunctions, httpsCallable } from 'firebase/functions';
import moment from 'moment';
import { FaUndoAlt as UndoArrow } from 'react-icons/fa';

import { db } from 'config/firebaseConfig';
import 'App.css';
import './AdminJobs.css';

const AdminJobs = () => {
  const navigate = useNavigate();
  const functions = getFunctions();
  const [activeTab, setActiveTab] = useState('Pending Jobs');
  const [jobs, setJobs] = useState([]);
  const [products, setProducts] = useState([]);
  const [sortConfig, setSortConfig] = useState({ field: 'date', direction: 'ascending' });
  const [engineers, setEngineers] = useState([]);
  const [dateChanges, setDateChanges] = useState({});
  const [durationChanges, setDurationChanges] = useState({});
  const [loading, setLoading] = useState(true);
  const [sendConfirmationEmailsLoading, setSendConfirmationEmailsLoading] = useState(false);
  const [sendAdditionalCostsEmailsLoading, setSendAdditionalCostsEmailsLoading] = useState(false);
  const [setStatusAwaitingPaymentLoading, setSetStatusAwaitingPaymentLoading] = useState(false);
  const [setStatusCompleteLoading, setSetStatusCompleteLoading] = useState(false);
  const [deleteJobLoading, setDeleteJobLoading] = useState(false);
  const [jobConfirmationEmailOptions, setJobConfirmationEmailOptions] = useState({  });
  const [confirmAdditionalCostsEmailOptions, setConfirmAdditionalCostsEmailOptions] = useState({});
  const [jobCancellationEmailOptions, setJobCancellationEmailOptions] = useState({});
  const [awaitingPaymentEmailOptions, setAwaitingPaymentEmailOptions] = useState({});
  const [sendCustomerPaymentEmail, setSendCustomerPaymentEmail] = useState({});
  const [confirmJobStatus, setConfirmJobStatus] = useState({});
  const [confirmAdditionalCosts, setConfirmAdditionalCosts] = useState({});
  const [signOffStatus, setSignOffStatus] = useState({});
  const [jobCompleteStatus, setjobCompleteStatus] = useState({});

  //Fetch all job, engineer and product documents from pendingJobs and engineers collections
  useEffect(() => {
    const fetchJobs = async () => {
      let jobsCollectionRef;
      switch (activeTab) {
        case 'Pending Jobs':
          const statusesToExclude = ['Complete', 'Deleted'];
          jobsCollectionRef = query(collection(db, 'pendingJobs'), where('jobStatus', 'not-in', statusesToExclude));
          break;
        case 'Completed Jobs':
          jobsCollectionRef = query(collection(db, 'pendingJobs'), where('jobStatus', '==', 'Complete'));
          break;
        case 'Deleted Jobs':
          jobsCollectionRef = query(collection(db, 'pendingJobs'), where('jobStatus', '==', 'Deleted'));
          break;
        default:
          jobsCollectionRef = collection(db, 'pendingJobs');
      }

      const querySnapshot = await getDocs(jobsCollectionRef);
      const jobsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Initialize confirmAdditionalCosts state based on Firestore data
      const initialConfirmAdditionalCosts = {};
      jobsData.forEach(job => {
        initialConfirmAdditionalCosts[job.id] = job.confirmAdditionalCostsEmailsSent || false;
      });

      setJobs(jobsData);
      setConfirmAdditionalCosts(initialConfirmAdditionalCosts); // Update the state
      sortJobs('date');
    };

    const fetchEngineers = async () => {
      const engineersCollectionRef = collection(db, 'engineers');
      const querySnapshot = await getDocs(engineersCollectionRef);
      const engineersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEngineers(engineersData);
    };

    const fetchProducts = async () => {
      const productsCollectionRef = collection(db, 'products');
      const querySnapshot = await getDocs(productsCollectionRef);
      const productsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsData);
    };

    const fetchData = async () => {
      await fetchJobs(); // This now depends on the activeTab state
      await fetchEngineers();
      await fetchProducts();
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, [activeTab]); // Add activeTab as a dependency to re-fetch data when it changes


  //Initialize job statuses and email options
  useEffect(() => {
    const initialJobConfirmationEmailOptions = {};
    const initialConfirmAdditionalCostsEmailOptions = {};
    const initialSendCustomerEmail = {};
    const initialSignOffStatus = {};
    const initialJobCancellationEmailOptions = {};
    const initialAwaitingPaymentEmailOptions = {};

    jobs.forEach(job => {
      initialJobConfirmationEmailOptions[job.id] = { sendEngineerEmail: true, sendCustomerEmail: true, sendAdminEmail: true };
      initialConfirmAdditionalCostsEmailOptions[job.id] = {sendCustomerEmail: true, sendAdminEmail: true };
      initialSendCustomerEmail[job.id] = true;
      initialSignOffStatus[job.id] = job.jobStatus === "Awaiting Payment";
      initialJobCancellationEmailOptions[job.id] = {sendCustomerEmail: true, sendEngineerEmail: true, sendAdminEmail: true};
      initialAwaitingPaymentEmailOptions[job.id] = {sendCustomerEmail: true, sendAdminEmail: true};
    });

    setJobConfirmationEmailOptions(initialJobConfirmationEmailOptions);
    setConfirmAdditionalCostsEmailOptions(initialConfirmAdditionalCostsEmailOptions);
    setSendCustomerPaymentEmail(initialSendCustomerEmail);
    setSignOffStatus(initialSignOffStatus);
    setJobCancellationEmailOptions(initialJobCancellationEmailOptions);
    setAwaitingPaymentEmailOptions(initialAwaitingPaymentEmailOptions);
  }, [jobs]);

  //Sort jobs based on sortConfig if active
  useEffect(() => {
    if (sortConfig !== null) {
      const sortedJobs = [...jobs].sort((a, b) => {
        if (a[sortConfig.field] < b[sortConfig.field]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.field] > b[sortConfig.field]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      setJobs(sortedJobs);
    }
  }, [sortConfig]);

  //Updates the confirmJobStatus variable based on a job's status. Called whenever there is a change in the jobs array.
  useEffect(() => {
    const updatedConfirmJobStatus = {};
    jobs.forEach(job => {
      updatedConfirmJobStatus[job.id] = job.jobStatus === "In Progress" || job.jobStatus === "Awaiting Sign-Off" || job.jobStatus === "Awaiting Payment" || job.jobStatus === "Complete" || job.jobStatus === "Deleted";
    });
    setConfirmJobStatus(updatedConfirmJobStatus);
  }, [jobs]);

  const sortJobs = (field) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.field === field && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ field, direction });
  };

  function errorMsg(error = "Error") {
    return <span className="red">{error}</span>;
  }

  const deleteJob = async (jobId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this job and send confirmation emails to the selected parties?");
    if (isConfirmed) {
      try {
        setDeleteJobLoading(prev => ({ ...prev, [jobId]: true }));
        const jobRef = doc(db, 'pendingJobs', jobId);
        const jobSnapshot = await getDoc(jobRef);
        if (!jobSnapshot.exists()) {
          throw new Error("Job not found");
        }
        const currentJobData = jobSnapshot.data();

        const preDeletionStatus = currentJobData.jobStatus;

        const sendJobCancellationEmails = httpsCallable(functions, 'sendJobCancellationEmails');
        await sendJobCancellationEmails({
          jobId: jobId,
          sendCustomerEmail: jobCancellationEmailOptions[jobId]?.sendCustomerEmail,
          sendEngineerEmail: jobCancellationEmailOptions[jobId]?.sendEngineerEmail,
          sendAdminEmail: jobCancellationEmailOptions[jobId]?.sendAdminEmail
        }).then((result) => {
          alert(result.data.message);
          console.log(result.data);
        }).catch((error) => {
          console.error("Error sending job cancellation emails: ", error);
          alert("Failed to send job cancellation emails. Please check the logs for more details.");
        });

        // Update the job's preDeletionJobStatus to the current job status
        // and set jobStatus to 'Deleted' in a single update operation
        await updateDoc(jobRef, {
          preDeletionJobStatus: preDeletionStatus,
          jobStatus: 'Deleted'
        });

        // Update local state to reflect the status change
        setJobs(prevJobs => prevJobs.filter(job => job.id !== jobId));
        setDeleteJobLoading(prev => ({ ...prev, [jobId]: false }));

      } catch (error) {
        console.error("Error executing job cancellation function: ", error);
        alert("Failed to execute job cancellation function. Please try again.");
      }
    }
  };

  const undoDeleteJob = async (jobId) => {
    const isConfirmed = window.confirm("Are you sure you want to undo deletion of this job?");
    if (isConfirmed) {
      try {
        const jobRef = doc(db, 'pendingJobs', jobId);
        const jobSnapshot = await getDoc(jobRef);
        if (!jobSnapshot.exists()) {
          throw new Error("Job not found");
        }
        const currentJobData = jobSnapshot.data();

        // Check if preDeletionJobStatus exists before attempting to revert
        if (!currentJobData.preDeletionJobStatus) {
          throw new Error("No pre-deletion job status found");
        }

        // Set the job's status back to the preDeletionJobStatus
        // and remove the preDeletionJobStatus field
        await updateDoc(jobRef, {
          jobStatus: currentJobData.preDeletionJobStatus,
          preDeletionJobStatus: deleteField()
        });

        // Update the local state to reflect these changes
        setJobs(prevJobs => prevJobs.filter(job => job.id !== jobId));

      } catch (error) {
        console.error("Error undoing job deletion: ", error);
        alert("Failed to undo deletion. Please try again.");
      }
    }
  };

  //Assign an engineer to a job. Also add jobCompletionID to be used in job completion form URL
  const assignEngineer = async (jobId, engineer) => {
    const jobRef = doc(db, 'pendingJobs', jobId);
    //Create a job completion ID using the last 4 characters of the engineer id. Ensures that if a job is reassigned, previously assigned engineer does not have access to completion form
    const jobCompletionID = jobId + '-' + engineer.id.slice(-4);
    const engineerAssignedString = `${engineer.firstname} ${engineer.lastname} (${engineer.company}, ${engineer.gasSafeNumber})`;

    try {
      await updateDoc(jobRef, {
        engineerAssigned: engineerAssignedString,
        assignedEngineerID: engineer.id,
        jobCompletionID
      });

      setJobs(jobs.map(job => job.id === jobId ? {
        ...job,
        engineerAssigned: engineerAssignedString,
        assignedEngineerID: engineer.id,
        jobCompletionID
      } : job));
    } catch (error) {
      console.error('Error assigning engineer:', error);
      alert("Failed to assign engineer. Please try again.");
    }
  };

  //Unassign engineer from job. Also remove jobCompletionID field to ensure job completion form is now inaccessible and revert job status
  const unassignEngineer = async (jobId) => {
    const isConfirmed = window.confirm("Are you sure you want to unassign this engineer from the job?");
    if (isConfirmed) {
      const jobRef = doc(db, 'pendingJobs', jobId);
      try {
        await updateDoc(jobRef, {
          engineerAssigned: deleteField(),
          assignedEngineerID: deleteField(),
          jobCompletionID: deleteField(),
          jobStatus: "Pending Assignment"
        });

        setJobs(prevJobs => prevJobs.map(job => {
          if (job.id === jobId) {
            return {
              ...job,
              engineerAssigned: null,
              assignedEngineerID: null,
              jobCompletionID: null,
              jobStatus: "Pending Assignment"
            };
          }
          return job;
        }));
      } catch (error) {
        console.error("Error unassigning engineer: ", error);
        alert("Failed to unassign the engineer. Please try again.");
      }
    }
  };

  //Updates date changes once date is selected
  const handleDateChange = (event, jobId) => {
    const newDate = event.target.value;
    setDateChanges({ ...dateChanges, [jobId]: newDate });
  };


  //Submits new date to Firestore and updates local jobs collection once user clicks away from date input
  const submitAssignedDate = async (jobId, dateValue) => {
    if (!dateValue) {
      alert("Please select a date.");
      return;
    }

    const selectedDate = new Date(dateValue);
    const today = new Date();
    // Set the time of today to 00:00:00 for a fair comparison
    today.setHours(0, 0, 0, 0);

    if (selectedDate < today) {
      alert("The assigned date cannot be before today's date.");
      return;
    }

    const jobRef = doc(db, 'pendingJobs', jobId);

    try {
      await updateDoc(jobRef, { assignedDate: dateValue });

      setJobs(jobs.map(job => job.id === jobId ? { ...job, assignedDate: dateValue } : job));
    } catch (error) {
      console.error("Error updating assigned date: ", error);
      alert("Failed to update the assigned date. Please try again.");
    }
  };

  //Clears assigned date in Firebase and local jobs collection and reverts status back to "Pending Assignment"
  const clearAssignedDate = async (jobId) => {
    const isConfirmed = window.confirm("Are you sure you want to clear this assigned date from the job?");
    if (isConfirmed) {
      const jobRef = doc(db, 'pendingJobs', jobId);

      try {
        await updateDoc(jobRef, {
          assignedDate: deleteField(),
          jobStatus: "Pending Assignment"
        });

        // Update the jobs array in the state to reflect the changes
        setJobs(prevJobs => prevJobs.map(job => {
          if (job.id === jobId) {
            return {
              ...job,
              assignedDate: null,        // Clear the assigned date
              jobStatus: "Pending Assignment" // Update the job status
            };
          }
          return job;
        }));
      } catch (error) {
        console.error("Error clearing assigned date: ", error);
        alert("Failed to clear the assigned date. Please try again.");
      }
    }
  };

  //Updates duration changes once duration is entered
  const handleDurationChange = (event, jobId) => {
   //Ensure value between 1-5
   let value = parseInt(event.target.value, 10);
   if (value < 1) value = 1;
   else if (value > 5) value = 5;

   const newDuration = value;
   setDurationChanges({ ...durationChanges, [jobId]: newDuration });
 };

 //Updates customer payment email checkbox changes when checkbox checked/unchecked
 const handleSendCustomerPaymentEmailChange = (jobId, checked) => {
   setSendCustomerPaymentEmail(prev => ({
     ...prev,
     [jobId]: checked
   }));
 };

 //Submits new duration to Firestore and update local jobs collection once user clicks away from duration input
  const submitAssignedDuration = async (jobId, durationValue) => {
    if (!durationValue) {
      alert("Please select a duration.");
      return;
    }

    const jobRef = doc(db, 'pendingJobs', jobId);

    try {
      await updateDoc(jobRef, { assignedDuration: durationValue });

      // Update the jobs array in the state
      setJobs(jobs.map(job => job.id === jobId ? { ...job, assignedDuration: durationValue } : job));
    } catch (error) {
      console.error("Error updating assigned duration: ", error);
      alert("Failed to update the assigned duration. Please try again.");
    }
  };

  //Clears assigned duration in Firebase and local jobs collection and reverts status back to "Pending Assignment"
  const clearAssignedDuration = async (jobId) => {
    const isConfirmed = window.confirm("Are you sure you want to clear this assigned duration from the job?");
    if (isConfirmed) {
      const jobRef = doc(db, 'pendingJobs', jobId);

      try {
        await updateDoc(jobRef, {
          assignedDuration: deleteField(),
          jobStatus: "Pending Assignment"
        });

        // Update the jobs array in the state
        setJobs(prevJobs => prevJobs.map(job => {
          if (job.id === jobId) {
            return {
              ...job,
              assignedDuration: null,
              jobStatus: "Pending Assignment"
            };
          } else {
            return job;
          }
        }));
      } catch (error) {
        console.error("Error clearing assigned duration: ", error);
        alert("Failed to clear the assigned duration. Please try again.");
      }
    }
  };

  //Handles logic for when confirm job checkbox is checked or unchecked
  const handleConfirmJobCheckboxChange = async (jobId, checked) => {
    const msg = !checked ? "Are you sure you want to revert the status of this job to 'Pending Assignment'?"
                         : "Are you sure you want to set the status of this job to 'In Progress'? Emails to selected parties will be sent!";

    const isConfirmed = window.confirm(msg);
    if (isConfirmed) {
      setConfirmJobStatus(prev => ({ ...prev, [jobId]: checked }));

      const job = jobs.find(j => j.id === jobId);
      if (!job) {
        alert("Job not found!")
        console.error("Job not found");
        return;
      }

      if (checked) {
        // Send confirmation emails and update job status to "In Progress"
        setSendConfirmationEmailsLoading(prev => ({ ...prev, [jobId]: true }));
        const sendJobConfirmationEmails = httpsCallable(functions, 'sendJobConfirmationEmails');

        sendJobConfirmationEmails({
          jobID: job.id,
          sendEngineerEmail: jobConfirmationEmailOptions[jobId].sendEngineerEmail,
          sendCustomerEmail: jobConfirmationEmailOptions[jobId].sendCustomerEmail,
          sendAdminEmail: jobConfirmationEmailOptions[jobId].sendAdminEmail
        })
        .then(async (result) => {
          alert(result.data.message);
          const jobRef = doc(db, 'pendingJobs', jobId);
          await updateDoc(jobRef, { jobStatus: "In Progress" });
          setJobs(prevJobs => prevJobs.map(j => j.id === jobId ? { ...j, jobStatus: "In Progress" } : j));
        })
        .catch((error) => {
          alert('Error sending confirmation emails');
          console.error('Error in handleConfirmJobCheckboxChange:', error);
        })
        .finally(() => {
          setSendConfirmationEmailsLoading(prev => ({ ...prev, [jobId]: false }));
        });
      } else {
        // Revert status to 'Awaiting Assignment' and show email options
        const jobRef = doc(db, 'pendingJobs', jobId);
        await updateDoc(jobRef, { jobStatus: "Pending Assignment" });
        setJobs(prevJobs => prevJobs.map(job => job.id === jobId ? { ...job, jobStatus: "Pending Assignment" } : job));
      }
    }
  };

  const handleConfirmAdditionalCostsCheckboxChange = async (jobId, checked) => {
    const msg = checked ? "Are you sure you want to send confirmation emails of the updated costs to all checked parties?"
                         : "Additional costs confirmation emails have been sent. Are you sure you want to revert this status?";

    const isConfirmed = window.confirm(msg);
    if (isConfirmed) {
      if (checked) {
        setSendAdditionalCostsEmailsLoading(prev => ({ ...prev, [jobId]: true }));
        try {
          const sendConfirmAdditionalCostsEmails = httpsCallable(functions, 'sendConfirmAdditionalCostsEmails');
          const result = await sendConfirmAdditionalCostsEmails({
            jobID: jobId,
            sendCustomerEmail: confirmAdditionalCostsEmailOptions[jobId]?.sendCustomerEmail,
            sendAdminEmail: confirmAdditionalCostsEmailOptions[jobId]?.sendAdminEmail
          });

          alert(result.data.message); // Success message from cloud function

          // Update Firestore and local state after successful email sending
          const jobRef = doc(db, 'pendingJobs', jobId);
          await updateDoc(jobRef, {
            confirmAdditionalCostsEmailsSent: checked
          });
          setConfirmAdditionalCosts(prev => ({ ...prev, [jobId]: checked }));
        } catch (error) {
          alert('Error sending additional costs confirmation emails');
          console.error('Error in handleConfirmAdditionalCostsCheckboxChange:', error);
        } finally {
          setSendAdditionalCostsEmailsLoading(prev => ({ ...prev, [jobId]: false }));
        }
      } else {
        // If checkbox is unchecked, simply update Firestore without sending emails
        const jobRef = doc(db, 'pendingJobs', jobId);
        try {
          await updateDoc(jobRef, {
            confirmAdditionalCostsEmailsSent: checked
          });
          setConfirmAdditionalCosts(prev => ({ ...prev, [jobId]: checked }));
        } catch (error) {
          console.error("Error updating Firestore: ", error);
          alert('Failed to update job status.');
        }
      }
    }
  };

  //Handles logic for when sign off checkbox is checked or unchecked
  const handleSignOffCheckboxChange = async (jobId, checked) => {
    const msg = !checked ? "Are you sure you want to revert the status of this job to 'Awaiting Sign-Off?'"
                         : "Are you sure you want to sign off this job and set the status to 'Awaiting Payment'? Emails to selected parties will be sent!";

    const isConfirmed = window.confirm(msg);
    if (isConfirmed) {
      setSetStatusAwaitingPaymentLoading(prev => ({ ...prev, [jobId]: checked }));

      const job = jobs.find(j => j.id === jobId);
      if (!job) {
        alert("Job not found!")
        console.error("Job not found");
        return;
      }

      if (checked) {
        // Send sign off emails and update job status to "Awaiting Payment"
        setSetStatusAwaitingPaymentLoading(prev => ({ ...prev, [jobId]: true }));
        const sendSignOffEmails = httpsCallable(functions, 'sendSignOffEmails');

        sendSignOffEmails({
          jobId,
          sendCustomerEmail: awaitingPaymentEmailOptions[jobId]?.sendCustomerEmail,
          sendAdminEmail: awaitingPaymentEmailOptions[jobId]?.sendAdminEmail
        })
        .then(async (result) => {
          alert(result.data.message);
          const jobRef = doc(db, 'pendingJobs', jobId);
          await updateDoc(jobRef, { jobStatus: "Awaiting Payment" });
          setJobs(prevJobs => prevJobs.map(j => j.id === jobId ? { ...j, jobStatus: "Awaiting Payment" } : j));
        })
        .catch((error) => {
          alert('Error sending sign-off emails');
          console.error('Error in handleSignOffCheckboxChange:', error);
        })
        .finally(() => {
          setSetStatusAwaitingPaymentLoading(prev => ({ ...prev, [jobId]: false }));
        });
      } else {
        // Revert status to 'Awaiting Assignment' and show email options
        const jobRef = doc(db, 'pendingJobs', jobId);
        await updateDoc(jobRef, { jobStatus: "Awaiting Sign-Off" });
        setJobs(prevJobs => prevJobs.map(job => job.id === jobId ? { ...job, jobStatus: "Awaiting Sign-Off" } : job));
      }
    }
  };

  const handleSendAdditionalCostsEmails = async (jobId) => {

    const isConfirmed = window.confirm("Are you sure you want to send new quote email with the additional costs?");
    if (isConfirmed) {
      alert("Hi");
    }
  };

  const handleSetCompleteCheckboxChange = async (jobId, checked) => {
    const msg = !checked ? "Are you sure you want to revert the status of this job to 'Awaiting Payment'?"
                         : "Are you sure you want to set the status of this job to 'Complete'? This confirms that payment has been received from the customer and the engineer has been paid.";

    const isConfirmed = window.confirm(msg);
    if (isConfirmed) {
      const newStatus = checked ? "Complete" : "Awaiting Payment";

      try {
        setSetStatusCompleteLoading(prev => ({ ...prev, [jobId]: true }));
        const jobRef = doc(db, 'pendingJobs', jobId);
        await updateDoc(jobRef, { jobStatus: newStatus });

        setJobs(prevJobs => prevJobs.map(j => j.id === jobId ? { ...j, jobStatus: newStatus } : j));
        setJobs(jobs.filter(job => job.id !== jobId));
      } catch (error) {
        console.error('Error updating job status:', error);
        alert(`Error: ${error.message}`);
      } finally {
        setSetStatusCompleteLoading(prev => ({ ...prev, [jobId]: false }));
      }
    }
  };

  const isRowDisabled = (jobStatus) => {
        return jobStatus === "Complete" || jobStatus === "Deleted";
  };

  //Gets product by id from the downloaded products collection. Returns null if no product found.
  function getProduct(productId) {
    const product = products.find(p => p.id === productId);
    return product;
  }

  const addAdditionalCost = async (jobId) => {
    const description = prompt('Additional cost description:');
    if (!description) {
      return;
    }

    const amountStr = prompt('Additional cost amount:');
    if (!amountStr) {
      return;
    }

    const cost = Number(amountStr); // Convert the string input to a number
    if (!description.trim() || isNaN(cost) || cost === 0) {
      alert('Invalid input');
      return;
    }

    const jobRef = doc(db, 'pendingJobs', jobId);

    // Pre-calculate newTotalCost and newDiscountAmount outside of the transaction
    const jobDoc = await getDoc(jobRef);
    if (!jobDoc.exists()) {
      console.error("Document does not exist!");
      alert('Job not found!');
      return;
    }

    const jobData = jobDoc.data();
    let newTotalCost = jobData.totalCost || 0;
    let newDiscountAmount = jobData.discountAmount || 0;

    if (jobData.discount && jobData.discount > 0) {
      newTotalCost += newDiscountAmount; // Undo discount to get pre-discount total
    }

    newTotalCost += cost; // Add the new cost

    if (jobData.discount && jobData.discount > 0) {
      newDiscountAmount = (jobData.discount / 100) * newTotalCost; // Recalculate discount amount
      newTotalCost -= newDiscountAmount; // Apply the new discount
    }

    newTotalCost = Number(newTotalCost.toFixed(2));
    newDiscountAmount = Number(newDiscountAmount.toFixed(2));

    try {
      await runTransaction(db, async (transaction) => {
        transaction.update(jobRef, {
          additionalCosts: arrayUnion({ label: description, price: cost, addedAt: new Date() }),
          totalCost: newTotalCost,
          discountAmount: newDiscountAmount
        });
      });

      // Update the local state with the pre-calculated values
      setJobs(jobs.map(job => {
        if (job.id === jobId) {
          const updatedAdditionalCosts = job.additionalCosts ? [...job.additionalCosts, { label: description, price: cost }] : [{ label: description, price: cost }];
          return {
            ...job,
            additionalCosts: updatedAdditionalCosts,
            totalCost: newTotalCost,
            discountAmount: newDiscountAmount
          };
        }
        return job;
      }));

      // Uncheck the email sent confirmation checkbox for this job
      setConfirmAdditionalCosts(prevState => ({
        ...prevState,
        [jobId]: false
      }));

    } catch (error) {
      console.error("Transaction failed: ", error);
      alert('Failed to add additional cost. Please try again.');
    }
  };

  const removeAdditionalCost = async (jobId, costLabel) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this additional cost item?");
    if (!isConfirmed) {
      console.log("Removal cancelled.");
      return;
    }

    const jobRef = doc(db, 'pendingJobs', jobId);
    const jobDoc = await getDoc(jobRef);
    if (!jobDoc.exists()) {
      alert("Job not found!");
      return;
    }

    const jobData = jobDoc.data();
    const costItem = jobData.additionalCosts.find(cost => cost.label === costLabel);
    if (!costItem) {
      alert("Cost item not found.");
      return; // Item not found, exit the function
    }

    // Calculate the pre-discount total cost if a discount exists
    let preDiscountTotal = jobData.totalCost;
    if (jobData.discount && jobData.discount > 0) {
      preDiscountTotal += jobData.discountAmount; // Add back the discount amount to get the total before discount
    }

    // Subtract the cost of the specific additional cost item
    preDiscountTotal -= costItem.price;

    // Recalculate the discount amount based on the new pre-discount total
    let newDiscountAmount = 0;
    if (jobData.discount && jobData.discount > 0) {
      newDiscountAmount = (jobData.discount / 100) * preDiscountTotal;
    }

    // Apply the recalculated discount to find the new total cost
    let newTotalCost = preDiscountTotal - newDiscountAmount;

    // Round the values to 2 decimal places
    newTotalCost = Number(newTotalCost.toFixed(2));
    newDiscountAmount = Number(newDiscountAmount.toFixed(2));

    const updatedAdditionalCosts = jobData.additionalCosts.filter(cost => cost.label !== costLabel);

    try {
      await runTransaction(db, async (transaction) => {
        transaction.update(jobRef, {
          additionalCosts: updatedAdditionalCosts,
          totalCost: newTotalCost,
          discountAmount: newDiscountAmount
        });
      });

      // Use the pre-calculated values to update the local state
      setJobs(jobs.map(job => {
        if (job.id === jobId) {
          return {
            ...job,
            additionalCosts: updatedAdditionalCosts,
            totalCost: newTotalCost,
            discountAmount: newDiscountAmount
          };
        }
        return job;
      }));

      // Uncheck the email sent confirmation checkbox for this job
      setConfirmAdditionalCosts(prevState => ({
        ...prevState,
        [jobId]: false
      }));
    } catch (error) {
      console.error("Transaction failed: ", error);
      alert('Failed to remove additional cost. Please try again.');
    }
  };

  return (
    <div className="admin-jobs">
      <Helmet>
        <title>Admin Jobs - BoilerRight</title>
        <link rel="canonical" href="https://www.boiler-right.co.uk/admin-jobs" />
        <meta name="robots" content="noindex, nofollow"/>
      </Helmet>
      <h1>Customer Jobs</h1>
      <div className="tabs">
        <span
          onClick={() => setActiveTab('Pending Jobs')}
          className={`tab ${activeTab === 'Pending Jobs' ? 'activeTab' : ''}`}
        >
          Pending Jobs
        </span>
        <span
          onClick={() => setActiveTab('Completed Jobs')}
          className={`tab ${activeTab === 'Completed Jobs' ? 'activeTab' : ''}`}
        >
          Completed Jobs
        </span>
        <span
          onClick={() => setActiveTab('Deleted Jobs')}
          className={`tab ${activeTab === 'Deleted Jobs' ? 'activeTab' : ''}`}
        >
          Deleted Jobs
        </span>
      </div>
      {loading ? (
        <div className="loader-container">
          <RotatingLines
            className="main-app-loader"
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            visible={true}
          />
        </div>
      ) : (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th onClick={() => sortJobs('id')}>Job ID</th>
                <th onClick={() => sortJobs('jobStatus')}>Job Status</th>
                <th onClick={() => sortJobs('date')}>Date</th>
                <th onClick={() => sortJobs('customerName')}>Customer</th>
                <th onClick={() => sortJobs('address')}>Address</th>
                <th onClick={() => sortJobs('email')}>Email</th>
                <th onClick={() => sortJobs('telephone')}>Telephone</th>
                <th onClick={() => sortJobs('desiredCombiBoiler')}>Desired Boiler</th>
                <th onClick={() => sortJobs('desiredProgrammer')}>Desired Programmer</th>
                <th onClick={() => sortJobs('priceCombiReplacement')}>Price Combi Replacement</th>
                <th onClick={() => sortJobs('priceFlue')}>Price Flue</th>
                <th onClick={() => sortJobs('priceStandoffKit')}>Price Standoff Kit (If Applicable)</th>
                <th onClick={() => sortJobs('priceProgrammer')}>Price Programmer</th>
                <th onClick={() => sortJobs('additionalCosts')}>Additional Costs</th>
                <th onClick={() => sortJobs('totalCost')}>Price Total</th>
                <th onClick={() => sortJobs('discount')}>Discount Code (If Applicable)</th>
                <th onClick={() => sortJobs('discount')}>Discount (If Applicable)</th>
                <th onClick={() => sortJobs('discountAmount')}>Discount Amount (If Applicable)</th>
                <th onClick={() => sortJobs('currentBoilerWorking')}>Current Boiler Working</th>
                <th onClick={() => sortJobs('flueExit')}>Flue Exit</th>
                <th onClick={() => sortJobs('pipesFromCeiling')}>Pipes From Ceiling</th>
                <th onClick={() => sortJobs('homeType')}>Home Type</th>
                <th onClick={() => sortJobs('numberOfBathrooms')}>No. of Bathrooms</th>
                <th onClick={() => sortJobs('numberOfRadiators')}>Max No. of Radiators</th>
                <th>Assign Engineer</th>
                <th>Assign Date</th>
                <th>Assign Duration (Days)</th>
                <th>Confirm Job</th>
                <th>Sign Off Job</th>
                <th>Complete Job</th>
                <th>Initial Visit Form</th>
                <th>Engineer Job Completion Form</th>
                <th>Delete Job</th>
              </tr>
            </thead>
            <tbody>
              {jobs.map(job => (
                <tr key={job.id} className={job.jobStatus === "Complete" || job.jobStatus === "Deleted" ? 'disabled-row' : ''}>
                  <td>{job.id}</td>
                  <td>
                    <span
                      style={{
                        color  : job.jobStatus === "Pending Initial Visit" ? 'red'
                               : job.jobStatus === "Pending Assignment" ? 'red'
                               : job.jobStatus === "In Progress" ? 'orange'
                               : job.jobStatus === "Awaiting Sign-Off" ? 'orange'
                               : job.jobStatus === "Awaiting Payment" ? 'orange'
                               : job.jobStatus === "Complete" ? 'green'
                               : job.jobStatus === "Deleted" ? 'red'
                               : 'black' // default color
                      }}
                    >
                      {job.jobStatus}
                    </span>
                  </td>
                  <td>{job.date.toDate().toLocaleString()}</td>
                  <td>
                    {
                      [
                        job.firstname,
                        job.lastname
                      ].filter(Boolean).join(' ')
                    }
                  </td>
                  <td>
                    {
                      [
                        job.address.firstLine,
                        job.address.town,
                        job.address.postcode
                      ].filter(Boolean).join(', ')
                    }
                  </td>
                  <td>{job.email}</td>
                  <td>{job.telephone}</td>
                  <td>{getProduct(job.desiredCombiBoiler)?.displayName || errorMsg()}</td>
                  <td>{getProduct(job.desiredProgrammer)?.displayName || errorMsg()}</td>
                  <td>£{job.priceCombiReplacement}</td>
                  <td>£{job.priceFlue}</td>
                  <td>{job.priceStandoffKit !== 'N/A' ? `£${job.priceStandoffKit}` : 'N/A'}</td>
                  <td>£{job.priceProgrammer}</td>
                  <td>
                    {sendAdditionalCostsEmailsLoading[job.id] ? (
                      <div className="loader-container">
                        <RotatingLines
                          className="main-app-loader"
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="25"
                          visible={true}
                        />
                      </div>
                    ) : (
                      <>
                        <button
                          className={`additional-costs-but ${isRowDisabled(job.jobStatus) ? 'additional-costs-but-disabled' : ''}`}
                          onClick={() => addAdditionalCost(job.id)}
                          disabled={isRowDisabled(job.jobStatus)}
                        >
                          +
                        </button>
                        {job.additionalCosts && job.additionalCosts.length > 0 ? (
                          job.additionalCosts.map((cost, index) => (
                            <div className="additional-cost-item" key={index}>
                              <b>{cost.label}</b>: <span className="additional-cost-amount">£{cost.price}</span>
                              <button
                                className={`x-button small-x-button ${isRowDisabled(job.jobStatus) ? 'x-button-disabled' : ''}`}
                                onClick={() => removeAdditionalCost(job.id, cost.label)}
                                disabled={isRowDisabled(job.jobStatus)}
                              >
                                X
                              </button>
                            </div>
                          ))
                        ) : ""}
                        {job.additionalCosts && job.additionalCosts.length > 0 && (
                          <>
                            <input
                              className="larger-checkbox"
                              type="checkbox"
                              checked={confirmAdditionalCosts[job.id] || false}
                              onChange={(e) => handleConfirmAdditionalCostsCheckboxChange(job.id, e.target.checked)}
                              disabled={isRowDisabled(job.jobStatus)}
                            />
                            {!confirmAdditionalCosts[job.id] &&
                              (<div className="email-recipients">
                                <label>
                                  <input
                                    type="checkbox"
                                    disabled={isRowDisabled(job.jobStatus)}
                                    checked={confirmAdditionalCostsEmailOptions[job.id]?.sendCustomerEmail}
                                    onChange={() => setConfirmAdditionalCostsEmailOptions(prev => ({
                                      ...prev,
                                      [job.id]: { ...prev[job.id], sendCustomerEmail: !prev[job.id]?.sendCustomerEmail }
                                    }))}
                                  />
                                  Customer Email
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    disabled={isRowDisabled(job.jobStatus)}
                                    checked={confirmAdditionalCostsEmailOptions[job.id]?.sendAdminEmail}
                                    onChange={() => setConfirmAdditionalCostsEmailOptions(prev => ({
                                      ...prev,
                                      [job.id]: { ...prev[job.id], sendAdminEmail: !prev[job.id]?.sendAdminEmail }
                                    }))}
                                  />
                                  Admin Email
                                </label>
                              </div>
                            )}
                          </>
                        )}
                        </>
                      )
                    }
                  </td>

                  <td>£{job.totalCost}</td>
                  <td>{job.discountCode ? job.discountCode : 'N/A'}</td>
                  <td>{job.discount ? `${job.discount}%` : 'N/A'}</td>
                  <td>{job.discountAmount ? `£${job.discountAmount}` : 'N/A'}</td>
                  <td>{job.currentBoilerWorking}</td>
                  <td>{job.flueExit}</td>
                  <td>{job.pipesFromCeiling}</td>
                  <td>{job.homeType}</td>
                  <td>{job.numberOfBathrooms}</td>
                  <td>{job.numberOfRadiators}</td>
                  <td>
                    {job.jobStatus !== "Pending Initial Visit" ? (
                      job.engineerAssigned ? (
                        <>
                          {job.engineerAssigned}
                          <button
                            className={`x-button ${isRowDisabled(job.jobStatus) ? 'x-button-disabled' : ''}`}
                            onClick={() => unassignEngineer(job.id)}
                            disabled={isRowDisabled(job.jobStatus)}
                          >
                            X
                          </button>
                        </>
                      ) : (
                        <select
                          onChange={(e) => assignEngineer(job.id, engineers.find(engineer => engineer.id === e.target.value))}
                          disabled={isRowDisabled(job.jobStatus)}
                          >
                          <option value="">Select Engineer</option>
                          {engineers.map(engineer => (
                            <option key={engineer.id} value={engineer.id}>
                              {engineer.firstname} {engineer.lastname} ({engineer.company}, {engineer.gasSafeNumber})
                            </option>
                          ))}
                        </select>
                      )
                    ) : ""}
                  </td>
                  <td>
                    {job.jobStatus !== "Pending Initial Visit" ? (
                      job.assignedDate ? (
                        <>
                          {moment(job.assignedDate).format('DD/MM/YYYY')}
                          <button
                            className={`x-button ${isRowDisabled(job.jobStatus) ? 'x-button-disabled' : ''}`}
                            onClick={() => clearAssignedDate(job.id)}
                            disabled={isRowDisabled(job.jobStatus)}
                          >
                            X
                          </button>
                        </>
                      ) : (
                        <input
                          type="date"
                          value={dateChanges[job.id] || ''}
                          onChange={(e) => handleDateChange(e, job.id)}
                          onBlur={(e) => submitAssignedDate(job.id, dateChanges[job.id])}
                          disabled={isRowDisabled(job.jobStatus)}
                        />
                      )
                    ) : ""}
                  </td>
                  <td>
                    {job.jobStatus !== "Pending Initial Visit" ? (
                      job.assignedDuration ? (
                        <>
                          {job.assignedDuration}
                          <div>
                            <button
                              className={`x-button ${isRowDisabled(job.jobStatus) ? 'x-button-disabled' : ''}`}
                              onClick={() => clearAssignedDuration(job.id)}
                              disabled={isRowDisabled(job.jobStatus)}
                            >
                              X
                            </button>
                          </div>
                        </>
                      ) : (
                        <input
                          className="small-input"
                          type="number"
                          min="1"
                          max="5"
                          value={durationChanges[job.id] || ''}
                          onChange={(e) => handleDurationChange(e, job.id)}
                          onBlur={(e) => submitAssignedDuration(job.id, durationChanges[job.id])}
                          disabled={isRowDisabled(job.jobStatus)}
                        />
                      )
                    ) : ""}
                  </td>
                  <td>
                    {sendConfirmationEmailsLoading[job.id] ? (
                      <div className="loader-container">
                        <RotatingLines
                          className="main-app-loader"
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="25"
                          visible={true}
                        />
                      </div>
                    ) : (
                      job.engineerAssigned && job.assignedDate && job.assignedDuration ? (
                        <>
                          <input
                            className="larger-checkbox"
                            type="checkbox"
                            checked={confirmJobStatus[job.id] || false}
                            onChange={(e) => handleConfirmJobCheckboxChange(job.id, e.target.checked)}
                            disabled={isRowDisabled(job.jobStatus)}
                          />
                          {job.jobStatus === "Pending Assignment" && (
                            <div className="email-recipients">
                              <label>
                                <input
                                  type="checkbox"
                                  disabled={isRowDisabled(job.jobStatus)}
                                  checked={jobConfirmationEmailOptions[job.id]?.sendEngineerEmail}
                                  onChange={() => setJobConfirmationEmailOptions(prev => ({
                                    ...prev,
                                    [job.id]: { ...prev[job.id], sendEngineerEmail: !prev[job.id]?.sendEngineerEmail }
                                  }))}
                                />
                                Engineer Email
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  disabled={isRowDisabled(job.jobStatus)}
                                  checked={jobConfirmationEmailOptions[job.id]?.sendCustomerEmail}
                                  onChange={() => setJobConfirmationEmailOptions(prev => ({
                                    ...prev,
                                    [job.id]: { ...prev[job.id], sendCustomerEmail: !prev[job.id]?.sendCustomerEmail }
                                  }))}
                                />
                                Customer Email
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  disabled={isRowDisabled(job.jobStatus)}
                                  checked={jobConfirmationEmailOptions[job.id]?.sendAdminEmail}
                                  onChange={() => setJobConfirmationEmailOptions(prev => ({
                                    ...prev,
                                    [job.id]: { ...prev[job.id], sendAdminEmail: !prev[job.id]?.sendAdminEmail }
                                  }))}
                                />
                                Admin Email
                              </label>
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      )
                    )}
                  </td>
                  <td>
                    {setStatusAwaitingPaymentLoading[job.id] ? (
                      <div className="loader-container">
                        <RotatingLines
                          className="main-app-loader"
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="25"
                          visible={true}
                        />
                      </div>
                    ) : (
                      (job.jobStatus === "Awaiting Sign-Off" || job.jobStatus === "Awaiting Payment" || job.jobStatus === "Complete" || (job.jobStatus === "Deleted" && job.preDeletionJobStatus === "Awaiting Payment")) && (
                        <>
                            <input
                              className="larger-checkbox"
                              type="checkbox"
                              checked={(job.jobStatus === "Awaiting Payment" || job.jobStatus === "Complete" || (job.jobStatus === "Deleted" && job.preDeletionJobStatus === "Awaiting Payment"))}
                              onChange={(e) => handleSignOffCheckboxChange(job.id, e.target.checked)}
                              disabled={isRowDisabled(job.jobStatus)}
                            />
                          {job.jobStatus === "Awaiting Sign-Off" && (
                            <div className="email-recipients">
                              <label>
                                <input
                                  type="checkbox"
                                  disabled={isRowDisabled(job.jobStatus)}
                                  checked={awaitingPaymentEmailOptions[job.id]?.sendCustomerEmail}
                                  onChange={() => setAwaitingPaymentEmailOptions(prev => ({
                                    ...prev,
                                    [job.id]: { ...prev[job.id], sendCustomerEmail: !prev[job.id]?.sendCustomerEmail }
                                  }))}
                                />
                                Customer Email
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  disabled={isRowDisabled(job.jobStatus)}
                                  checked={awaitingPaymentEmailOptions[job.id]?.sendAdminEmail}
                                  onChange={() => setAwaitingPaymentEmailOptions(prev => ({
                                    ...prev,
                                    [job.id]: { ...prev[job.id], sendAdminEmail: !prev[job.id]?.sendAdminEmail }
                                  }))}
                                />
                                Admin Email
                              </label>
                            </div>
                          )}
                        </>
                      )
                    )}
                  </td>
                  <td>
                    {setStatusCompleteLoading[job.id] ? (
                      <div className="loader-container">
                        <RotatingLines
                          className="main-app-loader"
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="25"
                          visible={true}
                        />
                      </div>
                    ) : (
                      (job.jobStatus === "Awaiting Payment" || job.jobStatus === "Complete") && (
                        <div className="checkbox-container">
                          <input
                            className="larger-checkbox"
                            type="checkbox"
                            checked={jobCompleteStatus[job.id] ?? (job.jobStatus === "Complete")}
                            onChange={(e) => handleSetCompleteCheckboxChange(job.id, e.target.checked)}
                          />
                        </div>
                      )
                    )}
                  </td>
                  <td>
                    {
                      job.initialVisitFormID
                        ? (
                          <>
                            <a href={`${window.location.origin}/initial-visit?initialVisitFormID=${job.initialVisitFormID}`}
                               target="_blank" rel="noopener noreferrer">
                              Open
                            </a> <br/><br/>
                            {
                              job.jobStatus !== "Pending Initial Visit"
                                ? (
                                  <a href={`${window.location.origin}/initial-visit?initialVisitFormID=${job.initialVisitFormID}&editForm=true`}
                                       target="_blank" rel="noopener noreferrer">
                                      Edit
                                  </a>
                                )
                              : ""
                            }
                          </>
                          )
                        : ""
                    }
                  </td>
                  <td>
                    {
                      job.jobCompletionID
                        ? (
                          <>
                            <a href={`${window.location.origin}/job-completion?jobCompletionID=${job.jobCompletionID}`}
                               target="_blank" rel="noopener noreferrer">
                              Open
                            </a> <br/><br/>
                            {
                              job.jobStatus !== "In Progress" && job.jobStatus !== "Pending Assignment"
                                ? (
                                  <a href={`${window.location.origin}/job-completion?jobCompletionID=${job.jobCompletionID}&editForm=true`}
                                       target="_blank" rel="noopener noreferrer">
                                      Edit
                                  </a>
                                )
                              : ""
                            }
                          </>
                          )
                        : ""
                    }
                  </td>
                  <td>
                    {deleteJobLoading[job.id] ? (
                      <div className="loader-container">
                        <RotatingLines
                          className="main-app-loader"
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="25"
                          visible={true}
                        />
                      </div>
                    ) : (
                      <>
                        {job.jobStatus === 'Deleted' ? (
                          <UndoArrow onClick={() => undoDeleteJob(job.id)} style={{ cursor: 'pointer' }}/>
                        ) : (
                          <>
                            <button
                              className={`x-button ${isRowDisabled(job.jobStatus) ? 'x-button-disabled' : ''}`}
                              disabled={isRowDisabled(job.jobStatus)}
                              onClick={() => deleteJob(job.id)}
                            >
                              X
                            </button>
                            <div className="email-recipients">
                              <label>
                                <input
                                  type="checkbox"
                                  disabled={isRowDisabled(job.jobStatus)}
                                  checked={jobCancellationEmailOptions[job.id]?.sendCustomerEmail}
                                  onChange={() => setJobCancellationEmailOptions(prev => ({
                                    ...prev,
                                    [job.id]: { ...prev[job.id], sendCustomerEmail: !prev[job.id]?.sendCustomerEmail }
                                  }))}
                                />
                                Customer Email
                              </label>
                              {job.engineerAssigned &&
                                <label>
                                  <input
                                    type="checkbox"
                                    disabled={isRowDisabled(job.jobStatus)}
                                    checked={jobCancellationEmailOptions[job.id]?.sendEngineerEmail}
                                    onChange={() => setJobCancellationEmailOptions(prev => ({
                                      ...prev,
                                      [job.id]: { ...prev[job.id], sendEngineerEmail: !prev[job.id]?.sendEngineerEmail }
                                    }))}
                                  />
                                  Engineer Email
                              </label>
                            }
                              <label>
                                <input
                                  type="checkbox"
                                  disabled={isRowDisabled(job.jobStatus)}
                                  checked={jobCancellationEmailOptions[job.id]?.sendAdminEmail}
                                  onChange={() => setJobCancellationEmailOptions(prev => ({
                                    ...prev,
                                    [job.id]: { ...prev[job.id], sendAdminEmail: !prev[job.id]?.sendAdminEmail }
                                  }))}
                                />
                                Admin Email
                              </label>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AdminJobs;
