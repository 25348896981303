import React, { createContext, useState, useEffect } from 'react';
import { db } from 'config/firebaseConfig';
import {doc, onSnapshot } from 'firebase/firestore';

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isServiceDown, setIsServiceDown] = useState(false);
  const [isQuoteToolDown, setIsQuoteToolDown] = useState(false);

  useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
   const docRef = doc(db, 'settings', 'settings');
   const unsubscribe = onSnapshot(docRef, (doc) => {
     if (doc.exists()) {
       setIsServiceDown(doc.data().serviceDownEnabled);
       setIsQuoteToolDown(doc.data().quoteToolDownEnabled);
     }
   });

   return () => unsubscribe();
  }, []);

  // The context value that will be supplied to the consumers
  const contextValue = {
    isOnline,
    isServiceDown,
    isQuoteToolDown
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};
