import React, { useState } from 'react';
import Modal from 'react-modal';
import { collection, addDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { RotatingLines } from 'react-loader-spinner'
import { Helmet } from 'react-helmet';
import contactImg from '../../images/contact-img.jpg'
import phoneIcon from '../../images/ph-icon.png'
import emailIcon from '../../images/email-icon.png'

import './ContactUs.css';
import ErrorModal from 'components/Modals/ErrorModal/ErrorModal'

Modal.setAppElement('#root')

function ContactUs() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const functions = getFunctions();
    const addEnquiryData = httpsCallable(functions, 'addEnquiryData');
    try {
      setIsLoading(true);
      await addEnquiryData({ name: name, email: email, message: message });
      setIsLoading(false);
      setModalIsOpen(true);
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Error submitting enquiry:', error);
      setIsErrorModalOpen(true);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Contact - BoilerRight</title>
        <link rel="canonical" href="https://www.boiler-right.co.uk/contact-us" />
        <meta name="description" content="Reach out to BoilerRight in Scunthorpe. Whether you have queries about our services or need a boiler installation quote, we're here to assist." />
        {process.env.REACT_APP_FIREBASE_ENV === "development" && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>
      <div className='c-primary-page-sec'>
          <h1 className='c-page-title' data-aos="fade-up">Contact <span>Us</span></h1>
          <div className='container'>
              <div className='row'>
                  <div className='col-lg-4 pb-5 pb-lg-0 text-center text-lg-start' data-aos="fade-right">
                      <img className="contact-img" src={contactImg} alt="BoilerRight Contact section image of a person communicating using a tablet computer"/>
                  </div>
                  <div className='col-lg-8' data-aos="fade-left" data-aos-offset="-200">
                    <ul className='c-contact-info'>
                      <li><i><img src={phoneIcon} alt="Call BoilerRight icon" /></i><a href='tel:01724 656427'>01724 656427</a></li>
                      <li><i><img src={emailIcon} alt="Email BoilerRight icon" /></i><a href="mailto:contact@boiler-right.co.uk">contact@boiler-right.co.uk</a></li>
                    </ul>
                    <p>If you have any questions about our services or would like to request a quote, please don't hesitate to get in touch with us.</p>
                  <div className="contact-form">
                    <form onSubmit={handleSubmit}>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        autoComplete="name"
                        placeholder='Name'
                        className='form-control'
                      />
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder='Email'
                        autoComplete="email"
                        className='form-control'
                      />
                      <textarea
                        id="message"
                        name="message"
                        rows="8"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        placeholder='Message'
                        className='form-control'
                      ></textarea>
                      <div className='text-lg-start text-center'>
                      <div className="c-submit-btn-container">
                        <button className="btn c-submit-btn contact-submit-btn" type="submit" disabled={isLoading}>
                          Submit
                        </button>
                      </div>
                      </div>
                      {isLoading &&
                        <div className="loader-container">
                          <RotatingLines
                            className="submit-loader"
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="30"
                            visible={true}
                          />
                        </div>}

                      <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={() => setModalIsOpen(false)} // Function to close the modal when clicking outside
                        contentLabel="Meep"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }}
                      >
                        <h2>Thanks</h2>
                        <p>We've received your enquiry and will get back to you shortly</p>
                        <button className='btn c-modal-btn' onClick={() => setModalIsOpen(false)}>
                          Close
                        </button>
                    </Modal>
                    <Modal
                      isOpen={isErrorModalOpen}
                      onRequestClose={() => setIsErrorModalOpen(false)} // Function to close the modal when clicking outside
                      contentLabel="Meep"
                      style={{
                          overlay: {
                              backgroundColor: 'rgba(0, 0, 0, 0.5)'
                          }
                      }}
                    >
                      <h2>Error</h2>
                      <p>Something went wrong. Please try again or contact the site administrator.</p>
                      <button className='btn c-modal-btn' onClick={() => setIsErrorModalOpen(false)}>
                        Close
                      </button>
                  </Modal>
                    </form>
                    </div>
                  </div>
              </div>
          </div>
      </div>

    </div>
  );
}

export default ContactUs;
