import React, { useState, useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom'
import { RotatingLines } from 'react-loader-spinner'
import { Helmet } from 'react-helmet';

import './InstantQuote.css'
import InstantQuoteTool from 'components/InstantQuoteTool/InstantQuoteTool'

const InstantQuote = () => {
  const [showTitle, setShowTitle] = useState(true);
  const [showNewTitle, setShowNewTitle] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const address = location?.state?.address;

  useEffect(() => {
    if(!address) {
      navigate('/address-check');
    }

    const timer = setTimeout(() => {
      setShowTitle(false);
      setShowNewTitle(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="instant-quote">
      <Helmet>
        <title>Instant Quote Tool - BoilerRight</title>
        <link rel="canonical" href="https://www.boiler-right.co.uk/instant-quote" />
        <meta name="robots" content="noindex"/>
        {process.env.REACT_APP_FIREBASE_ENV === "development" && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>
      {showTitle && (
        <>
          <h1 className="c-page-title">Great, looks like you're in our <span>service area!</span></h1>
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </>
      )}
      {showNewTitle && (
        <>
          <h2 className="c-page-title">Please answer a few simple questions about <span>your setup</span></h2>
          <InstantQuoteTool address={address} />
        </>
      )}
    </div>
  );
};

export default InstantQuote;
