import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './Home.css';
import heroImg from '../../images/pro-1.png'
import icon5 from '../../images/icon-5.png'
import icon6 from '../../images/icon-6.png'
import icon7 from '../../images/icon-7.png'
import icon8 from '../../images/icon-8.png'
import shape from '../../images/SHAPE.png'
import icon1 from '../../images/icon-1.png'
import icon2 from '../../images/icon-2.png'
import icon3 from '../../images/icon-3.png'
import icon4 from '../../images/icon-4.png'
import UseInView from 'components/UseInView/UseInView';
import FAQItem from 'components/FAQItem/FAQItem';
import PartnersCarousel from 'components/PartnersCarousel/PartnersCarousel'
import AccordionItem from 'components/FAQItem/AccordionItem';
import Testimonials from 'components/Testimonials/Testimonials';

function Home({homeImg}) {
  const navigate = useNavigate();
  const imageContainerRef = useRef(null);
  const [refHowItWorks, isInViewHowItWorks] = UseInView();
  const [refBenefit, isInViewBenefit] = UseInView();
  const [refFaqQuestions, isInViewFaqQuestions] = UseInView();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 991.5);

  const handleQuoteButtonClick = () => {
    navigate('/address-check');
  };

  const handleCallbackButtonClick = () => {
    navigate('/request-callback');
  };

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 991.5);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const aosAnimation = isLargeScreen ? 'zoom-in' : '';

  return (
    <div className="home">
        <Helmet>
          <title>Home - BoilerRight Scunthorpe - Boiler Replacement Service</title>
          <link rel="canonical" href="https://www.boiler-right.co.uk/" />
          <meta name="description" content="Welcome to BoilerRight - your trusted source for hassle-free boiler replacements. Get an instant online quote, discover our transparent pricing and learn why our certified engineers are the top choice for homeowners. Start your journey to a warmer home today." />
          {process.env.REACT_APP_FIREBASE_ENV === "development" && <meta name="robots" content="noindex, nofollow" />}
        </Helmet>

        {/* Hero Section */}
        <section className="c-main-banner w-100">
            <div className="c-main-banner-inner w-100">
              <div className="container">
                <div className="row">
                  <div className="col-xl-7 col-lg-6 mb-4 mb-lg-0">
                    <div className="c-banner-text" data-aos="fade-up">
                      <h1>Hassle-Free<br/>
                        Customer-focused<br/>
                        <span>Boiler Replacement Service</span>
                      </h1>
                      <div className="c-btn-group">
                        <a href="#" className="c-quote-btn" onClick={handleQuoteButtonClick} aria-label="BoilerRight Instant Quote Button">Instant Quote</a>
                        <a href="#" className="c-request-btn" onClick={handleCallbackButtonClick} aria-label="BoilerRight Request a Callback Button">Request Callback</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-6" data-aos="fade-right" data-aos-offset="-100">
                    <div className="c-banner-img"><img src={heroImg} alt="BoilerRight banner image showing a generic combi boiler" importance="high"/></div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        {/* How It Works Section */}
        <section className="c-work-sec">
          <div className="container">
            <h2 data-aos="fade-up" data-aos-offset="0">How <span>BoilerRight</span> Works</h2>
            <div className="c-work-sec-list position-relative w-100">
              <img src={shape} alt="BoilerRight How it Works section line image" className="c-work-sec-shape"/>
              <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 c-work-sec-list-row">
                <div className="col c-work-item" data-aos="zoom-in" data-aos-offset="-40">
                  <div className="c-work-item-in">
                    <div className="c-work-icon"><img src={icon5} alt="BoilerRight submit boiler replacement request icon"/></div>
                    <h3>Submit Your Request</h3>
                    <p>Tell us about your boiler replacement needs by completing our <Link to="/address-check">simple form</Link>.</p>
                  </div>
                </div>
                <div className="col c-work-item" data-aos="zoom-in" data-aos-offset="-40">
                  <div className="c-work-item-in">
                    <div className="c-work-icon"><img src={icon6} alt="BoilerRight get instant quote for a boiler replacement icon"/></div>
                    <h3>Get Instant Quote</h3>
                    <p>Get a custom-built quote sent instantly to your email inbox.</p>
                  </div>
                </div>
                <div className="col c-work-item" data-aos="zoom-in" data-aos-offset="-40">
                  <div className="c-work-item-in">
                    <div className="c-work-icon"><img src={icon7} alt="BoilerRight accept boiler replacement quote icon"/></div>
                    <h3>Accept Quote</h3>
                    <p>Review the quote details and, when you're ready, click the accept button.</p>
                  </div>
                </div>
                <div className="col c-work-item" data-aos="zoom-in" data-aos-offset="-40">
                  <div className="c-work-item-in">
                    <div className="c-work-icon"><img src={icon8} alt="BoilerRight get confirmation for your boiler replacement icon"/></div>
                    <h3>Get Confirmation</h3>
                    <p>We'll reach out to you to schedule a brief initial visit before confirming the job.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section className="c-why-choose-us-sec">
          <div className="container">
            <div className="why-choose-us-inner">
              <div className="section-heading" data-aos="fade-up" data-aos-offset="-60">
                <h2>Why Choose Us</h2>
              </div>
              <div
                className="row"
                data-aos={!isLargeScreen ? 'zoom-in' : ''} data-aos-offset="-60"
              >
                <div className="col-lg-6" data-aos={aosAnimation} data-aos-offset="-60">
                  <div className="c-why-choose-us-item-in">
                    <div className="c-why-icon"><img src={icon1} alt="BoilerRight certified Gas Safe engineers icon"/></div>
                    <div className="c-why-content">
                      <h3>Certified Gas Safe Engineers</h3>
                      <p>BoilerRight's engineers are fully certified and experienced in boiler installations.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6" data-aos={aosAnimation} data-aos-offset="-60">
                  <div className="c-why-choose-us-item-in">
                    <div className="c-why-icon"><img src={icon2} alt="BoilerRight competitive boiler replacement pricing icon"/></div>
                    <div className="c-why-content">
                      <h3>Competitive Pricing</h3>
                      <p>Get transparent quotes and affordable pricing for your boiler replacement.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6" data-aos={aosAnimation} data-aos-offset="-60">
                  <div className="c-why-choose-us-item-in">
                    <div className="c-why-icon"><img src={icon3} alt="BoilerRight efficient boiler replacement process icon"/></div>
                    <div className="c-why-content">
                      <h3>Efficient Process</h3>
                      <p>Our streamlined process ensures your boiler replacement is quick and hassle-free.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6" data-aos={aosAnimation} data-aos-offset="-60">
                  <div className="c-why-choose-us-item-in">
                    <div className="c-why-icon"><img src={icon4} alt="BoilerRight reliable post-boiler installation support icon"/></div>
                    <div className="c-why-content">
                      <h3>Reliable Support</h3>
                      <p>BoilerRight is here to help with any questions or concerns throughout the process.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*<section>
          <Testimonials />
        </section>*/}
    </div>
  );
}

export default Home;
