import React, { useState } from "react";
import {Link} from "react-router-dom";

import "./AcceptCookies.css";

const AcceptCookies = () => {
  const hasAcceptedCookies = () => {
    // Look for the "cookiesAccepted" cookie in the list of all cookies
    const cookie = document.cookie.split('; ').find(row => row.startsWith('cookiesAccepted'));

    if (cookie) {
      return cookie.split('=')[1] === 'true';
    } else {
      return false;
    }
  };

  const [showBanner, setShowBanner] = useState(!hasAcceptedCookies());

  const acceptCookies = () => {
    document.cookie = "cookiesAccepted=true; max-age=31536000"; // 1 year

    setShowBanner(false);
  };

  return showBanner ? (
    <div className="cookie-banner">
      <p>
        We use cookies to give you a better experience.{" "}
        <Link to="/privacy-policy">
          <u>Learn more</u>
        </Link>
      </p>
      <button onClick={acceptCookies}>Accept</button>
    </div>
  ) : null;
};

export default AcceptCookies;
