const AccordionItem = ({ title, content, isOpen, onClick }) => {
    return (
      <div className={`c-each-faq ${isOpen ? 'open' : ''}`}>
        <div className="c-faq-title" onClick={onClick}>
            <h3>{title}</h3>
        </div>
        <div className="c-faq-content">
            <p>{content}</p>
        </div>
        </div>
    );
  }
export default AccordionItem;