// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import Lato font
const fontLink = document.createElement('link');
fontLink.rel = 'stylesheet';
fontLink.href = 'https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap';
document.head.appendChild(fontLink);

const bootstrapcssLink = document.createElement('link');
bootstrapcssLink.rel = 'stylesheet';
bootstrapcssLink.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css';
document.head.appendChild(bootstrapcssLink);

const fontAwesomecssLink = document.createElement('link');
fontAwesomecssLink.rel = 'stylesheet';
fontAwesomecssLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css';
document.head.appendChild(fontAwesomecssLink);

createRoot(document.getElementById('root')).render(
    <App />
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
