import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { RotatingLines } from 'react-loader-spinner'
import { Helmet } from 'react-helmet';

import './AcceptQuote.css'

function AcceptQuote() {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [processing, setProcessing] = useState(false);

  const handleAcceptQuote = () => {
    setProcessing(true);
    const quoteId = new URLSearchParams(location.search).get("quoteId");
    console.log(`Running acceptQuote function for quoteId: ${quoteId}`);
    if (quoteId) {
      const functions = getFunctions();
      const acceptQuote = httpsCallable(functions, "acceptQuote");
      acceptQuote({ quoteId })
        .then((result) => {
          setMessage(result.data.message);
        })
        .catch((error) => {
          setMessage(error.message);
        });
    } else {
      setMessage("Error: A quote ID has not been provided");
    }
  };

  return (
    <div className="accept-quote">
      <Helmet>
        <title>Accept Quote - BoilerRight</title>
        <link rel="canonical" href="https://www.boiler-right.co.uk/accept-quote" />
        <meta name="robots" content="noindex"/>
        {process.env.REACT_APP_FIREBASE_ENV === "development" && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>
      <div className="c-primary-page-sec">
        <div className="container">
          <div className="accept-quote-card">
            {!processing ? (
              <>
                <p>Please click below to confirm acceptance of your quote.</p>
                <button className='btn c-modal-btn' onClick={handleAcceptQuote}>Accept</button>
              </>
            ) : (
              <>
                {message ? (
                  <p className="accept-quote-message">{message}</p>
                ) : (
                  <>
                    <p className="accept-quote-message">Your quote is being processed. Please wait...</p>
                    <p className="accept-quote-message">
                      <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="20"
                        visible={true}
                      />
                    </p>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptQuote;
