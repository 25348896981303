import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged, setPersistence, browserSessionPersistence} from "firebase/auth";
import { Helmet } from 'react-helmet';

import firebaseApp from "config/firebaseConfig";
import "./Admin.css";

function Admin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth(firebaseApp);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            navigate('/admin-quotes');
          } else {
            setIsLoading(false); // Not an admin, show login
          }
        });
      } else {
        setIsLoading(false); // Not signed in, show login
      }
    });

    return () => unsubscribe();
  }, [navigate, auth]);

  const handleLogin = (e) => {
    e.preventDefault();

    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password);
      })
      .then(({ user }) => {
        return user.getIdTokenResult();
      })
      .then((idTokenResult) => {
        if (idTokenResult.claims.admin) {
          navigate('/admin-quotes');
          console.log("Admin signed in successfully.");
        } else {
          signOut(auth);
          setMessage("Unauthorized access. You are not an admin.");
        }
      })
      .catch((error) => {
        console.log(`Error: ${error.message}`);
        setMessage("User not found or incorrect credentials.");
      });
};

  return (
    <div>
      {!isLoading && (
        <>
          <Helmet>
            <title>Admin - BoilerRight</title>
            <link rel="canonical" href="https://www.boiler-right.co.uk/admin" />
            <meta name="robots" content="noindex, nofollow"/>
          </Helmet>
          <div className="c-primary-page-sec admn">
            <h1 className='c-page-title'>Admin Login</h1>
            <div className="container">
              <div className="row text-center">
                <div className="col-lg-4 mx-auto">
                  <div className="admin-form">
                    <form onSubmit={handleLogin}>
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <button className="btn c-small-btn admin-button" type="submit">Login</button>
                    </form>
                    {message && <p className="admin-message">{message}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
       )}
    </div>
  );
}

export default Admin;
